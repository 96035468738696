import ReportDefinitionBase from "./ReportDefinitionBase";

class ReportDefinition implements ReportDefinitionBase {
  id: number;
  name: string;
  formJson: string;

  owner: string = "00000000-0000-0000-0000-000000000000";
  lastEditedUtc: string = "0001-01-01T00:00:00Z";
  msisdnsRemoved: boolean = false;

  constructor(name: string, formJson: string, id: number = 0) {
    this.name = name;
    this.formJson = formJson;
    this.id = id;
  }
}

export default ReportDefinition;
