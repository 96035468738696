class ESmlcLocationExport {
  public callId: number = -1;
  public msisdn: string | null = null;
  public locationReturned: string | null = null;
  public positionMethod: string | null = null;
  public sourceAltitude: string | null = null;
  public resultCode: string | null = null;
  public timeStamp: string | null = null;
  public shape: string | null = null;
  public extHorizUsed: string = "FALSE";
  public extVertUsed: string = "FALSE";
  public longitude: number | null = null;
  public latitude: number | null = null;
  public horizontalAccuracy: number | null = null;
  public verticalAccuracy: number | null = null;
  public uncertaintySMajor: number | null = null;
  public uncertaintySMinor: number | null = null;
  public uncertainty: number | null = null;
  public altitude: number | null = null;
  public uncertaintyAltitude: number | null = null;
  public confidence: number | null = null;
  public verticalConfidence: number | null = null;
  public ha3DLat: number | null = null;
  public ha3DLong: number | null = null;
  public ha3DUncertaintySMajor: number | null = null;
  public ha3DUncertaintySMinor: number | null = null;
  public ha3DUncertainty: number | null = null;
  public ha3DConfidence: number | null = null;
  public ha3DAltitude: number | null = null;
  public ha3DUncertaintyAltitude: number | null = null;
  public ha3DVerticalConfidence: number | null = null;
  public locationSource: string | null = null;
  public gnssList: string | null = null;
  public satVisible: number | null = null;
  public satMeasured: number | null = null;
  public satUsed: number | null = null;
  public gpsLatitude: number | null = null;
  public gpsLongitude: number | null = null;
  public gpsElevation: number | null = null;
  public gpsAscertainedTime: string | null = null;
}

export default ESmlcLocationExport;
