import { rawReportHeader } from "../../reportHelpers";
import CorrelationExport from "./CorrelationExport";
import Worksheet from "./Worksheet";

export default class RawDataWorksheet extends Worksheet {
  private _nextRow: number;

  public get hasData() {
    return this._nextRow > 2;
  }

  constructor() {
    super();
    this.writeHeaders();
    this._nextRow = 1;
  }

  public writeData = (c: CorrelationExport): void => {
    const values = [
      [
        c.callId,
        c.transactionId,
        c.msisdn,
        c.imsi,
        c.imei,
        c.ranTech,
        c.type,
        c.isiOS,
        c.callStartTime,
        c.locationRequestTime,
        c.ascertained,
        c.active,
        c.accuracy,
        c.vertical_accuracy,
        c.longitude,
        c.latitude,
        c.shape,
        c.extendedHorizontalUncertainty,
        c.extendedVerticalUncertainty,
        c.horizontalUncertainty,
        c.confidence,
        c.altitude,
        c.verticalUncertainty,
        c.latency,
        c.status_val,
        c.status_str,
        c.isSuccessful,
        c.cgi,
        c.positionMethod,
        c.ubp,
        c.floorNumber,
        c.neadAddressType,
        c.neadCounty,
        c.neadHouseNumber,
        c.neadFloor,
        c.neadUnit,
        c.neadRoad,
        c.neadCity,
        c.neadState,
        c.neadPostalCode,
        c.gpsUserName,
        c.gpsLongitude,
        c.gpsLatitude,
        c.gpsAscertained,
        c.gpsStatic,
        c.gpsElevation,
        c.gpsSpeed,
        c.gpsSatelliteCount,
        c.gpsStatus,
        c.gpsMorphology,
        c.gpsHdop,
        c.gpsPdop,
        c.gpsVdop,
        c.selectedVertical,
        c.usedInReport,
        c.isVersionSupported,
        c.rawCallLink,
      ],
    ];

    this.setCells(this._nextRow, 0, values);

    if (c.callStartTime) {
      this.setCellAsDateFormat(this._nextRow, 8); // col i, callStartTime
    }
    if (c.locationRequestTime) {
      this.setCellAsDateFormat(this._nextRow, 9); // col j, locationRequestTime
    }
    if (c.ascertained) {
      this.setCellAsDateFormat(this._nextRow, 10); // col k, ascertainedTime
    }
    if (c.gpsAscertained) {
      this.setCellAsDateFormat(this._nextRow, 43); // col ar, gpsAscertainedTime
    }
    if (c.rawCallLink) {
      this.setCellAsHyperLink(this._nextRow, 56); // col be, rawCallLink
    }

    for (let i = 0; i < values[0].length; i++) {
      this.setCellDefaultValueStyles(this._nextRow, i);
    }

    this._nextRow++;
  };

  private writeHeaders = (): void => {
    this.setCells(0, 0, [rawReportHeader]);

    for (let i = 0; i < rawReportHeader.length; i++) {
      this.setCellDefaultValueStyles(0, i);
    }

    //this needs to be the last column or beyond
    this.setAutofilter("BZ");
    this.setRawDataColumnWidths();
  };
}
