import { ESmlcEvent } from "../../../../services/models/ESmlcEvent";
import { ESmlcLocation } from "../../../../services/models/ESmlcLocation";
import { GpsLocation } from "./GpsLocation";

export class CorrelatedESmlcRecord {
  eSmlcEvent: ESmlcEvent;
  eSmlcLocations: ESmlcLocation [];
  correlatedGpsLocation: GpsLocation | null;

  constructor(
    eSmlcEvent: ESmlcEvent,
    eSmlcLocations: ESmlcLocation[] ,
    correlatedGpsLocation: GpsLocation | null,
  ) {
    this.eSmlcEvent = eSmlcEvent;
    this.eSmlcLocations = eSmlcLocations;
    this.correlatedGpsLocation = correlatedGpsLocation;
  }
};
