export default class EventSmallestValueList {
  private _smallestValuePerEvent = new Map<number, number>();

  public get count() {
    return this._smallestValuePerEvent.size;
  }

  public get values() {
    return [...this._smallestValuePerEvent.values()];
  }

  public get max() {
    return this.count > 0 ? Math.round(this.values.reduce((max, value) => Math.max(max, value), -Infinity)) : 0;
  }

  public get min() {
    return this.count > 0 ? Math.round(this.values.reduce((max, value) => Math.min(max, value), Infinity)) : 0;
  }

  public get avg() {
    if (this.count < 1) {
      return 0;
    }

    const total = this.values.reduce((sum, value) => sum + value, 0);
    return total / this.count;
  }

  public add = (eventId: number, value?: number | null, addZeroValue: boolean = true) => {
    if (value == null || (!addZeroValue && value === 0)) {
      return;
    }

    if (
      !this._smallestValuePerEvent.has(eventId) ||
      value < this._smallestValuePerEvent.get(eventId)!
    ) {
      this._smallestValuePerEvent.set(eventId, value);
    }
  };

  public getPercentile = (percentile: number) => {
    if (percentile < 0 || percentile > 100) {
      throw new Error("percentile must be between 0 and 100.");
    }

    if (percentile === 0 || this.count === 0) {
      return 0;
    }

    const orderedValues = this.values.sort((a, b) => a - b);

    // This formula matches the formula used by Excel as documented on
    // http://en.wikipedia.org/wiki/95th_percentile and verified manually by MK.
    const n = (percentile / 100) * (orderedValues.length - 1) + 1;
    const k = Math.trunc(n);
    const d = n - k;

    if (k === 0) {
      return orderedValues[0];
    }

    if (k === orderedValues.length) {
      return orderedValues[orderedValues.length - 1];
    }

    const index = k - 1;
    return orderedValues[index] + d * (orderedValues[index + 1] - orderedValues[index]);
  };
}
