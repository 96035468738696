import XLSX, { WorkBook } from "xlsx-js-style";

type CellValue = string | number | null | undefined;

export default abstract class Worksheet {
  private _worksheet: any;
  constructor() {
    this._worksheet = XLSX.utils.aoa_to_sheet([[]]);
  }

  protected setCell = (row: number, col: number, value: CellValue): void => {
    XLSX.utils.sheet_add_aoa(
      this._worksheet,
      [[value === null || value === undefined ? "" : value]],
      { origin: { r: row, c: col }, cellDates: true }
    );
  };

  protected setCells = (originRow: number, originCol: number, values: CellValue[][]): void => {
    XLSX.utils.sheet_add_aoa(
      this._worksheet,
      values,
      { origin: { r: originRow, c: originCol }, cellDates: true }
    );
  };

  public appendToWorkbook = (workbook: WorkBook, name?: string) => {
    XLSX.utils.book_append_sheet(workbook, this._worksheet, name);
  };

  // s=start, r=row, c=col, e=end
  protected mergeCells = (startRow: number, startColumn: number, endRow: number, endColumn: number) => {
    const merge = { s: { r: startRow, c: startColumn }, e: { r: endRow, c: endColumn } };

    if (!this._worksheet['!merges']) {
      this._worksheet['!merges'] = [];
    }

    this._worksheet["!merges"].push(merge);
  };

  //apply the filter ability in xlsx for the header row
  protected setAutofilter = (lastColumn: string) => {
    this._worksheet["!autofilter"] = { ref: `A1:${lastColumn}1` };;
  };

  protected setReportColumnWidths = () => {
    const colWidths = [
      { width: 30 }, //col a
      { width: 13 },  //col b
      { width: 8 },  //col c
      { width: 12 },  //col d
      { width: 8 },  //col e
      { width: 8 },  //col f
      { width: 8 },  //col g
      { width: 12 }, //col h
      { width: 9 },  //col i
      { width: 9 },  //col j
      { width: 8 },  //col k
      { width: 8 },  //col l
      { width: 8 },  //col m
      { width: 8 },  //col n
      { width: 8 },  //col o
      { width: 8 },  //col p
      { width: 12 }, //col q
      { width: 8 },  //col r
      { width: 8 },  //col s
      { width: 9 },  //col t
      { width: 9 },  //col u
      { width: 8 },  //col v
      { width: 8 },  //col w
      { width: 8 },  //col x
      { width: 12 }, //col y
      { width: 8 },  //col z
      { width: 8 },  //col aa
      { width: 8 },  //col ab
      { width: 8 },  //col ac
      { width: 8 },  //col ad
      { width: 8 },  //col ae
      { width: 12 }, //col af
    ];

    this._worksheet["!cols"] = colWidths;
  };

  protected setRawDataColumnWidths = () => {
    const colWidths = [
      { width: 8 },   //col a
      { width: 20 },  //col b
      { width: 12 },  //col c
      { width: 16 },  //col d
      { width: 18 },  //col e
      { width: 10 },  //col f
      { width: 8 },   //col g Type
      { width: 8 },   //col h
      { width: 22 },  //col i
      { width: 22 },  //col j
      { width: 20 },  //col k
      { width: 20 },  //col l
      { width: 19 },  //col m
      { width: 19 },  //col n
      { width: 15 },  //col o
      { width: 15 },  //col p
      { width: 12 },  //col q
      { width: 16 },  //col r
      { width: 16 },  //col s
      { width: 20 },  //col t
      { width: 15 },  //col u
      { width: 15 },  //col v
      { width: 20 },  //col w
      { width: 16 },  //col x
      { width: 20 },  //col y
      { width: 18 },  //col z
      { width: 18 },  //col aa
      { width: 18 },  //col ab
      { width: 20 },  //col ac
      { width: 12 },  //col ad
      { width: 12 },  //col ae
      { width: 18 },  //col af
      { width: 15 },  //col ag
      { width: 12 },  //col ah
      { width: 17 },  //col ai
      { width: 15 },  //col aj
      { width: 15 },  //col ak
      { width: 15 },  //col al
      { width: 20 },  //col am
      { width: 22 },  //col an
      { width: 14 },  //col ao
      { width: 14 },  //col ap
      { width: 14 },  //col aq
      { width: 20 },  //col ar
      { width: 16 },  //col as
      { width: 16 },  //col at
      { width: 11 },  //col au
      { width: 11 },  //col av
      { width: 11 },  //col aw
      { width: 16 },  //col ax
      { width: 11 },  //col ay
      { width: 11 },  //col az
      { width: 11 },  //col ba
      { width: 14 },  //col bb
      { width: 14 },  //col bc
      { width: 18 },  //col bd
      { width: 18 },  //col be rawCallLink
    ];

    this._worksheet["!cols"] = colWidths;
  };

  protected setESmlcCallsColumnWidths = () => {
    const colWidths = [
      { width: 8 },   //col a
      { width: 12 },  //col b
      { width: 21 },  //col c
      { width: 20 },  //col d
      { width: 20 },  //col e
      { width: 11 },  //col f
      { width: 16 },  //col g
      { width: 13 },  //col h
      { width: 17 },  //col i
      { width: 17 },  //col j
      { width: 20 },  //col k
      { width: 24 },  //col l
      { width: 19 },  //col m
      { width: 12 },  //col n
      { width: 15 },  //col o
      { width: 15 },  //col p
      { width: 13 },  //col q
      { width: 14 },  //col r
      { width: 13 },  //col s
      { width: 19 },  //col t
      { width: 10 },  //col u
      { width: 13 },  //col v
      { width: 15 },  //col w
      { width: 13 },  //col x
      { width: 14 },  //col y
      { width: 9 },   //col z
      { width: 13 },  //col aa
      { width: 18 },  //col ab
      { width: 20 },  //col ac
      { width: 24 },  //col ad
      { width: 8 },   //col ae
      { width: 9 },   //col af
      { width: 15 },  //col ag
      { width: 12 },  //col ah
      { width: 13 },  //col ai
      { width: 18 },  //col aj rawCallLink
    ];

    this._worksheet["!cols"] = colWidths;
  };

  protected setESmlcLocationsColumnWidths = () => {
    const colWidths = [
      { width: 8 },   //col a
      { width: 12 },  //col b
      { width: 13 },  //col c
      { width: 18 },  //col d
      { width: 18 },  //col e
      { width: 12 },  //col f
      { width: 20 },  //col g
      { width: 44 },  //col h
      { width: 13 },  //col i
      { width: 13 },  //col j
      { width: 13 },  //col k
      { width: 13 },  //col l
      { width: 18 },  //col m
      { width: 18 },  //col n
      { width: 12 },  //col o
      { width: 10 },  //col p
      { width: 19 },  //col q
      { width: 12 },  //col r
      { width: 13 },  //col s
      { width: 12 },  //col t
      { width: 12 },  //col u
      { width: 23 },  //col v
      { width: 23 },  //col w
      { width: 17 },  //col x
      { width: 17 },  //col y
      { width: 15 },  //col z
      { width: 23 },  //col aa
      { width: 18 },  //col ab
      { width: 15 },  //col ac
      { width: 15 },  //col ad
      { width: 15 },  //col ae
      { width: 15 },  //col af
      { width: 12 },  //col ag
      { width: 12 },  //col ah
      { width: 12 },  //col ai
      { width: 15 },  //col aj
      { width: 15 },  //col ak
      { width: 15 },  //col al
      { width: 20 },  //col am
    ];

    this._worksheet["!cols"] = colWidths;
  };

  protected setCellDefaultHeaderStyles = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      font: {
        name: "Aptos Narrow",
        sz: 11,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: "1",
      }
    };
  };

  protected setCellDefaultValueStyles = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      font: {
        ...this._worksheet[cellReference].s?.font,
        name: "Aptos Narrow",
        sz: 11,
      },
    };
  };

  protected setCellStyleFontBold = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      font: {
        ...this._worksheet[cellReference].s.font,
        bold: true,
      },
    };
  };

  protected setCellStyleFontSize = (startRow: number, startColumn: number, size: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      font: {
        ...this._worksheet[cellReference].s.font,
        sz: size,
      },
    };
  };

  protected setCellStyleFillColor = (startRow: number, startColumn: number, fillColor: string) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });

    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      fill: {
        fgColor: { rgb: fillColor }
      }
    };
  };

  protected setCellStyleBorders = (startRow: number, startColumn: number, endRow: number, endColumn: number) => {
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startColumn; j <= endColumn; j++) {
        const cellReference = XLSX.utils.encode_cell({ r: i, c: j });
        this.initializeUndefinedCell(cellReference);

        this._worksheet[cellReference].s = {
          ...this._worksheet[cellReference].s,
          border: {
            top: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
          }
        };
      }
    }
  };

  protected setCellStyleBorder = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      border: {
        top: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      }
    };
  };

  /**
   * This will set the cell as a date in the format 03/11/2022 08:20:28.327, doing this will allow
   * the user to edit the .xlsx and format the column into different date formats. The default format
   * for cells is t="s" or t="str" which does not allow easy date formatting.
   * https://github.com/SheetJS/sheetjs/blob/e14aee3e512a838a9d2a125568c8ae8070695957/docbits/51_cell.md
   * @param startRow - the row to format, 0 based
   * @param startColumn - the column to format, 0 based
   */
  protected setCellAsDateFormat = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });
    this.initializeUndefinedCell(cellReference);

    const cell = this._worksheet[cellReference]; // get the cell

    cell.z = "mm/dd/yy hh:mm:ss.000"; // set cell format
    cell.t = "d"; // set cell type

    XLSX.utils.format_cell(cell); // this refreshes the formatted text.
  };

  protected setCellAsHyperLink = (startRow: number, startColumn: number) => {
    const cellReference = XLSX.utils.encode_cell({ r: startRow, c: startColumn });

    this.initializeUndefinedCell(cellReference);

    const cell = this._worksheet[cellReference]; // get the cell

    //this will add the raw link in the cell
    //this._worksheet[cellReference].l = {
    //  Target: cell.v,
    //};

    //will set underline and color the link, can't set the cellstyle through this
    this._worksheet[cellReference].s = {
      ...this._worksheet[cellReference].s,
      font: {
        ...this._worksheet[cellReference].s?.font,
        underline: true,
        color: { rgb: "0f6ff9" },
      }
    };

    this._worksheet[cellReference].f = `HYPERLINK("${cell.v}", "Call XML")`;
  };

  //a cellReference that is undefined has had no data placed in it, format it as an empty cell
  protected initializeUndefinedCell = (cellReference: string) => {
    if (!this._worksheet[cellReference]) {
      XLSX.utils.sheet_add_aoa(this._worksheet, [[""]], { origin: cellReference });
    }
  };

}
