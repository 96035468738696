import styled from "styled-components";

const Button = styled.button`
  border: 0;
  color: white;
  background: blue;
  border-radius: 50px;
  padding: 1rem;
  margin-top: 1.5rem;
  font-size: inherit;
  font-weight: bolder;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    filter: brightness(90%);
  }
`;

export default Button;
