import EventCache from "./EventCache";
import EventSmallestValueList from "./EventSmallestValueList";
import EventSmallestValueListPerPositionMethod from "./EventSmallestValueListPerPositionMethod";
import JobDefinition from "./JobDefinition";
import ReportRecord from "./ReportRecord";
import Worksheet from "./Worksheet";

export default class ReportWorksheet extends Worksheet {
  private _jobDefinition: JobDefinition;
  private _msisdn: string | null;

  constructor(
    jobDefinition: JobDefinition,
    msisdn: string | null = null
  ) {
    super();

    this._jobDefinition = jobDefinition;
    this._msisdn = msisdn;
  }

  public writeCachedValues = (eventCache: EventCache): void => {
    this.writeHeadingRowOne();
    this.writeHeadingRowTwo();
    this.writePositionMethodHeading("Control Plane Position Method", 2, true, true);
    this.buildControlPlanePositionMethodRows(eventCache);

    /*
      The first row of report data is row 4. Add the number of Control Plane
      position methods + 6 CP rows (
        Subtotal iOS, Subtotal Android, 
        Status Error iOS, Status Error Android
        No CP Location iOS, No CP Location Android
      )
      and a blank row to start writing the SIP records.
     */
    let row = 4 + eventCache.cp_positionMethod_iOS.positionMethods.length +
      eventCache.cp_positionMethod_android.positionMethods.length + 7;

    this.writePositionMethodHeading("SIP", row, false, false);

    // Add the two header rows to the row index to get the start of the data rows.
    this.writeSIPRows(eventCache, row + 2);

    /*
      Add the 4 data rows for sip, 2 header rows 
      and a blank row to start writing the Thunderbird position records.
    */
    row = row + 7;

    this.writePositionMethodHeading("Thunderbird Position Method", row, false, false);

    // Add the two header rows to the row index to get the start of the data rows.
    this.writeThunderbirdPositionMethodRows(eventCache, row + 2);

    /*
     Write the vertical section:
      Add the TB position method rows, 2 header rows, 3 total rows 
      and a blank row to start writing the Vertical position records.
    */
    row = row + eventCache.tb_positionMethod.positionMethods.length + 6;

    this.writeVerticalHeading(row);

    this.writeVerticalRows(eventCache, row + 2);

    this.setReportColumnWidths();
  };

  public writeHeadingRowOne = (): void => {
    const title = this._msisdn || "ALL MSISDNs";
    this.setCell(0, 0, title);
    this.mergeCells(0, 0, 1, 0);  //A1:A2

    this.setCellDefaultHeaderStyles(0, 0);
    this.setCellStyleFontBold(0, 0);
    this.setCellStyleFontSize(0, 0, 14);
    this.setCellStyleBorders(0, 0, 1, 0);
  };

  public writeHeadingRowTwo = (): void => {
    //will merge cells C2:O2
    this.populateAndStyleHeadingRowTwo(1, 2, 1, 14, "Horizontal", "d6dce4");

    //will merge cells P2:T2
    this.populateAndStyleHeadingRowTwo(1, 15, 1, 19, "UBP", "ddebf7");
  };

  private populateAndStyleHeadingRowTwo = (
    startRow: number,
    startColumn: number,
    endRow: number,
    endColumn: number,
    value: string,
    fillForeGroundColor: string
  ) => {
    //set cell value
    this.setCell(startRow, startColumn, value);
    //apply cell styles
    this.mergeCells(startRow, startColumn, endRow, endColumn);
    this.setCellDefaultHeaderStyles(startRow, startColumn);
    this.setCellStyleFillColor(startRow, startColumn, fillForeGroundColor);
    this.setCellStyleFontBold(startRow, startColumn);
    this.setCellStyleBorders(startRow, startColumn, endRow, endColumn);
  };

  public writePositionMethodHeading = (
    positionMethodHeading: string,
    startRow: number,
    writeUBPSubHeading: boolean,
    isHeadingForControlPlane: boolean,
  ): void => {
    let row = startRow;

    this.setCell(row, 0, positionMethodHeading);
    //will merge cells A3:A4
    this.populateAndStylePositionMethodHeading(row, 0, row + 1, 0, positionMethodHeading);

    //will merge cells B3:B4
    const osStartRow = isHeadingForControlPlane ? row - 1 : row;
    this.populateAndStylePositionMethodHeading(osStartRow, 1, row + 1, 1, "OS");

    //Horizontal headings
    //will merge cells C3:C4
    this.populateAndStylePositionMethodHeading(row, 2, row + 1, 2, "Count");

    //will merge cells D3:D4
    const yieldHeading = isHeadingForControlPlane ? "Yield (%)" : "Per OS Yield (%)";
    this.populateAndStylePositionMethodHeading(row, 3, row + 1, 3, yieldHeading);

    //will merge cells E3:H3
    this.populateAndStylePositionMethodHeading(row, 4, row, 7, "Accuracy");

    //will merge cells I3:J3
    this.populateAndStylePositionMethodHeading(row, 8, row, 9, "Latency");

    //will merge cells K3:N3
    this.populateAndStylePositionMethodHeading(row, 10, row, 13, "Uncertainty");

    //will merge cells O3:O4
    this.populateAndStylePositionMethodHeading(row, 14, row + 1, 14, "Acc vs Unc Correl");

    if (writeUBPSubHeading) {
      //will merge cells P3:P4
      this.populateAndStylePositionMethodHeading(row, 15, row + 1, 15, "Count");
      //will merge cells Q3:Q4
      this.populateAndStylePositionMethodHeading(row, 16, row + 1, 16, "Successful Events with UBP (%)");
      //will merge cells R3:R4
      this.populateAndStylePositionMethodHeading(row, 17, row + 1, 17, "Max");
      //will merge cells S3:S4
      this.populateAndStylePositionMethodHeading(row, 18, row + 1, 18, "Min");
      //will merge cells T3:T4
      this.populateAndStylePositionMethodHeading(row, 19, row + 1, 19, "Mean");
    }

    row++;

    const values = [
      [
        `${this._jobDefinition.horizontalAccuracyThreshold_Meters}m (%)`,  //accuracy 50m (%)
        `${this.getOrdinal(this._jobDefinition.horizontalAccuracyPercentile1)} (m)`,
        `${this.getOrdinal(this._jobDefinition.horizontalAccuracyPercentile2)} (m)`,
        "Within Uncertainty (%)",
        `${this.getOrdinal(this._jobDefinition.latencyPercentile1)} (sec)`,
        `${this.getOrdinal(this._jobDefinition.latencyPercentile2)} (sec)`,
        "Count",  //uncertainty->count
        "Max",
        "Min",
        "Mean",
        null,  //Acc vs Unc Correl
      ],
    ];

    this.setCells(row, 4, values);

    for (let i = 4; i < values[0].length + 4; i++) {
      this.setCellDefaultHeaderStyles(row, i);
      this.setCellStyleFontBold(row, i);
      this.setCellStyleBorder(row, i);
    }
  };

  public writeVerticalHeading = (startRow: number): void => {
    let row = startRow;

    this.setCell(row, 0, "Vertical");
    //will merge cells A3:A4
    this.populateAndStylePositionMethodHeading(row, 0, row + 1, 0, "Vertical");

    //will merge cells B3:B4
    const osStartRow = row;
    this.populateAndStylePositionMethodHeading(osStartRow, 1, row + 1, 1, "OS");

    //will merge cells C3:C4
    this.populateAndStylePositionMethodHeading(row, 2, row + 1, 2, "Count");

    //will merge cells D3:D4
    this.populateAndStylePositionMethodHeading(row, 3, row + 1, 3, "Successful Events with Altitude (%)");

    //will merge cells E3:L3
    this.populateAndStylePositionMethodHeading(row, 4, row, 11, "Accuracy");

    //will merge cells K3:N3
    this.populateAndStylePositionMethodHeading(row, 12, row, 15, "Uncertainty");

    //will merge cells O3:O4
    this.populateAndStylePositionMethodHeading(row, 16, row + 1, 16, "Acc vs Unc Correl");

    row++;

    const values = [
      [
        `${this._jobDefinition.verticalAccuracyThreshold_Meters}m (%) base`,
        `${this._jobDefinition.verticalAccuracyThreshold_Meters}m (%) vertical`,
        `${this.getOrdinal(this._jobDefinition.verticalAccuracyPercentile1)} (m)`,
        `${this.getOrdinal(this._jobDefinition.verticalAccuracyPercentile2)} (m)`,
        "Max",
        "Min",
        "Mean",
        "Within Uncertainty (%)",
        "Count",  //Uncertainty
        "Max",
        "Min",
        "Mean",
      ],
    ];

    this.setCells(row, 4, values);
    for (let i = 4; i < values[0].length + 4; i++) {
      this.setCellDefaultHeaderStyles(row, i);
      this.setCellStyleFontBold(row, i);
      this.setCellStyleBorder(row, i);
    }
  };

  private populateAndStylePositionMethodHeading = (
    startRow: number,
    startColumn: number,
    endRow: number,
    endColumn: number,
    value: string
  ) => {
    //set cell value
    this.setCell(startRow, startColumn, value);
    //apply cell styles
    this.mergeCells(startRow, startColumn, endRow, endColumn);
    this.setCellDefaultHeaderStyles(startRow, startColumn);
    this.setCellStyleFontBold(startRow, startColumn);
    this.setCellStyleBorders(startRow, startColumn, endRow, endColumn);
  };

  public buildControlPlanePositionMethodRows = (eventCache: EventCache): void => {
    let reportRecords: ReportRecord[] = [];

    for (const positionMethod of eventCache.cp_positionMethod_iOS.positionMethods) {
      let rr: ReportRecord = new ReportRecord();

      rr.positionMethod = positionMethod;
      rr.os = "iOS";
      rr.reportableEventCount = eventCache.reportableEvent.count;
      rr.reportableiOSEventCount = eventCache.reportableiOSEvent.count
      rr.reportableAndroidEventCount = eventCache.reportableAndroidEvent.count

      rr.positionMethodCount = eventCache.cp_positionMethod_iOS.get(positionMethod)!.count;

      rr.withinHorizontalAccuracyThresholdCount = eventCache.cp_positionMethod_within_horizontalAccuracyThreshold_iOS.get(positionMethod)?.count || 0;
      rr.horizontalAccuracyWithinUncertaintyCount =
        eventCache.cp_positionMethod_horizontal_accuracy_uncertainty_iOS.get(positionMethod)
          ?.count || 0;
      rr.withAltitudeCount =
        eventCache.cp_positionMethod_with_altitude_iOS.get(positionMethod)?.count || 0;
      rr.verticalAccuracyWithinThresholdCount =
        eventCache.cp_positionMethod_within_verticalAccuracyThreshold_iOS.get(positionMethod)?.count || 0;
      rr.withVerticalUncertaintyCount =
        eventCache.cp_positionMethod_with_vertical_uncertainty_iOS.get(positionMethod)?.count || 0;
      rr.verticalUncertaintyCount =
        eventCache.cp_positionMethod_vertical_uncertainty_count_iOS.get(positionMethod)
          ?.count || 0;

      rr.horizontalAccuracyPercentile1 = Math.round(eventCache.cp_positionMethod_horizontal_accuracy_iOS
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.horizontalAccuracyPercentile1));

      rr.horizontalAccuracyPercentile2 = Math.round(eventCache.cp_positionMethod_horizontal_accuracy_iOS
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.horizontalAccuracyPercentile2));

      rr.horizontalLatencyPercentile1 = Math.round(eventCache.cp_positionMethod_horizontal_latency_iOS
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.latencyPercentile1));

      rr.horizontalLatencyPercentile2 = Math.round(eventCache.cp_positionMethod_horizontal_latency_iOS
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.latencyPercentile2));

      rr.horizontalUncertaintyValues = eventCache.cp_positionMethod_horizontal_uncertainty_values_iOS.get(positionMethod);

      const iOS_horizontalUncertaintyValues = eventCache.cp_positionMethod_horizontal_uncertainty_values_iOS.get(positionMethod);
      rr.horizontalUncertaintyValues = iOS_horizontalUncertaintyValues;

      const iOS_verticalUncertaintyValues = eventCache.cp_positionMethod_vertical_uncertainty_values_iOS.get(positionMethod);
      rr.verticalUncertaintyValues = iOS_verticalUncertaintyValues;

      const iOS_horizontalAccuracy = eventCache.cp_positionMethod_horizontal_accuracy_iOS.get(positionMethod);
      const iOS_verticalAccuracy = eventCache.cp_positionMethod_vertical_accuracy_iOS.get(positionMethod);

      rr.horizontalAccuracyVsUncertainty =
        this.correlationCoefficient(iOS_horizontalAccuracy?.values, iOS_horizontalUncertaintyValues?.values);
      rr.verticalAccuracyVsUncertainty = this.correlationCoefficient(iOS_verticalAccuracy?.values, iOS_verticalUncertaintyValues?.values);

      rr.verticalAccuracyValues = eventCache.cp_positionMethod_vertical_accuracy_values_iOS.get(positionMethod);

      rr.verticalAccuracyPercentile1 = Math.round(iOS_verticalAccuracy!.getPercentile(this._jobDefinition.verticalAccuracyPercentile1));
      rr.verticalAccuracyPercentile2 = Math.round(iOS_verticalAccuracy!.getPercentile(this._jobDefinition.verticalAccuracyPercentile2));

      rr.ubpValues = eventCache.cp_positionMethod_ubp_values_iOS.get(positionMethod);
      rr.ubpCount = eventCache.cp_positionMethod_ubp_count_iOS.get(positionMethod)?.count || 0;

      reportRecords.push(rr);
    }

    for (const positionMethod of eventCache.cp_positionMethod_android.positionMethods) {
      let rr: ReportRecord = new ReportRecord();

      rr.positionMethod = positionMethod;
      rr.os = "Android/Other";
      rr.reportableEventCount = eventCache.reportableEvent.count;
      rr.reportableiOSEventCount = eventCache.reportableiOSEvent.count
      rr.reportableAndroidEventCount = eventCache.reportableAndroidEvent.count

      rr.positionMethodCount = eventCache.cp_positionMethod_android.get(positionMethod)!.count;
      rr.withinHorizontalAccuracyThresholdCount = eventCache.cp_positionMethod_within_horizontalAccuracyThreshold_android.get(positionMethod)?.count || 0;
      rr.horizontalAccuracyWithinUncertaintyCount =
        eventCache.cp_positionMethod_horizontal_accuracy_uncertainty_android.get(positionMethod)
          ?.count || 0;
      rr.withAltitudeCount =
        eventCache.cp_positionMethod_with_altitude_android.get(positionMethod)?.count || 0;
      rr.verticalAccuracyWithinThresholdCount =
        eventCache.cp_positionMethod_within_verticalAccuracyThreshold_android.get(positionMethod)?.count || 0;
      rr.withVerticalUncertaintyCount =
        eventCache.cp_positionMethod_with_vertical_uncertainty_android.get(positionMethod)?.count || 0;
      rr.verticalUncertaintyCount =
        eventCache.cp_positionMethod_vertical_uncertainty_count_android.get(positionMethod)
          ?.count || 0;

      rr.horizontalAccuracyPercentile1 = Math.round(eventCache.cp_positionMethod_horizontal_accuracy_android
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.horizontalAccuracyPercentile1));

      rr.horizontalAccuracyPercentile2 = Math.round(eventCache.cp_positionMethod_horizontal_accuracy_android
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.horizontalAccuracyPercentile2));

      rr.horizontalLatencyPercentile1 = Math.round(eventCache.cp_positionMethod_horizontal_latency_android
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.latencyPercentile1));

      rr.horizontalLatencyPercentile2 = Math.round(eventCache.cp_positionMethod_horizontal_latency_android
        .get(positionMethod)!
        .getPercentile(this._jobDefinition.latencyPercentile2));

      const android_horizontalUncertaintyValues = eventCache.cp_positionMethod_horizontal_uncertainty_values_android.get(positionMethod);
      rr.horizontalUncertaintyValues = android_horizontalUncertaintyValues;

      const android_verticalUncertaintyValues = eventCache.cp_positionMethod_vertical_uncertainty_values_android.get(positionMethod);
      rr.verticalUncertaintyValues = android_verticalUncertaintyValues;

      const android_horizontalAccuracy = eventCache.cp_positionMethod_horizontal_accuracy_android.get(positionMethod);
      const android_verticalAccuracy = eventCache.cp_positionMethod_vertical_accuracy_android.get(positionMethod);

      rr.horizontalAccuracyVsUncertainty =
        this.correlationCoefficient(android_horizontalAccuracy?.values, android_horizontalUncertaintyValues?.values);
      rr.verticalAccuracyVsUncertainty =
        this.correlationCoefficient(android_verticalAccuracy?.values, android_verticalUncertaintyValues?.values);

      rr.verticalAccuracyValues = eventCache.cp_positionMethod_vertical_accuracy_values_android.get(positionMethod);

      rr.verticalAccuracyPercentile1 = Math.round(android_verticalAccuracy!.getPercentile(this._jobDefinition.verticalAccuracyPercentile1));
      rr.verticalAccuracyPercentile2 = Math.round(android_verticalAccuracy!.getPercentile(this._jobDefinition.verticalAccuracyPercentile2));

      rr.ubpValues = eventCache.cp_positionMethod_ubp_values_android.get(positionMethod);
      rr.ubpCount = eventCache.cp_positionMethod_ubp_count_android.get(positionMethod)?.count || 0;

      reportRecords.push(rr);
    }

    this.writeControlPlanePositionMethodRows(reportRecords, eventCache);
  };

  public writeControlPlanePositionMethodRows = (reportRecords: ReportRecord[], eventCache: EventCache): void => {

    //sort by positionMethod asc and os desc
    reportRecords.sort((a, b) => (a.positionMethod > b.positionMethod) ? 1 :
      (a.positionMethod === b.positionMethod) ? ((a.os < b.os) ? 1 : -1) : -1
    );

    const startRow = 4;
    let row = startRow;
    const data = [];

    for (const record of reportRecords) {
      const rowValues = [
        //col a
        record.positionMethod,
        //col b
        record.os,
        //col c - Horizontal start - count
        record.positionMethodCount,
        //col d - yield%
        this.percent(record.positionMethodCount, record.reportableEventCount),
        //col e 
        this.percent(record.withinHorizontalAccuracyThresholdCount, record.positionMethodCount),
        //col f
        this.formatNumber(record.horizontalAccuracyPercentile1),
        //col g
        this.formatNumber(record.horizontalAccuracyPercentile2),
        //col h
        this.percent(record.horizontalAccuracyWithinUncertaintyCount, record.positionMethodCount),
        //col i
        this.formatNumber(record.horizontalLatencyPercentile1),
        //col j
        this.formatNumber(record.horizontalLatencyPercentile2),
        //col k - Uncertainty start - count
        record.positionMethodCount,
        //col l
        this.formatNumber(record.horizontalUncertaintyValues?.max),
        //col m
        this.formatNumber(record.horizontalUncertaintyValues?.min),
        //col n
        this.formatAverage(record.horizontalUncertaintyValues),
        //col o
        record.horizontalAccuracyVsUncertainty,

        //col p = Ubp start
        record.ubpCount,
        //col q
        this.percent(record.ubpCount, record.positionMethodCount),
        //col r
        this.formatNumber(record.ubpValues?.max),
        //col s
        this.formatNumber(record.ubpValues?.min),
        //col t
        this.formatAverage(record.ubpValues),
      ];

      data.push(rowValues);
      row++;
    }

    this.setCells(startRow, 0, data);
    if (data[0]?.length > 0) {
      this.stylePositionMethodRows(startRow, row, data[0].length);
    }

    this.writeControlPlaneTotalAndErrorRows(eventCache, row);
  };

  private writeControlPlaneTotalAndErrorRows = (
    eventCache: EventCache,
    row: number
  ): void => {
    const iOSTotalSuccessCount = eventCache.cp_total_Success_iOS.count;
    const iOSTotalWithinHorizontalAccuracyThresholdCount = eventCache.cp_within_horizontalAccuracyThreshold_iOS.count;
    const iOSTotalHorizontalAccuracyUncertaintyCount = eventCache.cp_total_horizontal_accuracy_uncertainty_iOS.count;
    const iOSTotalUncertaintyCount = eventCache.cp_total_uncertainty_iOS.count;
    const iOSTotalUbpCount = eventCache.cp_total_ubp_iOS.count;
    const iOSTotalHorizontalUncertaintyValues = eventCache.cp_total_horizontal_uncertainty_values_iOS;

    const androidTotalSuccessCount = eventCache.cp_total_Success_android.count;
    const androidTotalWithinHorizontalAccuracyThresholdCount = eventCache.cp_within_horizontalAccuracyThreshold_android.count;
    const androidTotalHorizontalAccuracyUncertaintyCount = eventCache.cp_total_horizontal_accuracy_uncertainty_android.count;
    const androidTotalUncertaintyCount = eventCache.cp_total_uncertainty_android.count;
    const androidTotalUbpCount = eventCache.cp_total_ubp_android.count;
    const androidTotalHorizontalUncertaintyValues = eventCache.cp_total_horizontal_uncertainty_values_android;

    let iOSErrorCount = 0;
    let androidErrorCount = 0;

    for (const id of eventCache.controlPlaneiOSRecords.ids.values()) {
      if (!eventCache.cp_total_Success_iOS.ids.has(id)) {
        iOSErrorCount++;
      }
    }

    for (const id of eventCache.controlPlaneAndroidRecords.ids.values()) {
      if (!eventCache.cp_total_Success_android.ids.has(id)) {
        androidErrorCount++;
      }
    }

    let iOSMissingCount = 0;
    let androidMissingCount = 0;

    for (const id of eventCache.reportableiOSEvent.ids.values()) {
      if (!eventCache.controlPlaneiOSRecords.ids.has(id)) {
        iOSMissingCount++;
      }
    }

    for (const id of eventCache.reportableAndroidEvent.ids.values()) {
      if (!eventCache.controlPlaneAndroidRecords.ids.has(id)) {
        androidMissingCount++;
      }
    }

    const data = [
      //iOS subtotal data row
      [
        //col a
        "ControlPlane Subtotal",
        //col b
        "iOS",
        //col c
        iOSTotalSuccessCount,
        //col d
        this.percent(iOSTotalSuccessCount, eventCache.reportableiOSEvent.count),

        //col e
        this.percent(iOSTotalWithinHorizontalAccuracyThresholdCount, iOSTotalSuccessCount),
        //col f
        this.formatPercentileValue(eventCache.cp_total_horizontal_accuracy_iOS, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPercentileValue(eventCache.cp_total_horizontal_accuracy_iOS, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(iOSTotalHorizontalAccuracyUncertaintyCount, iOSTotalUncertaintyCount),
        //col i
        this.formatPercentileValue(eventCache.cp_total_latency_iOS, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPercentileValue(eventCache.cp_total_latency_iOS, this._jobDefinition.latencyPercentile2),
        //col k
        iOSTotalUncertaintyCount,
        //col l
        this.formatNumber(iOSTotalHorizontalUncertaintyValues.max),
        //col m
        this.formatNumber(iOSTotalHorizontalUncertaintyValues.min),
        //col n
        this.formatAverage(iOSTotalHorizontalUncertaintyValues),
        //col o 
        this.correlationCoefficient(eventCache.cp_total_horizontal_accuracy_iOS.values, iOSTotalHorizontalUncertaintyValues.values),

        //col p
        iOSTotalUbpCount,
        //col q
        this.percent(iOSTotalUbpCount, iOSTotalSuccessCount),
        //col r
        this.formatNumber(eventCache.cp_total_ubp_values_iOS.max),
        //col s
        this.formatNumber(eventCache.cp_total_ubp_values_iOS.min),
        //col t
        this.formatAverage(eventCache.cp_total_ubp_values_iOS),
      ],
      //Android subtotal data row
      [
        //col a
        "ControlPlane Subtotal",
        //col b
        "Android/Other",
        //col c
        androidTotalSuccessCount,
        //col d
        this.percent(androidTotalSuccessCount, eventCache.reportableAndroidEvent.count),

        //col e
        this.percent(androidTotalWithinHorizontalAccuracyThresholdCount, androidTotalSuccessCount),
        //col f
        this.formatPercentileValue(eventCache.cp_total_horizontal_accuracy_android, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPercentileValue(eventCache.cp_total_horizontal_accuracy_android, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(androidTotalHorizontalAccuracyUncertaintyCount, androidTotalUncertaintyCount),
        //col i
        this.formatPercentileValue(eventCache.cp_total_latency_android, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPercentileValue(eventCache.cp_total_latency_android, this._jobDefinition.latencyPercentile2),
        //col k
        androidTotalUncertaintyCount,
        //col l
        this.formatNumber(androidTotalHorizontalUncertaintyValues.max),
        //col m
        this.formatNumber(androidTotalHorizontalUncertaintyValues.min),
        //col n
        this.formatAverage(androidTotalHorizontalUncertaintyValues),
        //col o 
        this.correlationCoefficient(eventCache.cp_total_horizontal_accuracy_android.values, androidTotalHorizontalUncertaintyValues.values),

        //col p
        androidTotalUbpCount,
        //col q
        this.percent(androidTotalUbpCount, androidTotalSuccessCount),
        //col r
        this.formatNumber(eventCache.cp_total_ubp_values_android.max),
        //col s
        this.formatNumber(eventCache.cp_total_ubp_values_android.min),
        //col t
        this.formatAverage(eventCache.cp_total_ubp_values_android),
      ],
      ["ControlPlane Status Error", "iOS", iOSErrorCount],
      ["ControlPlane Status Error", "Android/Other", androidErrorCount],
      ["No ControlPlane Location", "iOS", iOSMissingCount],
      ["No ControlPlane Location", "Android/Other", androidMissingCount],
    ];

    this.setCells(row, 0, data);
    if (data[0]?.length > 0) {
      this.styleTotalAndErrorRowsForControlPlane(row, data[0].length);
    }
  };

  public writeThunderbirdPositionMethodRows = (eventCache: EventCache, startRow: number): void => {
    let row = startRow;

    const data = [];

    for (const positionMethod of eventCache.tb_positionMethod.positionMethods) {
      const positionMethodCount = eventCache.tb_positionMethod.get(positionMethod)!.count;
      const withinHorizontalAccuracyThresholdCount = eventCache.tb_positionMethod_within_horizontalAccuracyThreshold.get(positionMethod)?.count || 0;
      const horizontalAccuracyWithinUncertaintyCount =
        eventCache.tb_positionMethod_horizontal_accuracy_uncertainty.get(positionMethod)
          ?.count || 0;

      const horizontalAccuracyAverageValues = eventCache.tb_positionMethod_horizontal_accuracy.get(positionMethod)?.values;

      const horizontalUncertainty = eventCache.tb_positionMethod_horizontal_uncertainty_values.get(positionMethod);
      const horizontalUncertaintyCount = horizontalUncertainty?.count || 0;
      const horizontalUncertaintyValues = horizontalUncertainty?.values;

      const rowData = [
        //col a
        positionMethod,
        //col b
        "Android",
        //col c - Horizontal start
        positionMethodCount,
        //col d
        this.percent(positionMethodCount, eventCache.reportableAndroidEvent.count),
        //col e
        this.percent(withinHorizontalAccuracyThresholdCount, eventCache.reportableAndroidEvent.count),
        //col f
        this.formatPositionMethodPercentileValue(eventCache.tb_positionMethod_horizontal_accuracy, positionMethod, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPositionMethodPercentileValue(eventCache.tb_positionMethod_horizontal_accuracy, positionMethod, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(horizontalAccuracyWithinUncertaintyCount, horizontalUncertaintyCount),
        //col i
        this.formatPositionMethodPercentileValue(eventCache.tb_positionMethod_horizontal_latency, positionMethod, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPositionMethodPercentileValue(eventCache.tb_positionMethod_horizontal_latency, positionMethod, this._jobDefinition.latencyPercentile2),
        //col k
        horizontalUncertaintyCount,
        //col l
        this.formatNumber(horizontalUncertainty?.max),
        //col m
        this.formatNumber(horizontalUncertainty?.min),
        //col n
        this.formatAverage(horizontalUncertainty),
        //col o
        this.correlationCoefficient(horizontalAccuracyAverageValues, horizontalUncertaintyValues),
      ];

      data.push(rowData);
      row++;
    }

    this.setCells(startRow, 0, data);
    if (data[0]?.length > 0) {
      this.stylePositionMethodRows(startRow, row, data[0].length);
    }

    this.writeThunderbirdTotalAndErrorRows(eventCache, row);
  };

  private writeThunderbirdTotalAndErrorRows = (
    eventCache: EventCache,
    row: number
  ): void => {
    const totalSuccessCount = eventCache.tb_total_Success.count;
    const totalUncertaintyCount = eventCache.tb_total_uncertainty.count;

    const totalHorizontalUncertaintyValues = eventCache.tb_total_horizontal_uncertainty_values;

    let errorCount = 0;
    for (const id of eventCache.thunderbirdRecords.ids.values()) {
      if (!eventCache.tb_total_Success.ids.has(id)) {
        errorCount++;
      }
    }

    let missingCount = 0;
    for (const id of eventCache.reportableAndroidEvent.ids.values()) {
      if (!eventCache.thunderbirdRecords.ids.has(id)) {
        missingCount++;
      }
    }

    const data = [
      [
        //col a
        "Thunderbird Subtotal",
        //col b
        "Android",
        //col c
        totalSuccessCount,
        //col d
        this.percent(totalSuccessCount, eventCache.reportableAndroidEvent.count),

        //col e
        this.percent(eventCache.tb_within_horizontalAccuracyThreshold.count, totalSuccessCount),
        //col f
        this.formatPercentileValue(eventCache.tb_total_horizontal_accuracy, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPercentileValue(eventCache.tb_total_horizontal_accuracy, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(eventCache.tb_total_horizontal_accuracy_uncertainty.count, totalUncertaintyCount),
        //col i
        this.formatPercentileValue(eventCache.tb_total_latency, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPercentileValue(eventCache.tb_total_latency, this._jobDefinition.latencyPercentile2),
        //col k
        totalUncertaintyCount,
        //col l
        this.formatNumber(totalHorizontalUncertaintyValues.max),
        //col m
        this.formatNumber(totalHorizontalUncertaintyValues.min),
        //col n
        this.formatAverage(totalHorizontalUncertaintyValues),
        //col o 
        this.correlationCoefficient(eventCache.tb_total_horizontal_accuracy.values, totalHorizontalUncertaintyValues.values),
      ],

      ["Thunderbird Status Error", "Android", errorCount],
      ["No Thunderbird Location", "Android", missingCount],
    ];

    this.setCells(row, 0, data);
    if (data[0]?.length > 0) {
      this.styleTotalAndErrorRowsForThunderbird(row, data[0].length);
    }
  };

  public writeSIPRows = (eventCache: EventCache, startRow: number): void => {
    let row = startRow;

    const iOS_totalSuccessCount = eventCache.sip_total_Success_iOS.count;
    const iOS_horizontalUncertaintyCount = eventCache.sip_total_horizontal_uncertainty_values_iOS.count;

    const android_totalSuccessCount = eventCache.sip_total_Success_android.count;
    const android_horizontalUncertaintyCount = eventCache.sip_total_horizontal_uncertainty_values_android.count;

    const data = [
      [
        //col a
        "SIP",
        //col b
        "iOS",
        //col c - Horizontal start
        iOS_totalSuccessCount,
        //col d
        this.percent(eventCache.sip_total_Success_iOS.count, eventCache.reportableiOSEvent.count),
        //col e
        this.percent(eventCache.sip_within_horizontalAccuracyThreshold_iOS.count, iOS_totalSuccessCount),
        //col f
        this.formatPercentileValue(eventCache.sip_total_horizontal_accuracy_iOS, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPercentileValue(eventCache.sip_total_horizontal_accuracy_iOS, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(eventCache.sip_total_horizontal_accuracy_uncertainty_iOS.count, iOS_horizontalUncertaintyCount),
        //col i
        this.formatPercentileValue(eventCache.sip_total_horizontal_latency_iOS, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPercentileValue(eventCache.sip_total_horizontal_latency_iOS, this._jobDefinition.latencyPercentile2),
        //col k
        iOS_horizontalUncertaintyCount,
        //col l
        this.formatNumber(eventCache.sip_total_horizontal_uncertainty_values_iOS.max),
        //col m
        this.formatNumber(eventCache.sip_total_horizontal_uncertainty_values_iOS.min),
        //col n
        this.formatAverage(eventCache.sip_total_horizontal_uncertainty_values_iOS),
        //col o
        this.correlationCoefficient(eventCache.sip_total_horizontal_accuracy_iOS.values, eventCache.sip_total_horizontal_uncertainty_values_iOS.values),
      ],
      [
        //col a
        "SIP",
        //col b
        "Android/Other",
        //col c - Horizontal start
        android_totalSuccessCount,
        //col d
        this.percent(eventCache.sip_total_Success_android.count, eventCache.reportableAndroidEvent.count),
        //col e
        this.percent(eventCache.sip_within_horizontalAccuracyThreshold_android.count, android_totalSuccessCount),
        //col f
        this.formatPercentileValue(eventCache.sip_total_horizontal_accuracy_android, this._jobDefinition.horizontalAccuracyPercentile1),
        //col g
        this.formatPercentileValue(eventCache.sip_total_horizontal_accuracy_android, this._jobDefinition.horizontalAccuracyPercentile2),
        //col h
        this.percent(eventCache.sip_total_horizontal_accuracy_uncertainty_android.count, android_horizontalUncertaintyCount),
        //col i
        this.formatPercentileValue(eventCache.sip_total_horizontal_latency_android, this._jobDefinition.latencyPercentile1),
        //col j
        this.formatPercentileValue(eventCache.sip_total_horizontal_latency_android, this._jobDefinition.latencyPercentile2),
        //col k - Uncertainty start
        android_horizontalUncertaintyCount,
        //col l
        this.formatNumber(eventCache.sip_total_horizontal_uncertainty_values_android.max),
        //col m
        this.formatNumber(eventCache.sip_total_horizontal_uncertainty_values_android.min),
        //col n
        this.formatAverage(eventCache.sip_total_horizontal_uncertainty_values_android),
        //col o
        this.correlationCoefficient(eventCache.sip_total_horizontal_accuracy_android.values, eventCache.sip_total_horizontal_uncertainty_values_android.values),
      ]
    ];

    row++;

    this.setCells(startRow, 0, data);
    if (data[0]?.length > 0) {
      this.stylePositionMethodRows(startRow, row, data[0].length);
    }

    this.writeNoSIPRows(eventCache, row + 1);
  };

  private writeNoSIPRows = (
    eventCache: EventCache,
    row: number
  ): void => {
    //events with no SIP records that are flagged as Used in Report
    let iOS_missingCount = 0;
    let android_missingCount = 0;
    for (const id of eventCache.reportableiOSEvent.ids.values()) {
      if (!eventCache.sipiOSRecords.ids.has(id)) {
        iOS_missingCount++;
      }
    }

    for (const id of eventCache.reportableAndroidEvent.ids.values()) {
      if (!eventCache.sipAndroidRecords.ids.has(id)) {
        android_missingCount++;
      }
    }

    const data = [
      [
        //col a
        "No SIP",
        //col b
        "iOS",
        //col c
        iOS_missingCount,
        //col d
        this.percent(iOS_missingCount, eventCache.reportableiOSEvent.count),
      ],
      [
        //col a
        "No SIP",
        //col b
        "Android/Other",
        //col c
        android_missingCount,
        //col d
        this.percent(android_missingCount, eventCache.reportableAndroidEvent.count),
      ],
    ];

    this.setCells(row, 0, data);
    this.styleRowsForNoSIP(row);
  };

  public writeVerticalRows = (eventCache: EventCache, row: number): void => {
    //cp - ios buckets
    const iOSTotalSuccessCount = eventCache.cp_total_Success_iOS.count;
    const iOSTotalAltitudeCount = eventCache.cp_total_vertical_altitude_iOS.count;
    const iOSTotalVerticalAccuracyWithinUncertaintyCount = eventCache.cp_total_vertical_accuracy_within_uncertainty_iOS.count;
    const iOSTotalVerticalUncertainty = eventCache.cp_total_vertical_uncertainty_iOS;
    const iOSTotalVerticalAccuracyValues = eventCache.cp_total_vertical_accuracy_values_iOS;

    //sip - ios buckets
    const sip_iOS_totalSuccessCount = eventCache.sip_total_Success_iOS.count;
    const sip_iOS_withAltitudeCount = eventCache.sip_total_vertical_altitude_iOS.count;
    const sip_iOS_verticalAccuracyWithinThresholdCount = eventCache.sip_total_vertical_accuracy_within_Threshold_iOS.count;

    //selected vertical - ios buckets
    const selectedVertical_iOS_withAltitudeCount = eventCache.selectedVertical_total_vertical_altitude_iOS.count;
    const selectedVertical_iOS_verticalAccuracyWithinThresholdCount = eventCache.selectedVertical_total_vertical_accuracy_within_Threshold_iOS.count;

    //cp - android buckets
    const androidTotalSuccessCount = eventCache.cp_total_Success_android.count;
    const androidTotalAltitudeCount = eventCache.cp_total_vertical_altitude_android.count;
    const androidTotalVerticalAccuracyWithinThresholdCount = eventCache.cp_total_vertical_accuracy_within_Threshold_android.count;
    const androidTotalVerticalUncertainty = eventCache.cp_total_vertical_uncertainty_android;
    const androidTotalVerticalAccuracyValues = eventCache.cp_total_vertical_accuracy_values_android;

    //tb - buckets
    const tbTotalAltitudeCount = eventCache.tb_total_altitude.count;
    const tbTotalSuccessCount = eventCache.tb_total_Success.count;

    //selected vertical - android buckets
    const selectedVertical_android_withAltitudeCount = eventCache.selectedVertical_total_vertical_altitude_android.count;
    const selectedVertical_android_verticalAccuracyWithinThresholdCount = eventCache.selectedVertical_total_vertical_accuracy_within_Threshold_android.count;

    //selected vertical - all buckets
    const selectedVertical_all_totalSuccessCount = eventCache.iOS_total_Success.count + eventCache.android_total_Success.count;
    const selectedVertical_all_withAltitudeCount = eventCache.selectedVertical_total_vertical_altitude_all.count;
    const selectedVertical_all_verticalAccuracyWithinThresholdCount = eventCache.selectedVertical_total_vertical_accuracy_within_Threshold_all.count;

    const data = [
      //iOS subtotal data row
      [
        //col a
        "iOS CP",
        //col b
        "iOS",
        //col c
        iOSTotalAltitudeCount,
        //col d
        this.percent(iOSTotalAltitudeCount, iOSTotalSuccessCount),

        //col e
        this.percent(eventCache.cp_total_vertical_accuracy_within_Threshold_iOS.count, iOSTotalSuccessCount),
        //col f
        this.percent(eventCache.cp_total_vertical_accuracy_within_Threshold_iOS.count, iOSTotalAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.cp_total_vertical_accuracy_iOS, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.cp_total_vertical_accuracy_iOS, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(iOSTotalVerticalAccuracyValues.max),
        //col j
        this.formatNumber(iOSTotalVerticalAccuracyValues.min),
        //col k
        this.formatAverage(iOSTotalVerticalAccuracyValues),
        //col l
        this.percent(iOSTotalVerticalAccuracyWithinUncertaintyCount, iOSTotalAltitudeCount),

        //col m
        iOSTotalVerticalUncertainty.count,
        //col n
        this.formatNumber(iOSTotalVerticalUncertainty.max),
        //col o
        this.formatNumber(iOSTotalVerticalUncertainty.min),
        //col p
        this.formatAverage(iOSTotalVerticalUncertainty),
        //col q
        this.correlationCoefficient(eventCache.cp_total_vertical_accuracy_iOS.values, eventCache.cp_total_vertical_uncertainty_values_iOS.values),
      ],
      [
        //col a
        "iOS SIP",
        //col b
        "iOS",
        //col c - Vertical start
        sip_iOS_withAltitudeCount,
        //col d
        this.percent(sip_iOS_withAltitudeCount, sip_iOS_totalSuccessCount),
        //col e
        this.percent(sip_iOS_verticalAccuracyWithinThresholdCount, sip_iOS_totalSuccessCount),
        //col f
        this.percent(sip_iOS_verticalAccuracyWithinThresholdCount, sip_iOS_withAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.sip_total_vertical_accuracy_values_iOS, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.sip_total_vertical_accuracy_values_iOS, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(eventCache.sip_total_vertical_accuracy_values_iOS.max),
        //col j
        this.formatNumber(eventCache.sip_total_vertical_accuracy_values_iOS.min),
        //col k
        this.formatAverage(eventCache.sip_total_vertical_accuracy_values_iOS),
        //col l
        this.percent(eventCache.sip_total_vertical_accuracy_within_uncertainty_iOS.count, eventCache.sip_total_vertical_accuracy_values_iOS.count),

        //col m - Uncertainty start
        eventCache.sip_total_vertical_uncertainty_iOS.count,
        //col n
        this.formatNumber(eventCache.sip_total_vertical_uncertainty_values_iOS.max),
        //col o
        this.formatNumber(eventCache.sip_total_vertical_uncertainty_values_iOS.min),
        //col p
        this.formatAverage(eventCache.sip_total_vertical_uncertainty_values_iOS),
        //col q
        this.correlationCoefficient(eventCache.sip_total_vertical_accuracy_iOS.values, eventCache.sip_total_vertical_uncertainty_values_iOS.values),
      ],
      [
        //col a
        "iOS Selected Vertical",
        //col b
        "iOS",
        //col c - Vertical start
        selectedVertical_iOS_withAltitudeCount,
        //col d
        this.percent(selectedVertical_iOS_withAltitudeCount, eventCache.iOS_total_Success.count),
        //col e
        this.percent(selectedVertical_iOS_verticalAccuracyWithinThresholdCount, eventCache.iOS_total_Success.count),
        //col f
        this.percent(selectedVertical_iOS_verticalAccuracyWithinThresholdCount, selectedVertical_iOS_withAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_iOS, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_iOS, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_iOS.max),
        //col j
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_iOS.min),
        //col k
        this.formatAverage(eventCache.selectedVertical_total_vertical_accuracy_values_iOS),
        //col l
        this.percent(
          eventCache.selectedVertical_total_vertical_accuracy_within_uncertainty_iOS.count,
          eventCache.selectedVertical_total_vertical_accuracy_values_iOS.count
        ),

        //col m - Uncertainty start
        eventCache.selectedVertical_total_vertical_uncertainty_iOS.count,
        //col n
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_iOS.max),
        //col o
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_iOS.min),
        //col p
        this.formatAverage(eventCache.selectedVertical_total_vertical_uncertainty_values_iOS),
        //col q
        this.correlationCoefficient(
          eventCache.selectedVertical_total_vertical_accuracy_iOS.values,
          eventCache.selectedVertical_total_vertical_uncertainty_values_iOS.values
        ),
      ],
      //Android CP subtotal data row
      [
        //col a
        "Android/Others CP",
        //col b
        "Android/Others",
        //col c
        androidTotalAltitudeCount,
        //col d
        this.percent(androidTotalAltitudeCount, androidTotalSuccessCount),

        //col e
        this.percent(androidTotalVerticalAccuracyWithinThresholdCount, androidTotalSuccessCount),
        //col f
        this.percent(androidTotalVerticalAccuracyWithinThresholdCount, androidTotalAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.cp_total_vertical_accuracy_android, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.cp_total_vertical_accuracy_android, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(androidTotalVerticalAccuracyValues.max),
        //col j
        this.formatNumber(androidTotalVerticalAccuracyValues.min),
        //col k
        this.formatAverage(androidTotalVerticalAccuracyValues),
        //col l
        this.percent(eventCache.cp_total_vertical_accuracy_within_uncertainty_android.count, androidTotalAltitudeCount),

        //col m
        androidTotalVerticalUncertainty.count,
        //col n
        this.formatNumber(androidTotalVerticalUncertainty.max),
        //col o
        this.formatNumber(androidTotalVerticalUncertainty.min),
        //col p
        this.formatAverage(androidTotalVerticalUncertainty),

        //col q
        this.correlationCoefficient(eventCache.cp_total_vertical_accuracy_android.values, eventCache.cp_total_vertical_uncertainty_values_android.values),

      ],
      //Android TB subtotal data row
      [
        //col a
        "Android TB",
        //col b
        "Android",
        //col c
        tbTotalAltitudeCount,
        //col d
        this.percent(tbTotalAltitudeCount, tbTotalSuccessCount),

        //col e
        this.percent(eventCache.tb_total_vertical_accuracy_within_Threshold.count, tbTotalSuccessCount),
        //col f
        this.percent(eventCache.tb_total_vertical_accuracy_within_Threshold.count, tbTotalAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.tb_total_vertical_accuracy, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.tb_total_vertical_accuracy, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(eventCache.tb_total_vertical_accuracy_values.max),
        //col j
        this.formatNumber(eventCache.tb_total_vertical_accuracy_values.min),
        //col k
        this.formatAverage(eventCache.tb_total_vertical_accuracy_values),
        //col l
        this.percent(eventCache.tb_total_vertical_accuracy_within_uncertainty.count, tbTotalAltitudeCount),

        //col m
        eventCache.tb_total_vertical_uncertainty.count,
        //col n
        this.formatNumber(eventCache.tb_total_vertical_uncertainty_values.max),
        //col o
        this.formatNumber(eventCache.tb_total_vertical_uncertainty_values.min),
        //col p
        this.formatAverage(eventCache.tb_total_vertical_uncertainty_values),
        //col q
        this.correlationCoefficient(eventCache.tb_total_vertical_accuracy.values, eventCache.tb_total_vertical_uncertainty_values.values),
      ],
      [
        //col a
        "Android/Others Selected Vertical",
        //col b
        "Android/Others",
        //col c
        selectedVertical_android_withAltitudeCount,
        //col d
        this.percent(selectedVertical_android_withAltitudeCount, eventCache.android_total_Success.count),

        //col e
        this.percent(selectedVertical_android_verticalAccuracyWithinThresholdCount, eventCache.android_total_Success.count),
        //col f
        this.percent(selectedVertical_android_verticalAccuracyWithinThresholdCount, selectedVertical_android_withAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_android, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_android, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_android.max),
        //col j
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_android.min),
        //col k
        this.formatAverage(eventCache.selectedVertical_total_vertical_accuracy_values_android),
        //col l
        this.percent(
          eventCache.selectedVertical_total_vertical_accuracy_within_uncertainty_android.count,
          eventCache.selectedVertical_total_vertical_accuracy_values_android.count
        ),

        //col m - Uncertainty start
        eventCache.selectedVertical_total_vertical_uncertainty_android.count,
        //col n
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_android.max),
        //col o
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_android.min),
        //col p
        this.formatAverage(eventCache.selectedVertical_total_vertical_uncertainty_values_android),
        //col q
        this.correlationCoefficient(
          eventCache.selectedVertical_total_vertical_accuracy_android.values,
          eventCache.selectedVertical_total_vertical_uncertainty_values_android.values
        ),
      ],
      [
        //col a
        "Total Selected Vertical",
        //col b
        "All",
        //col c
        selectedVertical_all_withAltitudeCount,
        //col d
        this.percent(selectedVertical_all_withAltitudeCount, selectedVertical_all_totalSuccessCount),

        //col e
        this.percent(selectedVertical_all_verticalAccuracyWithinThresholdCount, selectedVertical_all_totalSuccessCount),
        //col f
        this.percent(selectedVertical_all_verticalAccuracyWithinThresholdCount, selectedVertical_all_withAltitudeCount),
        //col g
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_all, this._jobDefinition.verticalAccuracyPercentile1),
        //col h
        this.formatPercentileValue(eventCache.selectedVertical_total_vertical_accuracy_values_all, this._jobDefinition.verticalAccuracyPercentile2),
        //col i
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_all.max),
        //col j
        this.formatNumber(eventCache.selectedVertical_total_vertical_accuracy_values_all.min),
        //col k
        this.formatAverage(eventCache.selectedVertical_total_vertical_accuracy_values_all),
        //col l
        this.percent(
          eventCache.selectedVertical_total_vertical_accuracy_within_uncertainty_all.count,
          eventCache.selectedVertical_total_vertical_accuracy_values_all.count
        ),

        //col m - Uncertainty start
        eventCache.selectedVertical_total_vertical_uncertainty_all.count,
        //col n
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_all.max),
        //col o
        this.formatNumber(eventCache.selectedVertical_total_vertical_uncertainty_values_all.min),
        //col p
        this.formatAverage(eventCache.selectedVertical_total_vertical_uncertainty_values_all),
        //col q
        this.correlationCoefficient(
          eventCache.selectedVertical_total_vertical_accuracy_all.values,
          eventCache.selectedVertical_total_vertical_uncertainty_values_all.values
        ),
      ]
    ];

    this.setCells(row, 0, data);

    if (data[0]?.length > 0) {
      this.styleTotalRowsForVertical(row, data[0].length);
    }
  };

  private stylePositionMethodRows = (startRow: number, endRow: number, valuesLength: number) => {
    for (let i = startRow; i <= endRow; i++) {
      for (let j = 0; j < valuesLength; j++) {
        this.setCellDefaultValueStyles(i, j);
        this.setCellStyleBorder(i, j);
      }
    }
  };

  private styleTotalAndErrorRowsForControlPlane = (startRow: number, valuesLength: number) => {
    let row = startRow;

    //set styles for CP Subtotal iOS & Android
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < valuesLength; j++) {
        this.setCellDefaultValueStyles(row, j);
        this.setCellStyleBorder(row, j);
      }
      row++;
    }

    //set styles for status error iOS & android
    //set styles for no location iOS & android
    for (let i = 0; i < 4; i++) {
      this.setCellDefaultValueStyles(row, 0);  //col a
      this.setCellStyleBorder(row, 0);
      this.setCellDefaultValueStyles(row, 1);  //col b
      this.setCellStyleBorder(row, 1);
      this.setCellDefaultValueStyles(row, 2);  //col c
      this.setCellStyleBorder(row, 2);
      row++;
    }
  };

  private styleTotalAndErrorRowsForThunderbird = (startRow: number, valuesLength: number) => {
    let row = startRow;

    //set styles for TB Subtotal Android
    for (let j = 0; j < valuesLength; j++) {
      this.setCellDefaultValueStyles(row, j);
      this.setCellStyleBorder(row, j);
    }
    row++;

    //set styles for status error android
    //set styles for no location android
    for (let i = 0; i < 2; i++) {
      this.setCellDefaultValueStyles(row, 0);  //col a
      this.setCellStyleBorder(row, 0);
      this.setCellDefaultValueStyles(row, 1);  //col b
      this.setCellStyleBorder(row, 1);
      this.setCellDefaultValueStyles(row, 2);  //col c
      this.setCellStyleBorder(row, 2);
      row++;
    }
  };

  private styleRowsForNoSIP = (startRow: number) => {
    let row = startRow;

    //set styles for No SIP iOS/Android
    for (let i = 0; i < 2; i++) {
      this.setCellDefaultValueStyles(row, 0);  //col a
      this.setCellStyleBorder(row, 0);
      this.setCellDefaultValueStyles(row, 1);  //col b
      this.setCellStyleBorder(row, 1);
      this.setCellDefaultValueStyles(row, 2);  //col c
      this.setCellStyleBorder(row, 2);
      this.setCellDefaultValueStyles(row, 3);  //col d
      this.setCellStyleBorder(row, 3);
      row++;
    }
  };

  private styleTotalRowsForVertical = (startRow: number, valuesLength: number) => {
    let row = startRow;

    //set styles for Vertical rows
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < valuesLength; j++) {
        this.setCellDefaultValueStyles(row, j);
        this.setCellStyleBorder(row, j);
      }
      row++;
    }
  };

  private percent = (numerator: number, denominator: number): string => {
    if (denominator === 0) {
      return "n/a";
    }

    const result = (numerator / denominator) * 100;
    return `${result.toFixed(2)}%`;
  };

  private getOrdinal = (i: number): string => {
    if (i >= 11 && i <= 19) {
      return `${i}th`;
    } else {
      const lastDigit = i % 10;
      switch (lastDigit.toString()) {
        case "1":
          return `${i}st`;
        case "2":
          return `${i}nd`;
        case "3":
          return `${i}rd`;
        default:
          return `${i}th`;
      }
    }
  };

  /*
   x = accuracy
   y = uncertainty
   https://github.com/rubenvar/calculate-correlation
   https://www.wallstreetmojo.com/pearson-correlation-coefficient/
   */
  private correlationCoefficient = (
    accuracy: number[] | undefined,
    uncertainty: number[] | undefined
  ) => {
    if (accuracy && uncertainty && accuracy.length > 1 && uncertainty.length > 1) {
      try {
        const calculateCorrelation = require("calculate-correlation");
        const pearsonCorrelation = calculateCorrelation(accuracy, uncertainty);

        if (!isNaN(pearsonCorrelation)) {
          return pearsonCorrelation.toFixed(2);
        }

      } catch {
        return "n/a";
      }
    }

    return "n/a";
  };

  private formatPositionMethodPercentileValue = (
    columnValue: EventSmallestValueListPerPositionMethod,
    positionMethod: string,
    percentileValue: number,
  ) => {
    const value = columnValue.get(positionMethod);

    return value === null || value.count === 0 ?
      "n/a" :
      Math.round(value.getPercentile(percentileValue));
  }

  private formatPercentileValue = (
    columnValue: EventSmallestValueList,
    percentileValue: number,
  ) => {
    return columnValue.count === 0 ?
      "n/a" :
      Math.round(columnValue.getPercentile(percentileValue));
  }

  private formatNumber = (columnValue: number | undefined | null) => {
    return !columnValue || columnValue === 0 ? "n/a" : Math.round(columnValue);
  }

  private formatAverage = (columnValue: EventSmallestValueList | null) => {
    return !columnValue || columnValue.avg === 0 ? "n/a" : columnValue.avg.toFixed(2);
  }

}
