import { CustomCellRendererProps } from "ag-grid-react";
import ReportDefinitionSummary from "../../../../services/models/ReportDefinitions/ReportDefinitionSummary";
import { useUserContext } from "../../../../contexts/UserContext";
import { appPermissions } from "../../../auth/roles";
import DropdownIconButton from "../DropdownIconButton";
import { deleteReportDefinition, getReportDefinition } from "../../../../services/reportDefinitionService";
import IconButton from "../../shared/IconButton";
import ReportDefinition from "../../../../services/models/ReportDefinitions/ReportDefinition";
import { theme } from "../../../../utilities/constants";

type Props = CustomCellRendererProps<ReportDefinitionSummary, number> & {
  onLoad: (reportDefinition: ReportDefinition) => Promise<void> | void;
  onDelete: (id: number) => Promise<void> | void;
  onError: (errors: string[]) => Promise<void> | void;
};

const ActionButtonRenderer = ({ data, onLoad, onDelete, onError }: Props) => {
  const { currentUser, userHasAppPermission } = useUserContext();
  const canDelete =
    data?.owner === currentUser?.localAccountId ||
    userHasAppPermission(appPermissions.reportPage.reportDefinitions.delete);

  const handleLoadClick = async () => {
    const response = await getReportDefinition(data!.id);

    if (response.error) {
      console.error(response.error.join("\n"));
      onError(response.error);
    } else {
      onLoad(response.data!);
    }
  };

  const handleDeleteClick = async () => {
    const response = await deleteReportDefinition(data!.id);

    if (response.error) {
      console.error(response.error.join("\n"));
      onError(response.error);
    } else {
      onDelete(data!.id);
    }
  };

  return (
    <span className="d-flex justify-content-between">
      <IconButton iconProp="file-export" hoverColor={theme.colors.green} title="Load" onClick={handleLoadClick} />

      {canDelete && (
        <DropdownIconButton
          actionText="Delete"
          dropdownId={`delete-action-${data?.id}`}
          icon="trash"
          hoverColor="#dc3545"
          onActionClick={handleDeleteClick}
        />
      )}
    </span>
  );
};

export default ActionButtonRenderer;
