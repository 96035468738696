import React from "react";
import { Navigate } from "react-router-dom";
import { AuthCheck, AuthCheckProps } from "./AuthCheck";

type Props = Omit<AuthCheckProps, "deny">;

const AuthorizedRoute = (props: Props) => {
  return (
    <AuthCheck {...props} deny={() => <Navigate to="/401" replace/>} />
  );
};

export default AuthorizedRoute;;