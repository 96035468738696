import React, { useCallback, useEffect, useState, MouseEvent, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import { Button, Form } from "react-bootstrap";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getMtlrSets,
  deleteMtlrSet,
  getMtlrSet,
  pauseMtlrSet,
  startMtlrSet,
  stopMtlrSet,
} from "../../../services/apiService";
import { MtlrSetWithDisplayName } from "../../../services/models/MtlrSetWithDisplayName";
import {
  dateFormatter,
  statusGetter,
  msisdnFormatter,
  userFormatter,
  StatusStates,
  intervalFormatter,
  timeZoneFormatter,
  countGetter,
} from "./mtlrHelpers";
import "./MtlrSet.css";
import DeleteButton from "../shared/DeleteButton";
import DeleteModal from "./DeleteModal";
import AddModal from "./AddModal";
import StopModal from "./StopModal";
import { DateTime } from "luxon";
import ExpandButton from "./ExpandButton";
import GmlcData from "./GmlcData";
import { GmlcDataProvider } from "../Mtlr/GmlcDataProvider";
import { ColDef, ICellRendererParams, SelectionChangedEvent } from "ag-grid-community";
import TooltipIcon from "../shared/TooltipIcon";
import ControlButton from "./ControlButton";
import { useNavigate } from "react-router-dom";
import SpinnerButton from "../shared/SpinnerButton";
import MtlrSetResponse from "../../../services/responses/MtlrSetResponse";
import { ApiResponse } from "../../../services/responses/ApiResponse";
import { MTLR_DATA_FOR_REPORT_SESSION_STORAGE } from "../shared/helpers";
import MtlrToReport, { PartialMtlr } from "../shared/MtlrToReport";
import ReportModal from "./ReportModal";
import { PauseMtlrSetRequest } from "../../../services/requests/PauseMtlrSetRequest";
import { theme } from "../../../utilities/constants";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GridGmlcData = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const GridStyle = styled.div`
  grid-area: grid;
  flex: 1;
  max-width: 1465px;
  height: 97%;
  margin-bottom: 5px;
`;

const GridWrapper = styled.div.attrs({ className: "ag-theme-balham" })`
  height: 100%;
  font-size: 14px;
`;

type MessageProps = {
  $isError?: boolean;
};

const Message = styled.span<MessageProps>`
  color: ${(props) => (props.$isError ? "#dc3545" : theme.colors.green)};
  margin-left: 10px;
`;

const ToggleButtonDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
`;

const BulkActionButtonDiv = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const SwitchLabel = styled.span`
  padding-right: 5px;
`;

const ScheduledIcon = styled.span`
  color: #6c757d;
`;

const ScheduledToolTipIcon = styled.span`
  color: #6c757d;
  width: 15px
`;

const ScheduledButtonText = styled.span`
  margin-left: 10px;
`;

const ScheduledDescription = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const OnDemandButtonContainer = styled.div`
  margin-top: 10px;
`;

const OnDemandButtonText = styled.span`
  margin-left: 8px;
`;

const OnDemandNotStarted = styled.span`
  color: #adb5bd;
`;

const PlayIcon = styled.span`
  color: ${theme.colors.green};
`;

const PlayToolTipIcon = styled.span`
  color: ${theme.colors.green};
  width: 15px
`;

const PauseIcon = styled.span`
  color: #0d6efd;
`;

const PauseToolTipIcon = styled.span`
  color: #0d6efd;
  width: 15px
`;

const ToolTipRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const CirlcePlayIcon = styled.span`
  color: #dc3545  
`;

const CirlcePlayTooltTipIcon = styled.span`
  color: #dc3545;
  width: 15px;
`;

const PowerOffIcon = styled.span`
  color: #dc3545  
`;

const PowerOffToolTipIcon = styled.span`
  color: #dc3545;  
  width: 15px;
`;

const PowerOffDisabledToolTipIcon = styled.span`
  color: #adb5bd;  
  width: 15px;
`;

const ReportActive = styled.span`
  color: #0d6efd;
  width: 15px;
`;

const ReportDisabled = styled.span`
  color: #adb5bd;
`;

const DeleteIcon = styled.span`
  color: #dc3545;
  width: 15px;
`;

const ReportHeader = () => {
  return (
    <TooltipIcon iconStyles={{ marginLeft: 0 }} className={"Mtrl-tooltip"} muted={false} content={
      <>
        <ToolTipRow><ReportActive><FontAwesomeIcon icon={icon({ name: "file-lines" })} /></ReportActive> Run a report for this MTLR Set</ToolTipRow>
        <ToolTipRow><ReportDisabled><FontAwesomeIcon icon={icon({ name: "file-lines" })} /></ReportDisabled> Report link becomes Active 15 minutes after MTLR Set End Time</ToolTipRow>
      </>
    } />
  );
};

const RunTypeHeader = () => {
  return (
    <TooltipIcon iconStyles={{ marginLeft: 0 }} className={"Mtrl-tooltip"} muted={false} content={
      <>
        <ToolTipRow><ScheduledToolTipIcon><FontAwesomeIcon icon={icon({ name: "calendar-days" })} /></ScheduledToolTipIcon>Scheduled Set: Runs on schedule</ToolTipRow>
        <ToolTipRow><PowerOffDisabledToolTipIcon><FontAwesomeIcon icon={icon({ name: "power-off" })} /></PowerOffDisabledToolTipIcon>On-Demand Set: Runs when started</ToolTipRow>
        <ToolTipRow><PowerOffToolTipIcon><FontAwesomeIcon icon={icon({ name: "power-off" })} /></PowerOffToolTipIcon>On-Demand Set: Stop current run</ToolTipRow>
      </>
    } />
  );
};

const ControlHeader = () => {
  return (
    <TooltipIcon iconStyles={{ marginLeft: 0 }} className={"Mtrl-tooltip"} muted={false} content={
      <>
        <ToolTipRow><PauseToolTipIcon><FontAwesomeIcon icon={icon({ name: "pause" })} /></PauseToolTipIcon>Pause Set, keep stats</ToolTipRow>
        <ToolTipRow><PlayToolTipIcon><FontAwesomeIcon icon={icon({ name: "play" })} /></PlayToolTipIcon>Resume Set, keep stats</ToolTipRow>
        <ToolTipRow><CirlcePlayTooltTipIcon><FontAwesomeIcon icon={icon({ name: "circle-play" })} /></CirlcePlayTooltTipIcon>Start On-Demand, clear stats</ToolTipRow>
      </>
    } />
  );
};

const MtlrSet = () => {
  const isAuthenticated = useIsAuthenticated();
  const autoUpdateInterval = useRef<number>();
  const { accounts } = useMsal();
  const [mtlrSets, setMtlrSets] = useState<MtlrSetWithDisplayName[] | null>(null);
  const [bulkMtlrToReport, setBulkMtlrToReport] = useState<MtlrToReport | null>(null);
  const gridApiRef = useRef<AgGridReact>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showStopModal, setShowStopModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [gmlcDataLoading, setGmlcDataLoading] = useState<boolean>(true);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [addIsScheduled, setAddIsScheduled] = useState<boolean>(true);
  const [gridIdOfRowToBeDeleted, setGridIdOfRowToBeDeleted] = useState<string | null>(null);
  const [isCreatingUserDelete, setIsCreatingUserDelete] = useState<boolean>(false);
  const [creatingUserSets, setCreatingUserSets] = useState<number | undefined>();
  const [otherUserSets, setOtherUserSets] = useState<number | undefined>();
  const [gridIdOfRowToBeStopped, setGridIdOfRowToBeStopped] = useState<string | null>(null);
  const [isBulkPause, setIsBulkPause] = useState<boolean>(false);
  const [isBulkResume, setIsBulkResume] = useState<boolean>(false);
  const [isBulkStart, setIsBulkStart] = useState<boolean>(false);
  const [isBulkComplete, setIsBulkComplete] = useState<boolean>(false);
  const [isBulkReport, setIsBulkReport] = useState<boolean>(false);
  const [isBulkDelete, setIsBulkDelete] = useState<boolean>(false);
  const [reportEligibleCount, setReportEligibleCount] = useState<number>(0);
  const [reportIneligibleCount, setReportIneligibleCount] = useState<number>(0);
  const navigate = useNavigate();
  const anySelected = mtlrSets?.some(s => s.selected);
  const bulkActionInProgress = isBulkPause || isBulkResume || isBulkStart || isBulkComplete || isBulkReport || isBulkDelete;

  const [messageState, setMessageState] = useState({
    message: "",
    isError: false,
  });

  const [gmlcDataForMtlrSet, setGmlcDataForMtlrSet] = useState({
    id: -1,
    msisdn: "",
    startTime: "",
    lastRequest: "",
    lastResponse: "",
  });

  const [showOnlyMySets, setShowOnlyMySets] = useState<boolean>(
    () => sessionStorage.getItem("showOnlyMySets") === "true"
  );

  const [showCompletedSets, setShowCompletedSets] = useState<boolean>(() => {
    //by default this toggle is on (true)
    if (sessionStorage.getItem("showCompletedSets")) {
      return sessionStorage.getItem("showCompletedSets") === "true";
    } else {
      return true;
    }
  });

  const [autoUpdate, setAutoUpdate] = useState<boolean>(() => {
    //by default this toggle is on (true)
    if (sessionStorage.getItem("autoUpdate")) {
      return sessionStorage.getItem("autoUpdate") === "true";
    } else {
      return true;
    }
  });

  const fadeStyles = useSpring({
    config: config.molasses,
    from: { opacity: 1 },
    to: {
      opacity: messageState.message ? 0 : 1,
    },
    delay: 5000,
  });

  const fetchMtlrSets = useCallback(async () => {
    if (isAuthenticated) {
      let selectedRows: MtlrSetWithDisplayName[] = [];

      if (gridApiRef.current && gridApiRef.current.api) {
        //grab the selected rows
        selectedRows = gridApiRef.current.api.getSelectedRows();
      }

      const response = await getMtlrSets();

      if (response.hasError || !response.data) {
        if (response.error) {
          setMessage(response.error.join(" "), true);
        } else {
          setMessage("Fetching MtlrSets Failed", true);
        }

        setMtlrSets(null);
      } else {
        setMessage("");

        let gridData = response.data.mtlrSetsWithDisplayName;

        //set the previously selected rows
        gridData = gridData.map(g => ({
          ...g, selected: selectedRows.findIndex(f => f.id === g.id) !== -1
        }));

        setMtlrSets(gridData);
      }
    }
  }, [isAuthenticated]);

  const fetchMtlrSet = useCallback(async () => {
    if (isAuthenticated && gmlcDataForMtlrSet.id > 0) {
      const response = await getMtlrSet(gmlcDataForMtlrSet.id);

      if (response.hasError || !response.data) {
        console.error(response.data);
        setGmlcDataForMtlrSet((prev) => {
          return {
            ...prev,
            lastRequest: "Error occurred getting request",
            lastResponse: "Error occurred getting response",
          };
        });
      } else {
        setGmlcDataForMtlrSet((prev) => {
          if (response && response.data) {
            return {
              ...prev,
              lastRequest: response.data.lastRequest,
              lastResponse: response.data.lastResponse,
            };
          } else {
            return prev;
          }
        });
      }
    }
  }, [isAuthenticated, gmlcDataForMtlrSet]);

  useEffect(() => {
    fetchMtlrSets();
  }, [fetchMtlrSets]);

  const statusCellClass = (params: any) => {
    if (params.value === StatusStates.Completed) {
      return "cell-completed";
    }

    if (params.value === StatusStates.Running) {
      return "cell-running";
    }

    if (params.value === StatusStates.NotStarted) {
      return "cell-not-started";
    }

    if (params.value === StatusStates.Paused) {
      return "cell-paused";
    }

    return "";
  };

  const runTypeColumnCellRenderer = (params: ICellRendererParams<MtlrSetWithDisplayName>) => {
    if (params.data) {
      if (params.data.isScheduled) {
        return (<ScheduledIcon><FontAwesomeIcon icon={icon({ name: "calendar-days" })} /></ScheduledIcon>);
      } else {
        const now = DateTime.now().setZone("utc");

        const startTimeUtc = params.data.startTimeUtc === null
          ? params.data.startTimeUtc
          : DateTime.fromISO(params.data.startTimeUtc.toString(), { zone: "utc" });

        const endTimeUtc = params.data.endTimeUtc === null
          ? params.data.endTimeUtc
          : DateTime.fromISO(params.data.endTimeUtc.toString(), { zone: "utc" });

        if (startTimeUtc === null && endTimeUtc === null) {
          return (
            <OnDemandNotStarted>
              <FontAwesomeIcon icon={icon({ name: "power-off" })} />
            </OnDemandNotStarted>
          );
        }

        if (startTimeUtc < now && endTimeUtc === null) {
          return (<ControlButton iconName="power-off" onClick={() => handlePowerOffClick(params)} ></ControlButton>);
        }

        if (startTimeUtc < now && endTimeUtc < now) {
          return (
            <OnDemandNotStarted>
              <FontAwesomeIcon icon={icon({ name: "power-off" })} />
            </OnDemandNotStarted>
          );
        }
      }
    }

    return null;
  };

  const controlColumnCellRenderer = (params: ICellRendererParams<MtlrSetWithDisplayName>) => {
    const now = DateTime.now().setZone("utc");

    if (params.data) {

      const startTime = params.data.startTimeUtc === null ? params.data.startTimeUtc : DateTime.fromISO(params.data.startTimeUtc.toString(), {
        zone: "utc",
      });

      const endTime = params.data.endTimeUtc === null ? params.data.endTimeUtc : DateTime.fromISO(params.data.endTimeUtc.toString(), {
        zone: "utc",
      });

      if (params.data.isScheduled) {
        if (startTime < now && endTime > now && params.data.isPaused === false) {
          return (<ControlButton iconName="pause" onClick={() => handlePauseClick(params)} ></ControlButton>);
        }

        if (startTime < now && endTime > now && params.data.isPaused === true) {
          return (<ControlButton iconName="play" onClick={() => handlePlayClick(params)} ></ControlButton>);
        }
      } else {
        if (startTime === null && endTime === null) {
          return (<ControlButton iconName="circle-play" onClick={() => handleCirclePlayClick(params)} ></ControlButton>);
        }

        if (startTime < now) {
          if (endTime === null && params.data.isPaused === false) {
            return (<ControlButton iconName="pause" onClick={() => handlePauseClick(params)} ></ControlButton>);
          }

          if (endTime === null && params.data.isPaused === true) {
            return (<ControlButton iconName="play" onClick={() => handlePlayClick(params)} ></ControlButton>);
          }

          if (endTime < now) {
            return (<ControlButton iconName="circle-play" onClick={() => handleCirclePlayClick(params)} ></ControlButton>);
          }
        }
      }
    }

    return null;
  };

  const reportColumnCellRenderer = (params: ICellRendererParams<MtlrSetWithDisplayName>) => {
    const status = statusGetter(params);

    if (status === StatusStates.Completed) {
      const now = DateTime.now().setZone("utc");
      const endTime = DateTime.fromISO(params.data!.endTimeUtc.toString(), {
        zone: "utc",
      }).plus({ minutes: 15 });

      return (<ControlButton iconName="file-lines" onClick={() => handleReportClick(params)} disabled={now < endTime} ></ControlButton>);
    }

    return null;
  };

  const checkBoxColumnCellRenderer = (params: ICellRendererParams<MtlrSetWithDisplayName>) => {
    if (params.data && params.data.id) {
      if (gridApiRef.current && gridApiRef.current.api && params.data.selected) {
        const node = gridApiRef.current.api.getRowNode(params.data.id.toString());

        if (node) {
          //tell ag-grid that this row is selected
          node.setSelected(true);
        }
      }
    }
  };

  const handleExpandClick = async (e: MouseEvent<HTMLButtonElement>, params: any) => {
    if (params === null || gmlcDataForMtlrSet.id === params.data.id) {
      setGmlcDataForMtlrSet({
        id: -1,
        msisdn: "",
        startTime: "",
        lastRequest: "",
        lastResponse: "",
      });
    } else {
      setGmlcDataLoading(true);

      setGmlcDataForMtlrSet({
        id: params.data.id,
        msisdn: params.data.msisdn,
        startTime: params.data.startTimeUtc,
        lastRequest: "",
        lastResponse: "",
      });

      const response = await getMtlrSet(params.data.id);

      if (response.hasError || !response.data) {
        console.error(response.data);
        setGmlcDataForMtlrSet((prev) => {
          return {
            ...prev,
            lastRequest: "Error occurred getting request",
            lastResponse: "Error occurred getting response",
          };
        });
      } else {
        setGmlcDataForMtlrSet((prev) => {
          if (response && response.data) {
            return {
              ...prev,
              lastRequest: response.data.lastRequest,
              lastResponse: response.data.lastResponse,
            };
          } else {
            return prev;
          }
        });
      }

      setGmlcDataLoading(false);
    }
  };

  const handleAddScheduledClick = () => {
    setShowAddModal(true);
    setAddIsScheduled(true);
  };

  const handleAddOnDemandClick = () => {
    setShowAddModal(true);
    setAddIsScheduled(false);
  };

  const handleDeleteModal = async () => {
    setShowDeleteModal(false);

    if (isBulkDelete) {
      bulkDeleteMtlrSetsAndRemoveRowsFromGrid();
    } else {
      deleteMtlrSetAndRemoveRowFromGrid();
    }
  };

  const handleStopModal = async () => {
    setShowStopModal(false);

    if (isBulkComplete) {
      bulkCompleteMtlrSets();
    } else {
      stopRunningMtlrSet(gridIdOfRowToBeStopped);
    }
  };

  const handleReportModal = async () => {
    setShowReportModal(false);

    sessionStorage.setItem(MTLR_DATA_FOR_REPORT_SESSION_STORAGE, JSON.stringify(bulkMtlrToReport));

    navigate("/report");
  };

  const handleAddModal = async (newSets: MtlrSetWithDisplayName[]) => {
    setShowAddModal(false);
    setMessage("");

    setMtlrSets(prev => (prev ? [...prev, ...newSets] : [...newSets]));
  };

  const setMessageTimeout = () => {
    //after x seconds this will "unmount" the message to allow animation to run again
    setTimeout(() => {
      setMessage("");
    }, 6000);
  };

  const setMessage = (message: string, isError: boolean = false) => {
    setMessageState({ message: message, isError: isError });
  };

  const handlePauseClick = async (params: any) => {
    const selectedRow = gridApiRef.current!.api.getRowNode(params.node.id);

    if (selectedRow) {
      gridApiRef.current!.api.clearFocusedCell();

      //call api to pause the record
      const response = await pauseMtlrSet(selectedRow.data.id, new PauseMtlrSetRequest(true));

      if (response?.hasError) {
        setMessage("Pause click failed", true);
      } else {
        if (response.data) {
          updateGridRowData(params.node.id, response.data.mtlrSetWithDisplayName);
        }

        setMessage("Request paused");
      }

      setMessageTimeout();
    }
  };

  const handlePlayClick = async (params: any) => {
    const selectedRow = gridApiRef.current!.api.getRowNode(params.node.id);

    if (selectedRow) {
      gridApiRef.current!.api.clearFocusedCell();

      //call api to unpause the record
      const response = await pauseMtlrSet(selectedRow.data.id, new PauseMtlrSetRequest(false));

      if (response?.hasError) {
        setMessage("Play click failed", true);
      } else {
        if (response.data) {
          updateGridRowData(params.node.id, response.data.mtlrSetWithDisplayName);
        }

        setMessage("Request unpaused");
      }

      setMessageTimeout();
    }
  };

  const handleCirclePlayClick = async (params: any) => {
    const selectedRow = gridApiRef.current!.api.getRowNode(params.node.id);

    if (selectedRow) {
      gridApiRef.current!.api.clearFocusedCell();

      //call api to start the record
      const response = await startMtlrSet(selectedRow.data.id);

      if (response?.hasError) {
        setMessage("Circle Play click failed", true);
      } else {
        if (response.data) {
          updateGridRowData(params.node.id, response.data.mtlrSetWithDisplayName);
        }

        setMessage("Request playing");
      }

      setMessageTimeout();
    }
  };

  const onGridSelectionChange = (params: SelectionChangedEvent<MtlrSetWithDisplayName>) => {
    const selectedRows = params.api.getSelectedRows();

    //need to set the selected state or the grid and data get out of sync
    setMtlrSets(prev => prev?.map(g => ({
      ...g, selected: selectedRows.findIndex(f => f.id === g.id) !== -1
    })) ?? null);
  };

  const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>, params: any) => {
    //clear the focus so there is not the ghost of the invisible row being highlighted
    gridApiRef.current!.api.clearFocusedCell();

    setGridIdOfRowToBeDeleted(params.node.id);
    setIsCreatingUserDelete(accounts[0].localAccountId === params.data.creatingUser);
    //the sets are only used when doing a bulk delete
    setCreatingUserSets(undefined);
    setOtherUserSets(undefined);

    //show a "are you sure" modal?
    setShowDeleteModal(true);
  };

  const handlePowerOffClick = (params: any) => {
    //clear the focus so there is not the ghost of the invisible row being highlighted
    gridApiRef.current!.api.clearFocusedCell();

    setGridIdOfRowToBeStopped(params.node.id);

    //show a "are you sure" modal?
    setShowStopModal(true);
  };

  const handleReportClick = (params: ICellRendererParams<MtlrSetWithDisplayName>) => {
    if (params.data) {

      const mtlr: PartialMtlr = {
        msisdn: params.data.msisdn,
        startTimeUtc: params.data.startTimeUtc.toString(),
        endTimeUtc: params.data.endTimeUtc.toString(),
        timeZone: params.data.timeZone,
      };

      const mtlrToReport = new MtlrToReport();
      mtlrToReport.partialMtlr.push(mtlr);

      const mtlrToReportJSON = JSON.stringify(mtlrToReport);

      // Save data to sessionStorage
      sessionStorage.setItem(MTLR_DATA_FOR_REPORT_SESSION_STORAGE, mtlrToReportJSON);

      navigate("/report");
    }
  };

  const handleBulkPauseClick = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      setIsBulkPause(true);

      //grab the selected rows
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        await makeBulkApiRequests(buildBulkPausePromises(selectedRows, true));
      }
    }

    setIsBulkPause(false);
  };

  const handleBulkResumeClick = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      setIsBulkResume(true);

      //grab the selected rows
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        await makeBulkApiRequests(buildBulkPausePromises(selectedRows, false));
      }
    }

    setIsBulkResume(false);
  };

  const buildBulkPausePromises = (selectedRows: MtlrSetWithDisplayName[], pause: boolean) => {
    return selectedRows.map((r, i) =>
      pauseMtlrSet(r.id, new PauseMtlrSetRequest(pause)).then((response) => {
        return response;
      })
    );
  };

  const handleBulkStartClick = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      setIsBulkStart(true);

      //grab the selected rows
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        const promises = selectedRows.map((r, i) =>
          startMtlrSet(r.id).then((response) => {
            return response;
          }));

        await makeBulkApiRequests(promises);
      }
    }

    setIsBulkStart(false);
  };

  const handleBulkCompleteClick = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      //grab the selected rows
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        setIsBulkComplete(true);

        setShowStopModal(true);
      }
    }
  };

  const handleBulkReportClick = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      setIsBulkReport(true);

      const selectedRows = gridApiRef.current.api.getSelectedRows();
      if (selectedRows.length > 0) {
        const now = DateTime.utc();

        let eligible = 0;
        let ineligible = 0;
        const mtlrToReport = new MtlrToReport();

        for (const row of selectedRows) {
          const startTimeUtc = row.startTimeUtc === null
            ? row.startTimeUtc
            : DateTime.fromISO(row.startTimeUtc.toString(), { zone: "utc" });

          //have to wait 15 minutes after "endTime" for splunk records to be present
          const endTimeUtc = row.endTimeUtc === null
            ? row.endTimeUtc
            : DateTime.fromISO(row.endTimeUtc.toString(), { zone: "utc" }).plus({ minutes: 15 });

          if (row.isScheduled) {
            if (now >= endTimeUtc) {
              eligible++;

              mtlrToReport.partialMtlr.push(buildPartialMtlr(row));
            } else {
              ineligible++;
            }
          } else if ((startTimeUtc !== null && endTimeUtc !== null)
            && startTimeUtc <= now && now >= endTimeUtc && row.isPaused === false) {
            eligible++;

            mtlrToReport.partialMtlr.push(buildPartialMtlr(row));

          } else {
            ineligible++;
          }
        }

        if (ineligible === 0) {
          //build the report session state and send user to report page
          const mtlrToReportJSON = JSON.stringify(mtlrToReport);
          // Save data to sessionStorage
          sessionStorage.setItem(MTLR_DATA_FOR_REPORT_SESSION_STORAGE, mtlrToReportJSON);

          setIsBulkReport(false);

          navigate("/report");

        } else {
          //not all mtlrSets selected are eligible for Report, show modal
          setReportEligibleCount(eligible);
          setReportIneligibleCount(ineligible);
          setIsBulkReport(false);
          setBulkMtlrToReport(mtlrToReport);
          setShowReportModal(true);
        }
      }
    };

    setIsBulkReport(false);
  };

  const buildPartialMtlr = (row: MtlrSetWithDisplayName) => {
    const partialMtlr: PartialMtlr = {
      msisdn: row.msisdn,
      startTimeUtc: row.startTimeUtc.toString(),
      endTimeUtc: row.endTimeUtc.toString(),
      timeZone: row.timeZone,
    };

    return partialMtlr;
  };

  const handleBulkDeleteClick = () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        setIsBulkDelete(true);

        let currentUserCount = 0;
        let otherUserCount = 0;

        for (const row of selectedRows) {
          if (row.creatingUser === accounts[0].localAccountId) {
            currentUserCount++;
          } else {
            otherUserCount++;
          }
        }

        setCreatingUserSets(currentUserCount);
        setOtherUserSets(otherUserCount);

        setShowDeleteModal(true);
      }
    }
  };

  const bulkDeleteMtlrSetsAndRemoveRowsFromGrid = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        const promises = selectedRows.map((r, i) =>
          deleteMtlrSet(r.id).then((response) => {
            return response;
          }));

        await makeBulkApiRequests(promises, true);
      }
    }

    setIsBulkDelete(false);
  };

  const bulkCompleteMtlrSets = async () => {
    if (gridApiRef.current && gridApiRef.current.api) {
      const selectedRows = gridApiRef.current.api.getSelectedRows();

      if (selectedRows.length > 0) {
        const promises = selectedRows.map((r, i) =>
          stopMtlrSet(r.id).then((response) => {
            return response;
          }));

        await makeBulkApiRequests(promises);
      }
    }

    setIsBulkComplete(false);
  };

  const makeBulkApiRequests = async (promises: Promise<ApiResponse<MtlrSetResponse | null>>[], isDeleteRequest = false) => {
    let errorMessage = null;

    const responses = await Promise.all(promises);

    for (const response of responses) {
      if (response.hasError) {
        errorMessage = "A server error occurred. Action not applied to some MTLR Sets.";
      } else {
        //Response of 204 will be returned if the bulk action taken does not apply to the
        // mtlrSet in the request. This is not considered an error but we will no have data.
        if (response.data) {
          if (isDeleteRequest) {
            setMtlrSets(prev => prev?.filter(g => g.id !== response.data?.mtlrSetWithDisplayName.id) ?? null);
          }
          else {
            updateGridRowData(response.data.mtlrSetWithDisplayName.id.toString(), response.data.mtlrSetWithDisplayName);
          }
        }
      }
    }

    if (errorMessage) {
      setMessage(errorMessage, true);
    }
  };

  const deleteMtlrSetAndRemoveRowFromGrid = async () => {
    if (gridIdOfRowToBeDeleted) {
      //get the grid row that is to be deleted
      const selectedRow = gridApiRef.current!.api.getRowNode(gridIdOfRowToBeDeleted);

      if (selectedRow) {
        //set row height to make the row become invisible on the grid
        selectedRow.setRowHeight(0);
        gridApiRef.current!.api.onRowHeightChanged();

        //call api to delete the record
        const response = await deleteMtlrSet(selectedRow.data.id);

        if (response?.hasError) {
          //show grid row again
          selectedRow.setRowHeight(null);
          gridApiRef.current!.api.onRowHeightChanged();

          setMessage("Delete failed", true);
        } else {
          //remove the row from the grid
          setMtlrSets(prev => prev?.filter(g => g.id.toString() !== selectedRow.id) ?? null);

          setMessage("Request deleted");
        }

        setMessageTimeout();
      }
    }
  };

  const stopRunningMtlrSet = async (gridId: string | null) => {
    if (gridId) {
      //get the grid row that is to be stopped
      const selectedRow = gridApiRef.current!.api.getRowNode(gridId);

      if (selectedRow) {
        //call api to stop the record
        const response = await stopMtlrSet(selectedRow.data.id);

        if (response?.hasError) {
          setMessage("Stop click failed", true);
        } else {
          if (response.data) {
            updateGridRowData(gridId, response.data.mtlrSetWithDisplayName);
          }

          setMessage("Request stopped");
        }

        setMessageTimeout();
      }
    }
  };

  const updateGridRowData = (id: string, mtlrSet: MtlrSetWithDisplayName) => {
    //update the row in the grid
    const selectedRow = gridApiRef.current!.api.getRowNode(id);

    if (selectedRow && mtlrSet) {
      selectedRow.data.isPaused = mtlrSet.isPaused;
      selectedRow.data.startTimeUtc = mtlrSet.startTimeUtc;
      selectedRow.data.endTimeUtc = mtlrSet.endTimeUtc;
      selectedRow.data.slirCount = mtlrSet.slirCount;
      selectedRow.data.sliaCount = mtlrSet.sliaCount;
      selectedRow.data.positionCount = mtlrSet.positionCount;
      selectedRow.data.haPositionCount = mtlrSet.haPositionCount;

      setMtlrSets(prev =>
        prev?.map(g => g.id.toString() !== selectedRow.id ? g : selectedRow.data)
        ?? null);
    }
  };

  const handleDeleteModalCancel = () => {
    setShowDeleteModal(false);
    setIsBulkDelete(false);
  };

  const handleStopModalCancel = () => {
    setShowStopModal(false);
    setIsBulkComplete(false);
  }
  const handleReportModalCancel = () => setShowReportModal(false);

  const handleAddModalCancel = () => {
    setShowAddModal(false);

    fetchMtlrSets();
  };

  const handleShowOnlyMySets = (showAllSets: boolean) => {
    setShowOnlyMySets(showAllSets);
    //putting this in sessionsStorage will allow the browser to be refreshed and the setting to be remembered
    sessionStorage.setItem("showOnlyMySets", String(showAllSets));
  };

  const handleShowCompletedSets = (showCompletedSets: boolean) => {
    setShowCompletedSets(showCompletedSets);
    //putting this in sessionsStorage will allow the browser to be refreshed and the setting to be remembered
    sessionStorage.setItem("showCompletedSets", String(showCompletedSets));
  };

  useEffect(() => {
    if (autoUpdate) {
      clearInterval(autoUpdateInterval.current);
      autoUpdateInterval.current = setInterval(() => {
        fetchMtlrSets();
        fetchMtlrSet();
      }, 30000) as unknown as number;
    } else {
      clearInterval(autoUpdateInterval.current);
      autoUpdateInterval.current = undefined;
    }
  }, [autoUpdate, fetchMtlrSets, fetchMtlrSet]);

  //will run on unmount
  useEffect(() => {
    return () => {
      if (autoUpdateInterval.current) {
        clearInterval(autoUpdateInterval.current);
      }
    };
  }, []);

  const handleAutoUpdate = (autoUpdate: boolean) => {
    setAutoUpdate(autoUpdate);
    //putting this in sessionsStorage will allow the browser to be refreshed and the setting to be remembered
    sessionStorage.setItem("autoUpdate", String(autoUpdate));
  };

  const filteredMtlrSets = useMemo<MtlrSetWithDisplayName[]>(() =>
    mtlrSets?.filter((m) => {
      if (showOnlyMySets && showCompletedSets) {
        return m.creatingUser === accounts[0].localAccountId;
      } else if (showOnlyMySets && !showCompletedSets) {
        if (m.endTimeUtc === null) { return true; }

        const now = DateTime.now().setZone("utc");
        const endDateUtc = DateTime.fromISO(m.endTimeUtc.toString(), {
          zone: "utc",
        });

        return m.creatingUser === accounts[0].localAccountId && now < endDateUtc;
      } else if (!showOnlyMySets && !showCompletedSets) {
        if (m.endTimeUtc === null) { return true; }

        const now = DateTime.now().setZone("utc");
        const endDateUtc = DateTime.fromISO(m.endTimeUtc.toString(), {
          zone: "utc",
        });

        return now < endDateUtc;
      } else {
        return m;
      }
    }) ?? [],
    [mtlrSets, accounts, showOnlyMySets, showCompletedSets]
  );

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState<ColDef<MtlrSetWithDisplayName>[]>([
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 38,
      editable: false,
      pinned: "left",
      cellRenderer: checkBoxColumnCellRenderer,
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      width: 37,
      editable: false,
      pinned: "left",
      cellRenderer: DeleteButton,
      cellRendererParams: { onClick: handleDeleteClick },
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      headerComponent: ReportHeader,
      width: 37,
      editable: false,
      pinned: "left",
      cellRenderer: reportColumnCellRenderer,
      cellRendererParams: { onClick: handleReportClick },
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      headerComponent: ControlHeader,
      width: 38,
      pinned: "left",
      cellRenderer: controlColumnCellRenderer,
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      width: 115,
      pinned: "left",
      valueFormatter: (params) => {
        return msisdnFormatter(params.value);
      },
    },
    {
      headerName: "Status",
      width: 96,
      pinned: "left",
      valueGetter: params => { return statusGetter(params); },
      cellClass: params => { return statusCellClass(params); },
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      headerComponent: RunTypeHeader,
      width: 38,
      pinned: "left",
      cellRenderer: runTypeColumnCellRenderer,
      cellStyle: {
        borderRight: "1px solid #ccc",
      },
    },
    {
      field: "displayName",
      headerName: "User",
      width: 160,
      valueFormatter: (params) => {
        return userFormatter(params.value);
      },
    },
    {
      field: "startTimeUtc",
      headerName: "Start Time",
      width: 160,
      valueFormatter: dateFormatter,
    },
    {
      field: "endTimeUtc",
      headerName: "End Time",
      width: 160,
      valueFormatter: dateFormatter,
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      width: 96,
      valueFormatter: timeZoneFormatter,
    },
    {
      field: "intervalSeconds",
      headerName: "Interval",
      width: 80,
      valueFormatter: (params) => {
        return intervalFormatter(params.value);
      },
    },
    {
      field: "slirCount",
      headerName: "Requests",
      width: 90,
      valueGetter: (params) => countGetter(params, params.data?.slirCount ?? null)
    },
    {
      field: "sliaCount",
      headerName: "Responses",
      width: 100,
      valueGetter: (params) => countGetter(params, params.data?.sliaCount ?? null)
    },
    {
      field: "positionCount",
      headerName: "Found",
      width: 80,
      valueGetter: (params) => countGetter(params, params.data?.positionCount ?? null)
    },
    {
      field: "haPositionCount",
      headerName: "HA Pos",
      width: 80,
      valueGetter: (params) => countGetter(params, params.data?.haPositionCount ?? null)
    },
    {
      field: "id",
      headerName: "SLIR",
      width: 55,
      sortable: false,
      editable: false,
      cellRenderer: ExpandButton,
      cellRendererParams: { onClick: handleExpandClick },
      pinned: "right",
      cellStyle: () => ({
        display: "flex",
        justifyContent: "center"
      })
    },
  ]);

  return (
    <FlexContainer id="mtlrContainer">
      <ToggleButtonDiv>
        <SwitchLabel>Show Completed Sets</SwitchLabel>
        <Form.Switch
          id="showCompletedSets"
          role="switch"
          className="myform-check-input"
          checked={showCompletedSets}
          onChange={(e) => handleShowCompletedSets(e.target.checked)}
        />
        <SwitchLabel>Show Only My Sets</SwitchLabel>
        <Form.Switch
          id="showOnlyMySets"
          role="switch"
          className="myform-check-input"
          checked={showOnlyMySets}
          onChange={(e) => handleShowOnlyMySets(e.target.checked)}
        />
        <SwitchLabel>Auto-Update</SwitchLabel>
        <Form.Switch
          id="autoUpdate"
          role="switch"
          className="myform-check-input"
          checked={autoUpdate}
          onChange={(e) => handleAutoUpdate(e.target.checked)}
        />
        {messageState.message && (
          <Message $isError={messageState.isError}>
            <animated.span style={fadeStyles}>{messageState.message}</animated.span>
          </Message>
        )}
      </ToggleButtonDiv>

      <BulkActionButtonDiv>
        <TooltipIcon iconStyles={{ marginLeft: 0, marginRight: "3px" }}
          content="Select one or more MTLR Sets to apply actions"
        />

        <SwitchLabel>Bulk Actions:</SwitchLabel>
        <SpinnerButton className="bulkActionButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkPause} onClick={handleBulkPauseClick}>
          <PauseIcon><FontAwesomeIcon icon={icon({ name: "pause" })} /></PauseIcon>
          <ScheduledButtonText>Pause</ScheduledButtonText>
        </SpinnerButton>
        <SpinnerButton className="bulkActionButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkResume} onClick={handleBulkResumeClick}>
          <PlayIcon><FontAwesomeIcon icon={icon({ name: "play" })} /></PlayIcon>
          <ScheduledButtonText>Resume</ScheduledButtonText>
        </SpinnerButton>
        <SpinnerButton className="bulkActionButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkStart} onClick={handleBulkStartClick}>
          <CirlcePlayIcon><FontAwesomeIcon icon={icon({ name: "circle-play" })} /></CirlcePlayIcon>
          <ScheduledButtonText>Start</ScheduledButtonText>
        </SpinnerButton>
        <SpinnerButton className="bulkActionButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkComplete} onClick={handleBulkCompleteClick}>
          <PowerOffIcon><FontAwesomeIcon icon={icon({ name: "power-off" })} /></PowerOffIcon>
          <ScheduledButtonText>Complete</ScheduledButtonText>
        </SpinnerButton>
        <SpinnerButton className="bulkActionButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkReport} onClick={handleBulkReportClick}>
          <ReportActive><FontAwesomeIcon icon={icon({ name: "file-lines" })} /></ReportActive>
          <ScheduledButtonText>Report</ScheduledButtonText>
        </SpinnerButton>
        <SpinnerButton className="bulkActionButton bulkActionDeleteButton" variant="outline-secondary" disabled={!anySelected || bulkActionInProgress} isSpinning={isBulkDelete} onClick={handleBulkDeleteClick}>
          <DeleteIcon><FontAwesomeIcon icon={icon({ name: "trash" })} /></DeleteIcon>
          <ScheduledButtonText>Delete</ScheduledButtonText>
        </SpinnerButton>
      </BulkActionButtonDiv>

      <GridGmlcData>
        <GridStyle>
          <GridWrapper>
            <GmlcDataProvider>
              <AgGridReact
                ref={gridApiRef}
                suppressDragLeaveHidesColumns={true}
                rowData={filteredMtlrSets}
                getRowId={(params) => params.data.id}
                columnDefs={colDefs}
                defaultColDef={{
                  resizable: false,
                  sortable: true,
                }}
                enableCellTextSelection={true}
                rowMultiSelectWithClick={true}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                onSelectionChanged={onGridSelectionChange}
              ></AgGridReact>
            </GmlcDataProvider>
          </GridWrapper>
        </GridStyle>

        {gmlcDataForMtlrSet.id > 0 && (
          <GmlcData
            msisdn={gmlcDataForMtlrSet.msisdn}
            startTime={gmlcDataForMtlrSet.startTime}
            isLoading={gmlcDataLoading}
            lastRequest={gmlcDataForMtlrSet.lastRequest}
            lastResponse={gmlcDataForMtlrSet.lastResponse}
          />
        )}
      </GridGmlcData>

      <div className="d-flex align-items-center">
        <Button className="mtlrSetButton" size="sm" variant="primary" onClick={handleAddScheduledClick}>
          <FontAwesomeIcon icon={icon({ name: "calendar-days" })} />
          <ScheduledButtonText>Add Scheduled</ScheduledButtonText>
        </Button>
        <ScheduledDescription>MTLR Set with a set start time and duration.</ScheduledDescription>
      </div>

      <OnDemandButtonContainer className="d-flex align-items-center">
        <Button className="mtlrSetButton" size="sm" variant="primary" onClick={handleAddOnDemandClick}>
          <FontAwesomeIcon icon={icon({ name: "power-off" })} />
          <OnDemandButtonText>Add On-Demand</OnDemandButtonText>
        </Button>
        <ScheduledDescription>Persistent MTLR Set that can be run at will.</ScheduledDescription>
      </OnDemandButtonContainer>

      <DeleteModal
        show={showDeleteModal}
        isUserCreatingUser={isCreatingUserDelete}
        creatingUserSets={creatingUserSets}
        otherUserSets={otherUserSets}
        onModalDelete={handleDeleteModal}
        onModalClose={handleDeleteModalCancel}
      />
      <AddModal
        show={showAddModal}
        onModalAdd={handleAddModal}
        onModalClose={handleAddModalCancel}
        isScheduled={addIsScheduled}
      />
      <StopModal
        show={showStopModal}
        onModalStop={handleStopModal}
        onModalClose={handleStopModalCancel}
      />
      <ReportModal
        show={showReportModal}
        reportEligible={reportEligibleCount}
        reportIneligible={reportIneligibleCount}
        onModalReport={handleReportModal}
        onModalClose={handleReportModalCancel}
      />
    </FlexContainer>
  );
};

export default MtlrSet;
