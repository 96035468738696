import { ChangeEventHandler, ReactNode } from "react";
import { Form } from "react-bootstrap";

import "./FormSwitch.scss";

type Props = {
  id: string;
  label?: ReactNode;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const FormSwitch = ({ label, id, checked, onChange }: Props) => {
  return (
    <label className="d-flex gap-1">
      {label && <small>{label}</small>}
      <Form.Switch id={id} className="lcat-form-switch" checked={checked} onChange={onChange} />
    </label>
  );
};

export default FormSwitch;
