import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { cancelJob } from "../../../services/apiService";
import SpinnerButton from "../shared/SpinnerButton";
import { GpsFileProgress } from "./Report";

const setProgressBarIcon = (stepDoneValue: number, stepProgress: number, errorMessage: string) => {
  if (stepProgress >= stepDoneValue) {
    return <FontAwesomeIcon className="text-success" icon={icon({ name: "circle-check" })} />;
  }

  if (errorMessage) {
    return <FontAwesomeIcon className="text-danger" icon={icon({ name: "circle-xmark" })} />;
  }

  return <FontAwesomeIcon icon={icon({ name: "spinner" })} spin />;
};

const setBatchSetProgressBarIcon = (isComplete: boolean) => {
  if (isComplete) {
    return <FontAwesomeIcon className="text-success" icon={icon({ name: "circle-check" })} />;
  }

  return <FontAwesomeIcon icon={icon({ name: "spinner" })} spin />;
};

const setFontWeight = (stepDoneValue: number, stepProgress: number) => {
  if (stepProgress >= stepDoneValue) {
    return "bold";
  }

  return "normal";
};

const setBatchSetFontWeight = (isComplete: boolean) => {
  if (isComplete) {
    return "bold";
  }

  return "normal";
};

type Props = {
  show: boolean;
  numberOfFilteredEvents: number;
  numberOfEvents: number;
  numberOfGPSLocations: number;
  numberOfLocations: number;
  numberOfESmlcCalls: number | null;
  retrieveESmlcRecords: boolean;
  exportJobIds: string[];
  doneProgressNumber: number;
  doneProgressTime: number;
  onModalClose: () => void;
  doCancel: () => void;
  errorMessage: string;
  batchSetsProgress: boolean[];
  gpsFileProgress: GpsFileProgress[];
};
const ProgressModal = ({
  show,
  numberOfEvents,
  numberOfFilteredEvents,
  numberOfGPSLocations,
  numberOfLocations,
  numberOfESmlcCalls,
  retrieveESmlcRecords,
  exportJobIds,
  doneProgressNumber,
  doneProgressTime,
  onModalClose,
  doCancel,
  errorMessage,
  batchSetsProgress,
  gpsFileProgress,
}: Props) => {
  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  const handleCancel = async () => {
    setIsCancelling(true);

    const promises = exportJobIds.map((exportJobId) => cancelJob(exportJobId));
    await Promise.all(promises);
    setIsCancelling(false);
    onModalClose();
    doCancel();
  };

  const handleDone = async () => {
    onModalClose();
  };

  const showCloseButton = doneProgressNumber === 6 || errorMessage ? true : false;

  return (
    <Modal id="ProgressModal" show={show} onHide={handleDone} backdrop="static">
      <Modal.Header>
        <Modal.Title>Creating Output</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <span>
          <div className="mb-2" style={{ fontWeight: setFontWeight(1, doneProgressNumber) }}>
            Preparing request… {setProgressBarIcon(1, doneProgressNumber, errorMessage)}
          </div>
          <div className="mb-2" style={{ fontWeight: setFontWeight(2, doneProgressNumber) }}>
            Fetching MLP data… {setProgressBarIcon(2, doneProgressNumber, errorMessage)}
          </div>
          {batchSetsProgress.map((x, index) => (
            <div
              key={`bs_${index}`}
              className="ms-3"
              style={{ fontWeight: setBatchSetFontWeight(batchSetsProgress[index]) }}
            >
              Fetching Batch {`${index + 1}`}...{" "}
              {setBatchSetProgressBarIcon(batchSetsProgress[index])}
            </div>
          ))}
          {gpsFileProgress.length > 0 && (
            <>
              <div
                className="mt-2 mb-2"
                style={{ fontWeight: setFontWeight(2, doneProgressNumber) }}
              >
                Fetching Saved GPS data… {setProgressBarIcon(2, doneProgressNumber, errorMessage)}
              </div>
              {gpsFileProgress.map((x, index) => (
                <div
                  key={`bs_${x.batchSetIndex}`}
                  className="ms-3"
                  style={{ fontWeight: setBatchSetFontWeight(gpsFileProgress[index].isComplete) }}
                >
                  Fetching Batch {`${x.batchSetIndex + 1}`}...{" "}
                  {setBatchSetProgressBarIcon(gpsFileProgress[index].isComplete)}
                </div>
              ))}
            </>
          )}

          <div className="mt-2 mb-2" style={{ fontWeight: setFontWeight(3, doneProgressNumber) }}>
            Correlating MLP and Ground Truth data…{" "}
            {setProgressBarIcon(3, doneProgressNumber, errorMessage)}
          </div>
          <div className="mb-2" style={{ fontWeight: setFontWeight(4, doneProgressNumber) }}>
            Summarizing results… {setProgressBarIcon(4, doneProgressNumber, errorMessage)}
          </div>
          <div className="mb-2" style={{ fontWeight: setFontWeight(5, doneProgressNumber) }}>
            Downloading… {setProgressBarIcon(5, doneProgressNumber, errorMessage)}
          </div>
          <div className="mb-2" style={{ fontWeight: setFontWeight(6, doneProgressNumber) }}>
            Completed. {setProgressBarIcon(6, doneProgressNumber, errorMessage)}
          </div>
        </span>
        {doneProgressNumber === 6 && (
          <span>
            <div className="mt-3">
              <strong>
                Raw Data Generation Completed in{" "}
                {new Date(doneProgressTime * 1000).toISOString().substring(14, 19)}.
              </strong>
            </div>
            <div>Calls: {numberOfEvents}</div>
            <div>Filtered Calls: {numberOfFilteredEvents}</div>
            <div>Locations: {numberOfLocations}</div>
            <div>{`With Ground Truth: ${numberOfGPSLocations}`}</div>
            {retrieveESmlcRecords && <div>eSMLC Matches: {numberOfESmlcCalls}</div>}
          </span>
        )}
        <div>{errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}</div>
      </Modal.Body>
      <Modal.Footer>
        {doneProgressNumber < 6 && !errorMessage && (
          <SpinnerButton isSpinning={isCancelling} size="sm" onClick={handleCancel} variant="danger">
            <FontAwesomeIcon
              icon={icon({ name: "arrow-turn-up" })}
              rotation={270}
              className="me-1"
            />{" "}
            Cancel
          </SpinnerButton>
        )}
        {showCloseButton && (
          <Button variant="primary" size="sm" onClick={handleDone}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProgressModal;
