import { v4 as uuidv4 } from "uuid";
import { getLocalTimeAsUTC } from "../../../utilities/datetime";
import {
  getUserTimeZoneAbbreviationFromIdentifier,
  TimeZoneIdentifier,
  TimeZoneValue,
} from "../../../utilities/timezone";
import { DateTimeOption } from "../shared/helpers";
import { GroundTruthData } from "../Report/models/GroundTruthData";
import { AdvancedReportFilters } from "../Report/models/AdvancedReportFilters";

export class BatchSet {
  msisdns: string[] = [];
  dateTimeOption: string = DateTimeOption.LAST_EIGHT_HOURS;
  customStartDate: Date;
  customEndDate: Date;
  groundTruthData: GroundTruthData = new GroundTruthData();
  timeZone: TimeZoneValue;
  key: string;
  advancedFilters: AdvancedReportFilters = new AdvancedReportFilters();

  constructor(userTimeZoneIdentifier: TimeZoneIdentifier, key?: string) {
    this.timeZone = getUserTimeZoneAbbreviationFromIdentifier(userTimeZoneIdentifier);
    this.key = key ?? uuidv4();
    this.customStartDate = new Date(
      getLocalTimeAsUTC(userTimeZoneIdentifier).toJSDate().setHours(0, 0, 0, 0)
    );
    this.customEndDate = new Date(
      getLocalTimeAsUTC(userTimeZoneIdentifier).toJSDate().setHours(23, 59, 0, 0)
    );
  }
}