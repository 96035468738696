import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Button
      variant="link"
      className="text-decoration-none pe-0"
      onClick={handleLogout}
    >
      Sign out
    </Button>
  );
};
