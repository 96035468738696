import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { StaticPoint } from "../services/models/StaticPoint";

type ReportContextValue = {
  groundTruthStaticMessageState: {
    message: string;
    isError: boolean;
  };
  setGroundTruthStaticMessage: (message: string, isError?: boolean) => void;
  setLoadedStaticPointId: (id: number) => void;
  loadedStaticPointId: number | null;
  setIsSaveAsButtonDisabled: (isDisabled: boolean) => void;
  isSaveAsButtonDisabled: boolean;
};

const defaultValues: ReportContextValue = {
  groundTruthStaticMessageState: {
    message: "",
    isError: false,
  },
  setGroundTruthStaticMessage: () => {},
  setLoadedStaticPointId: () => {},
  loadedStaticPointId: null,
  setIsSaveAsButtonDisabled: () => {},
  isSaveAsButtonDisabled: false,
};

export const ReportContext = createContext<ReportContextValue>(defaultValues);

export const ReportContextProvider = ({ children }: { children: ReactNode }) => {
  const [isSaveAsButtonDisabled, setIsSaveAsButtonDisabled] = useState<boolean>(false);
  const [loadedStaticPointId, setLoadedStaticPointId] = useState<number | null>(null);
  const [groundTruthStaticMessageState, setGroundTruthStaticMessageState] = useState({
    message: "",
    isError: false,
  });

  const setGroundTruthStaticMessage = (message: string, isError: boolean = false) => {
    setGroundTruthStaticMessageState({ message: message, isError: isError });
  };

  return (
    <ReportContext.Provider
      value={{
        groundTruthStaticMessageState,
        setGroundTruthStaticMessage,
        setLoadedStaticPointId,
        loadedStaticPointId,
        isSaveAsButtonDisabled,
        setIsSaveAsButtonDisabled,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error("useReportContext must be used within a <ReportContextProvider />");
  }

  return context;
};
