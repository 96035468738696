import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { styled } from "styled-components";

type Props = {
  icon: IconProp;
  dropdownId: string;
  hoverColor: string;
  actionText: string;
  isHidden?: boolean;
  onActionClick: () => Promise<void> | void;
};

type StyledButtonProps = {
  $hoverColor: string;
  $isHidden: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  visibility: ${(props) => (props.$isHidden ? "hidden" : "visible")};
  color: gray;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover:not(:disabled) {
    text-decoration: underline;
    color: ${(props) => props.$hoverColor};
    filter: brightness(85%);
  }

  &:disabled {
    cursor: default;
    color: rgba(80, 80, 80, 0.5);
  }

  & .dropdown-toggle {
    visibility: initial !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  & > button::after {
    display: none;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  line-height: normal;
`;

const DropdownIconButton = ({ icon, dropdownId, hoverColor, actionText, isHidden = false, onActionClick }: Props) => {
  return (
    <StyledDropdown>
      <Dropdown.Toggle as={StyledButton} id={dropdownId} $isHidden={isHidden} $hoverColor={hoverColor} type="button">
        <FontAwesomeIcon icon={icon} />
      </Dropdown.Toggle>

      <DropdownMenu>
        <Dropdown.Item
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onActionClick();
          }}
        >
          {actionText}
        </Dropdown.Item>
      </DropdownMenu>
    </StyledDropdown>
  );
};

export default DropdownIconButton;
