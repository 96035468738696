import EventSmallestValueList from "./EventSmallestValueList";

export default class EventSmallestValueListPerPositionMethod {
  private _data = new Map<string, EventSmallestValueList>();

  public add = (
    positionMethod: string,
    eventId: number,
    value?: number | null,
    addZeroValue: boolean = true
  ) => {
    if (!this._data.has(positionMethod)) {
      this._data.set(positionMethod, new EventSmallestValueList());
    }

    this._data.get(positionMethod)!.add(eventId, value, addZeroValue);
  };

  public get = (positionMethod: string) => this._data.get(positionMethod) || null;
}
