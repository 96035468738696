import { GpsFileSummary } from "../../../../services/responses/GpsFileSummaryResponse";
import { TimeZoneIdentifier } from "../../../../utilities/timezone";
import { formatDateTimeUtc } from "../../shared/formatters";
import { SavedGpsData } from "./SavedGpsDataGrid";

export const getMapOfGpsFileSummaryByMsisdn = (
  summaries: GpsFileSummary[]
): Map<string, GpsFileSummary> => {
  const mapOfSummaryDataByMsisdn = new Map<string, GpsFileSummary>();

  for (const summary of summaries) {
    const updated = mapOfSummaryDataByMsisdn.get(summary.msisdn);

    if (!updated) {
      mapOfSummaryDataByMsisdn.set(summary.msisdn, { ...summary });
      continue;
    }

    updated!.startTimeUtc =
      new Date(updated!.startTimeUtc) <= new Date(summary.startTimeUtc)
        ? updated!.startTimeUtc
        : summary.startTimeUtc;

    updated!.endTimeUtc =
      new Date(updated!.endTimeUtc) >= new Date(summary.endTimeUtc)
        ? updated!.endTimeUtc
        : summary.endTimeUtc;

    updated!.recordCount += summary.recordCount;

    updated!.lastModifiedTimeUtc =
      new Date(updated!.lastModifiedTimeUtc) >= new Date(summary.lastModifiedTimeUtc)
        ? updated!.lastModifiedTimeUtc
        : summary.lastModifiedTimeUtc;

    mapOfSummaryDataByMsisdn.set(summary.msisdn, updated!);
  }

  return mapOfSummaryDataByMsisdn;
};

export const getCleanSummaryDataForGrid = (
  mapOfSummaryDataByMsisdn: Map<string, GpsFileSummary>,
  timeZoneIdentifier: TimeZoneIdentifier
): SavedGpsData[] => {
  const cleanedSummaryData: SavedGpsData[] = [];

  for (const [msisdn, summary] of mapOfSummaryDataByMsisdn.entries()) {
    const startTime = formatDateTimeUtc(summary.startTimeUtc, timeZoneIdentifier, false);
    const endTime = formatDateTimeUtc(summary.endTimeUtc, timeZoneIdentifier, false);
    const timeOfLoad = formatDateTimeUtc(summary.lastModifiedTimeUtc, timeZoneIdentifier);

    const savedGpsData: SavedGpsData = {
      msisdn: msisdn,
      timeRange: `${startTime} - ${endTime}`,
      gpsRecordCount: summary.recordCount,
      timeOfLoad: timeOfLoad,
    };

    cleanedSummaryData.push(savedGpsData);
  }

  return cleanedSummaryData;
};
