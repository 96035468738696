import { useField } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";

type StyledFormControlProps = {
  fieldwidth?: number;
};
const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
  ${(props) =>
    props.fieldwidth && props.fieldwidth > 0
    ? `width:${props.fieldwidth}px;`
      : ""};
  display: inline-block;
  &[disabled] {
    color: hsl(0, 0%, 60%);
    background: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
`;

type Props = {
  id: string;
  name: string;
  isDisabled?: boolean;
  width?: number;
  maxlength?: number;
  regex: RegExp;
};

const NumberInput = ({ id, name, width, maxlength, regex, isDisabled = false }: Props) => {
  const [field, meta] = useField(name);

  return (
    <StyledFormControl
      fieldwidth={width}
      {...field}
      isInvalid={meta.touched && !!meta.error}
      onChange={(e) => {
        if (!e.target.value || regex.test(e.target.value as string)) {
          field.onChange(e);
        }
      }}
      disabled={isDisabled}
      maxLength={maxlength}
      id={id}
    />
  );
};

export default NumberInput;
