import { isCorrelatedControlPlane, isCorrelatedThunderbird } from "../../reportHelpers";
import CorrelationGroup from "./CorrelationGroup";
import EventIdList from "./EventIdList";
import EventIdListPerPositionMethod from "./EventIdListPerPositionMethod";
import EventSmallestValueList from "./EventSmallestValueList";
import EventSmallestValueListPerPositionMethod from "./EventSmallestValueListPerPositionMethod";
import MlpStatusOptions from "../MlpStatusOptions";

export default class EventCache {
  public reportableEvent = new EventIdList();
  public reportableiOSEvent = new EventIdList();
  public reportableAndroidEvent = new EventIdList();
  public controlPlaneiOSRecords = new EventIdList();
  public controlPlaneAndroidRecords = new EventIdList();
  public thunderbirdRecords = new EventIdList();
  public sipiOSRecords = new EventIdList();
  public sipAndroidRecords = new EventIdList();
  public iOS_total_Success = new EventIdList();
  public android_total_Success = new EventIdList();

  //control plane iOS fields
  public cp_total_Success_iOS = new EventIdList();
  public cp_positionMethod_iOS = new EventIdListPerPositionMethod();

  public cp_within_horizontalAccuracyThreshold_iOS = new EventIdList();
  public cp_positionMethod_within_horizontalAccuracyThreshold_iOS = new EventIdListPerPositionMethod();

  public cp_total_horizontal_accuracy_iOS = new EventSmallestValueList();
  public cp_positionMethod_horizontal_accuracy_iOS = new EventSmallestValueListPerPositionMethod();

  public cp_total_vertical_accuracy_iOS = new EventSmallestValueList();
  public cp_positionMethod_vertical_accuracy_iOS = new EventSmallestValueListPerPositionMethod();

  public cp_total_latency_iOS = new EventSmallestValueList();
  public cp_positionMethod_horizontal_latency_iOS = new EventSmallestValueListPerPositionMethod();

  public cp_positionMethod_horizontal_accuracy_uncertainty_iOS =
    new EventSmallestValueListPerPositionMethod();
  public cp_total_horizontal_accuracy_uncertainty_iOS = new EventSmallestValueList();

  public cp_total_uncertainty_iOS = new EventSmallestValueList();

  public cp_positionMethod_horizontal_uncertainty_values_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_horizontal_uncertainty_values_iOS = new EventSmallestValueList();

  public cp_positionMethod_with_altitude_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_altitude_iOS = new EventSmallestValueList();

  public cp_positionMethod_within_verticalAccuracyThreshold_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_within_Threshold_iOS = new EventSmallestValueList();

  public cp_positionMethod_vertical_accuracy_values_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_values_iOS = new EventSmallestValueList();

  public cp_positionMethod_with_vertical_uncertainty_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_within_uncertainty_iOS = new EventSmallestValueList();

  public cp_positionMethod_vertical_uncertainty_count_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_uncertainty_iOS = new EventSmallestValueList();

  public cp_positionMethod_vertical_uncertainty_values_iOS =
    new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_uncertainty_values_iOS = new EventSmallestValueList();

  public cp_positionMethod_ubp_count_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_ubp_iOS = new EventSmallestValueList();
  public cp_positionMethod_ubp_values_iOS = new EventSmallestValueListPerPositionMethod();
  public cp_total_ubp_values_iOS = new EventSmallestValueList();

  //control plane android fields
  public cp_total_Success_android = new EventIdList();
  public cp_positionMethod_android = new EventIdListPerPositionMethod();

  public cp_within_horizontalAccuracyThreshold_android = new EventIdList();
  public cp_positionMethod_within_horizontalAccuracyThreshold_android = new EventIdListPerPositionMethod();

  public cp_total_horizontal_accuracy_android = new EventSmallestValueList();
  public cp_positionMethod_horizontal_accuracy_android = new EventSmallestValueListPerPositionMethod();

  public cp_total_vertical_accuracy_android = new EventSmallestValueList();
  public cp_positionMethod_vertical_accuracy_android = new EventSmallestValueListPerPositionMethod();

  public cp_total_latency_android = new EventSmallestValueList();
  public cp_positionMethod_horizontal_latency_android = new EventSmallestValueListPerPositionMethod();

  public cp_positionMethod_horizontal_accuracy_uncertainty_android =
    new EventSmallestValueListPerPositionMethod();
  public cp_total_horizontal_accuracy_uncertainty_android = new EventSmallestValueList();

  public cp_total_uncertainty_android = new EventSmallestValueList();

  public cp_positionMethod_horizontal_uncertainty_values_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_horizontal_uncertainty_values_android = new EventSmallestValueList();

  public cp_positionMethod_with_altitude_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_altitude_android = new EventSmallestValueList();

  public cp_positionMethod_within_verticalAccuracyThreshold_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_within_Threshold_android = new EventSmallestValueList();

  public cp_positionMethod_vertical_accuracy_values_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_values_android = new EventSmallestValueList();

  public cp_positionMethod_with_vertical_uncertainty_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_accuracy_within_uncertainty_android = new EventSmallestValueList();

  public cp_positionMethod_vertical_uncertainty_count_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_uncertainty_android = new EventSmallestValueList();

  public cp_positionMethod_vertical_uncertainty_values_android =
    new EventSmallestValueListPerPositionMethod();
  public cp_total_vertical_uncertainty_values_android = new EventSmallestValueList();

  public cp_positionMethod_ubp_count_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_ubp_android = new EventSmallestValueList();
  public cp_positionMethod_ubp_values_android = new EventSmallestValueListPerPositionMethod();
  public cp_total_ubp_values_android = new EventSmallestValueList();

  //thunderbird fields
  public tb_total_Success = new EventIdList();
  public tb_positionMethod = new EventIdListPerPositionMethod();

  public tb_within_horizontalAccuracyThreshold = new EventIdList();
  public tb_positionMethod_within_horizontalAccuracyThreshold = new EventIdListPerPositionMethod();

  public tb_total_horizontal_accuracy = new EventSmallestValueList();
  public tb_positionMethod_horizontal_accuracy = new EventSmallestValueListPerPositionMethod();

  public tb_total_vertical_accuracy = new EventSmallestValueList();
  public tb_positionMethod_vertical_accuracy = new EventSmallestValueListPerPositionMethod();

  public tb_total_latency = new EventSmallestValueList();
  public tb_positionMethod_horizontal_latency = new EventSmallestValueListPerPositionMethod();

  public tb_positionMethod_horizontal_accuracy_uncertainty =
    new EventSmallestValueListPerPositionMethod();
  public tb_total_horizontal_accuracy_uncertainty = new EventSmallestValueList();

  public tb_total_uncertainty = new EventSmallestValueList();

  public tb_positionMethod_horizontal_uncertainty_values = new EventSmallestValueListPerPositionMethod();
  public tb_total_horizontal_uncertainty_values = new EventSmallestValueList();

  public tb_positionMethod_with_altitude = new EventSmallestValueListPerPositionMethod();
  public tb_total_altitude = new EventSmallestValueList();

  public tb_positionMethod_within_verticalAccuracyThreshold = new EventSmallestValueListPerPositionMethod();
  public tb_total_vertical_accuracy_within_Threshold = new EventSmallestValueList();

  public tb_positionMethod_vertical_accuracy_values = new EventSmallestValueListPerPositionMethod();
  public tb_total_vertical_accuracy_values = new EventSmallestValueList();

  public tb_positionMethod_with_vertical_uncertainty = new EventSmallestValueListPerPositionMethod();
  public tb_total_vertical_accuracy_within_uncertainty = new EventSmallestValueList();

  public tb_positionMethod_vertical_uncertainty_count = new EventSmallestValueListPerPositionMethod();
  public tb_total_vertical_uncertainty = new EventSmallestValueList();

  public tb_positionMethod_vertical_uncertainty_values =
    new EventSmallestValueListPerPositionMethod();
  public tb_total_vertical_uncertainty_values = new EventSmallestValueList();

  //sip iOS fields
  public sip_total_Success_iOS = new EventIdList();
  public sip_within_horizontalAccuracyThreshold_iOS = new EventIdList();
  public sip_total_horizontal_accuracy_iOS = new EventSmallestValueList();
  public sip_total_vertical_accuracy_iOS = new EventSmallestValueList();
  public sip_total_horizontal_latency_iOS = new EventSmallestValueList();
  public sip_total_horizontal_accuracy_uncertainty_iOS = new EventSmallestValueList();
  public sip_total_uncertainty_iOS = new EventSmallestValueList();
  public sip_total_horizontal_uncertainty_values_iOS = new EventSmallestValueList();
  public sip_total_vertical_altitude_iOS = new EventSmallestValueList();
  public sip_total_vertical_accuracy_within_Threshold_iOS = new EventSmallestValueList();
  public sip_total_vertical_accuracy_values_iOS = new EventSmallestValueList();
  public sip_total_vertical_accuracy_within_uncertainty_iOS = new EventSmallestValueList();
  public sip_total_vertical_uncertainty_iOS = new EventSmallestValueList();
  public sip_total_vertical_uncertainty_values_iOS = new EventSmallestValueList();

  //sip android fields
  public sip_total_Success_android = new EventIdList();
  public sip_within_horizontalAccuracyThreshold_android = new EventIdList();
  public sip_total_horizontal_accuracy_android = new EventSmallestValueList();
  public sip_total_vertical_accuracy_android = new EventSmallestValueList();
  public sip_total_horizontal_latency_android = new EventSmallestValueList();
  public sip_total_horizontal_accuracy_uncertainty_android = new EventSmallestValueList();
  public sip_total_uncertainty_android = new EventSmallestValueList();
  public sip_total_horizontal_uncertainty_values_android = new EventSmallestValueList();
  public sip_total_vertical_altitude_android = new EventSmallestValueList();
  public sip_total_vertical_accuracy_within_Threshold_android = new EventSmallestValueList();
  public sip_total_vertical_accuracy_values_android = new EventSmallestValueList();
  public sip_total_vertical_accuracy_within_uncertainty_android = new EventSmallestValueList();
  public sip_total_vertical_uncertainty_android = new EventSmallestValueList();
  public sip_total_vertical_uncertainty_values_android = new EventSmallestValueList();

  //selectedVertical iOS fields
  public selectedVertical_total_vertical_accuracy_iOS = new EventSmallestValueList();
  public selectedVertical_total_uncertainty_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_altitude_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_Threshold_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_values_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_uncertainty_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_iOS = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_values_iOS = new EventSmallestValueList();

  //selectedVertical android fields
  public selectedVertical_total_vertical_accuracy_android = new EventSmallestValueList();
  public selectedVertical_total_uncertainty_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_altitude_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_Threshold_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_values_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_uncertainty_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_android = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_values_android = new EventSmallestValueList();

  //selectedVertical all fields
  public selectedVertical_total_vertical_accuracy_all = new EventSmallestValueList();
  public selectedVertical_total_uncertainty_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_altitude_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_Threshold_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_values_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_accuracy_within_uncertainty_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_all = new EventSmallestValueList();
  public selectedVertical_total_vertical_uncertainty_values_all = new EventSmallestValueList();

  public cacheCorrelationGroup = (
    correlationGroup: CorrelationGroup,
    yieldExclude: boolean,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number
  ): void => {
    if (correlationGroup.mlpLocation.correlatedEvent == null ||
      !correlationGroup.isCorrelated ||
      yieldExclude
    ) {
      return;
    }

    const status = correlationGroup.status;
    const eventId = correlationGroup.mlpLocation.correlatedEvent.callId;

    this.evaluateSelectedVertical(correlationGroup, eventId, status, verticalAccuracyThreshold);

    //can't filter out locations that are usedInReport = false in initial check because all
    //records need to go through the SelectedVertical evaluation.
    if (!correlationGroup.mlpLocation.usedInReport) { return; }

    this.reportableEvent.add(eventId);

    if (correlationGroup.mlpLocation.correlatedEvent.isiOS) {
      this.reportableiOSEvent.add(eventId);
    } else {
      this.reportableAndroidEvent.add(eventId);
    }

    if (isCorrelatedControlPlane(correlationGroup.mlpLocation)) {
      if (correlationGroup.mlpLocation.correlatedEvent.isiOS) {
        this.populateControlPlaneiOSFields(
          correlationGroup,
          eventId,
          status,
          horizontalAccuracyThreshold,
          verticalAccuracyThreshold,
          correlationGroup.mlpLocation.ubp
        );
      } else {
        this.populateControlPlaneAndroidFields(
          correlationGroup,
          eventId,
          status,
          horizontalAccuracyThreshold,
          verticalAccuracyThreshold,
          correlationGroup.mlpLocation.ubp
        );
      }
    } else if (isCorrelatedThunderbird(correlationGroup.mlpLocation)) {
      this.populateThunderbirdFields(
        correlationGroup,
        eventId,
        status,
        horizontalAccuracyThreshold,
        verticalAccuracyThreshold
      );
    } else {
      if (correlationGroup.mlpLocation.correlatedEvent.isiOS) {
        this.populateSIPiOSFields(
          correlationGroup,
          eventId,
          status,
          horizontalAccuracyThreshold,
          verticalAccuracyThreshold,
        );
      } else {
        this.populateSIPAndroidFields(
          correlationGroup,
          eventId,
          status,
          horizontalAccuracyThreshold,
          verticalAccuracyThreshold,
        );
      }
    }
  };

  private evaluateSelectedVertical = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    verticalAccuracyThreshold: number
  ) => {
    if (MlpStatusOptions.isSuccess(status) && c.mlpLocation.selectedVertical) {
      if (c.mlpLocation.correlatedEvent.isiOS) {
        this.populateSelectedVertical_iOS(c, eventId, verticalAccuracyThreshold);
      } else {
        this.populateSelectedVertical_android(c, eventId, verticalAccuracyThreshold);
      }
    }
  };

  private populateControlPlaneiOSFields = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number,
    ubp?: number,
  ) => {
    var positionMethod = c.positionMethod;

    this.controlPlaneiOSRecords.add(eventId);

    if (MlpStatusOptions.isSuccess(status)) {
      this.iOS_total_Success.add(eventId);
      this.cp_total_Success_iOS.add(eventId);
      this.cp_total_horizontal_accuracy_iOS.add(eventId, c.accuracy);
      this.cp_total_latency_iOS.add(eventId, c.mlpLocation!.latency);
      this.cp_total_vertical_accuracy_iOS.add(eventId, c.altitudeAccuracy);

      if (c.accuracy &&
        c.mlpLocation?.horizontalUncertainty &&
        c.accuracy <= c.mlpLocation.horizontalUncertainty
      ) {
        this.cp_total_horizontal_accuracy_uncertainty_iOS.add(
          eventId,
          c.mlpLocation.horizontalUncertainty,
          false
        );
      }

      this.cp_total_uncertainty_iOS.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      // The maximum uncertainty value
      this.cp_total_horizontal_uncertainty_values_iOS.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.cp_total_vertical_altitude_iOS.add(eventId, c.mlpLocation?.altitude);

      if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
        // Records where vertical accuracy <= 3m.
        this.cp_total_vertical_accuracy_within_Threshold_iOS.add(eventId, c.altitudeAccuracy);
      }

      this.cp_total_vertical_accuracy_values_iOS.add(eventId, c.altitudeAccuracy);

      // Records where vertical accuracy <= altitude uncertainty.
      if (
        c.altitudeAccuracy !== null &&
        c.mlpLocation?.verticalUncertainty != null &&
        c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
      ) {
        this.cp_total_vertical_accuracy_within_uncertainty_iOS.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
      }

      this.cp_total_vertical_uncertainty_iOS.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );

      // non-null altitude, non-null or non-0 altitude uncertainty
      if (c.mlpLocation?.altitude != null) {

        // excluding NULL but including 0
        this.cp_total_vertical_uncertainty_values_iOS.add(eventId, c.mlpLocation.verticalUncertainty);
      }

      this.cp_total_ubp_iOS.add(eventId, ubp, false);

      this.cp_total_ubp_values_iOS.add(eventId, ubp);

      if (positionMethod != null) {
        this.cp_positionMethod_iOS.add(positionMethod, eventId);
        this.cp_positionMethod_horizontal_accuracy_iOS.add(positionMethod, eventId, c.accuracy);
        this.cp_positionMethod_horizontal_latency_iOS.add(positionMethod, eventId, c.mlpLocation?.latency);

        this.cp_positionMethod_vertical_accuracy_iOS.add(positionMethod, eventId, c.altitudeAccuracy);

        if (
          c.accuracy !== null &&
          c.mlpLocation?.horizontalUncertainty !== undefined &&
          c.accuracy <= c.mlpLocation.horizontalUncertainty
        ) {
          // non-zero and non-null uncertainty where accuracy <= uncertainty value.
          this.cp_positionMethod_horizontal_accuracy_uncertainty_iOS.add(
            positionMethod,
            eventId,
            c.mlpLocation.horizontalUncertainty,
            false
          );
        }

        this.cp_positionMethod_horizontal_uncertainty_values_iOS.add(
          positionMethod,
          eventId,
          c.mlpLocation?.horizontalUncertainty,
          false
        );

        // Records with non-null altitude for at least one location.
        this.cp_positionMethod_with_altitude_iOS.add(
          positionMethod,
          eventId,
          c.mlpLocation?.altitude
        );

        // Altitude accuracy = vertical accuracy.
        if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
          // records where the vertical accuracy value for the tech <= vertical accuracy threshold for at least one location.
          this.cp_positionMethod_within_verticalAccuracyThreshold_iOS.add(positionMethod, eventId, c.altitudeAccuracy);
        }

        this.cp_positionMethod_vertical_accuracy_values_iOS.add(
          positionMethod,
          eventId,
          c.altitudeAccuracy
        );

        if (
          c.altitudeAccuracy !== null &&
          c.mlpLocation?.verticalUncertainty != null &&
          c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
        ) {
          this.cp_positionMethod_with_vertical_uncertainty_iOS.add(
            positionMethod,
            eventId,
            c.mlpLocation.verticalUncertainty,
            false
          );
        }

        // The maximum altitude uncertainty value - excluding NULL but including 0.
        this.cp_positionMethod_vertical_uncertainty_values_iOS.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty
        );

        // non-null or non-0 altitude uncertainty for at least one location.
        this.cp_positionMethod_vertical_uncertainty_count_iOS.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );

        this.cp_positionMethod_ubp_count_iOS.add(
          positionMethod,
          eventId,
          ubp,
          false
        );
        this.cp_positionMethod_ubp_values_iOS.add(positionMethod, eventId, ubp);
      }

      if (c.accuracy !== null && c.accuracy <= horizontalAccuracyThreshold) {
        this.cp_within_horizontalAccuracyThreshold_iOS.add(eventId);

        if (positionMethod != null) {
          this.cp_positionMethod_within_horizontalAccuracyThreshold_iOS.add(positionMethod, eventId);
        }
      }
    }
  };

  private populateControlPlaneAndroidFields = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number,
    ubp?: number,
  ) => {
    var positionMethod = c.positionMethod;

    this.controlPlaneAndroidRecords.add(eventId);

    if (MlpStatusOptions.isSuccess(status)) {
      this.android_total_Success.add(eventId);
      this.cp_total_Success_android.add(eventId);
      this.cp_total_horizontal_accuracy_android.add(eventId, c.accuracy);
      this.cp_total_latency_android.add(eventId, c.mlpLocation!.latency);
      this.cp_total_vertical_accuracy_android.add(eventId, c.altitudeAccuracy);

      if (c.accuracy &&
        c.mlpLocation?.horizontalUncertainty &&
        c.accuracy <= c.mlpLocation.horizontalUncertainty
      ) {
        this.cp_total_horizontal_accuracy_uncertainty_android.add(
          eventId,
          c.mlpLocation.horizontalUncertainty,
          false
        );
      }

      this.cp_total_uncertainty_android.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      // The maximum uncertainty value
      this.cp_total_horizontal_uncertainty_values_android.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.cp_total_vertical_altitude_android.add(eventId, c.mlpLocation?.altitude);

      if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
        // Records where vertical accuracy <= 3m.
        this.cp_total_vertical_accuracy_within_Threshold_android.add(eventId, c.altitudeAccuracy);
      }

      this.cp_total_vertical_accuracy_values_android.add(eventId, c.altitudeAccuracy);

      // Records where vertical accuracy <= altitude uncertainty.
      if (
        c.altitudeAccuracy !== null &&
        c.mlpLocation?.verticalUncertainty != null &&
        c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
      ) {
        this.cp_total_vertical_accuracy_within_uncertainty_android.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
      }

      this.cp_total_vertical_uncertainty_android.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );

      // non-null altitude, non-null or non-0 altitude uncertainty
      if (c.mlpLocation?.altitude != null) {
        // The maximum altitude uncertainty value in Htech - excluding NULL but including 0
        this.cp_total_vertical_uncertainty_values_android.add(eventId, c.mlpLocation.verticalUncertainty);
      }

      this.cp_total_ubp_android.add(eventId, ubp, false);

      this.cp_total_ubp_values_android.add(eventId, ubp);

      if (positionMethod != null) {
        this.cp_positionMethod_android.add(positionMethod, eventId);
        this.cp_positionMethod_horizontal_accuracy_android.add(positionMethod, eventId, c.accuracy);
        this.cp_positionMethod_horizontal_latency_android.add(positionMethod, eventId, c.mlpLocation?.latency);

        this.cp_positionMethod_vertical_accuracy_android.add(positionMethod, eventId, c.altitudeAccuracy);

        if (
          c.accuracy !== null &&
          c.mlpLocation?.horizontalUncertainty !== undefined &&
          c.accuracy <= c.mlpLocation.horizontalUncertainty
        ) {
          // non-zero and non-null uncertainty where accuracy <= uncertainty value.
          this.cp_positionMethod_horizontal_accuracy_uncertainty_android.add(
            positionMethod,
            eventId,
            c.mlpLocation.horizontalUncertainty,
            false
          );
        }

        this.cp_positionMethod_horizontal_uncertainty_values_android.add(
          positionMethod,
          eventId,
          c.mlpLocation?.horizontalUncertainty,
          false
        );

        // Records with non-null altitude for at least one location.
        this.cp_positionMethod_with_altitude_android.add(
          positionMethod,
          eventId,
          c.mlpLocation?.altitude
        );

        // Altitude accuracy = vertical accuracy.
        if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
          // records where the vertical accuracy value for the tech <= vertical accuracy threshold for at least one location.
          this.cp_positionMethod_within_verticalAccuracyThreshold_android.add(positionMethod, eventId, c.altitudeAccuracy);
        }

        this.cp_positionMethod_vertical_accuracy_values_android.add(
          positionMethod,
          eventId,
          c.altitudeAccuracy
        );

        if (
          c.altitudeAccuracy !== null &&
          c.mlpLocation?.verticalUncertainty != null &&
          c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
        ) {
          this.cp_positionMethod_with_vertical_uncertainty_android.add(
            positionMethod,
            eventId,
            c.mlpLocation.verticalUncertainty,
            false
          );
        }

        // The maximum altitude uncertainty value - excluding NULL but including 0.
        this.cp_positionMethod_vertical_uncertainty_values_android.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty
        );

        // non-null or non-0 altitude uncertainty for at least one location.
        this.cp_positionMethod_vertical_uncertainty_count_android.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );

        this.cp_positionMethod_ubp_count_android.add(
          positionMethod,
          eventId,
          ubp,
          false
        );
        this.cp_positionMethod_ubp_values_android.add(positionMethod, eventId, ubp);
      }

      if (c.accuracy !== null && c.accuracy <= horizontalAccuracyThreshold) {
        this.cp_within_horizontalAccuracyThreshold_android.add(eventId);

        if (positionMethod != null) {
          this.cp_positionMethod_within_horizontalAccuracyThreshold_android.add(positionMethod, eventId);
        }
      }
    }
  };

  private populateThunderbirdFields = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number
  ) => {
    const positionMethod = c.positionMethod;

    this.thunderbirdRecords.add(eventId);

    if (MlpStatusOptions.isSuccess(status)) {
      this.android_total_Success.add(eventId);
      this.tb_total_Success.add(eventId);
      this.tb_total_horizontal_accuracy.add(eventId, c.accuracy);
      this.tb_total_latency.add(eventId, c.mlpLocation?.latency);
      this.tb_total_vertical_accuracy.add(eventId, c.altitudeAccuracy);

      if (
        c.accuracy !== null &&
        c.mlpLocation?.horizontalUncertainty !== undefined &&
        c.accuracy <= c.mlpLocation.horizontalUncertainty
      ) {
        this.tb_total_horizontal_accuracy_uncertainty.add(
          eventId,
          c.mlpLocation.horizontalUncertainty,
          false
        );
      }

      this.tb_total_uncertainty.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.tb_total_horizontal_uncertainty_values.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.tb_total_altitude.add(eventId, c.mlpLocation?.altitude);

      if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
        // Records where the vertical accuracy value for each tech <= vertical accuracy threshold for at least one TB location.
        this.tb_total_vertical_accuracy_within_Threshold.add(eventId, c.altitudeAccuracy);
      }

      this.tb_total_vertical_accuracy_values.add(eventId, c.altitudeAccuracy);

      if (
        c.altitudeAccuracy !== null &&
        c.mlpLocation?.verticalUncertainty != null &&
        c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
      ) {
        this.tb_total_vertical_accuracy_within_uncertainty.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
      }

      if (c.mlpLocation?.altitude != null) {
        this.tb_total_vertical_uncertainty.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
        this.tb_total_vertical_uncertainty_values.add(eventId, c.mlpLocation.verticalUncertainty);
      }

      if (positionMethod != null) {
        this.tb_positionMethod.add(positionMethod, eventId);
        this.tb_positionMethod_horizontal_accuracy.add(positionMethod, eventId, c.accuracy);
        this.tb_positionMethod_horizontal_latency.add(positionMethod, eventId, c.mlpLocation?.latency);

        this.tb_positionMethod_vertical_accuracy.add(positionMethod, eventId, c.altitudeAccuracy);

        if (
          c.accuracy !== null &&
          c.mlpLocation?.horizontalUncertainty !== undefined &&
          c.accuracy <= c.mlpLocation.horizontalUncertainty
        ) {
          this.tb_positionMethod_horizontal_accuracy_uncertainty.add(
            positionMethod,
            eventId,
            c.mlpLocation.horizontalUncertainty,
            false
          );
        }

        this.tb_positionMethod_horizontal_uncertainty_values.add(
          positionMethod,
          eventId,
          c.mlpLocation?.horizontalUncertainty,
          false
        );

        this.tb_positionMethod_with_altitude.add(
          positionMethod,
          eventId,
          c.mlpLocation?.altitude
        );

        if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
          this.tb_positionMethod_within_verticalAccuracyThreshold.add(positionMethod, eventId, c.altitudeAccuracy);
        }

        this.tb_positionMethod_vertical_accuracy_values.add(
          positionMethod,
          eventId,
          c.altitudeAccuracy
        );

        if (
          c.altitudeAccuracy !== null &&
          c.mlpLocation?.verticalUncertainty != null &&
          c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
        ) {
          // records with non-zero and non-null altitude uncertainty where vertical accuracy <= altitude uncertainty value.
          this.tb_positionMethod_with_vertical_uncertainty.add(
            positionMethod,
            eventId,
            c.mlpLocation.verticalUncertainty,
            false
          );
        }

        this.tb_positionMethod_vertical_uncertainty_count.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );

        // Note: For each event, there may be multiple TB locations for each
        //  position method. Take the minimum altitude uncertainty value of
        //  the TB locations for each position method. This value is then used
        //  for the Max/Min/Avg across all events.
        this.tb_positionMethod_vertical_uncertainty_values.add(
          positionMethod,
          eventId,
          c.mlpLocation.verticalUncertainty
        );
      }

      if (c.accuracy !== null && c.accuracy <= horizontalAccuracyThreshold) {
        this.tb_within_horizontalAccuracyThreshold.add(eventId);

        if (positionMethod != null) {
          this.tb_positionMethod_within_horizontalAccuracyThreshold.add(positionMethod, eventId);
        }
      }
    }
  };

  private populateSIPiOSFields = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number,
  ) => {
    this.sipiOSRecords.add(eventId);

    if (MlpStatusOptions.isSuccess(status)) {
      this.iOS_total_Success.add(eventId);
      this.sip_total_Success_iOS.add(eventId);
      this.sip_total_horizontal_accuracy_iOS.add(eventId, c.accuracy);
      this.sip_total_horizontal_latency_iOS.add(eventId, c.mlpLocation!.latency);
      this.sip_total_vertical_accuracy_iOS.add(eventId, c.altitudeAccuracy);

      if (c.accuracy &&
        c.mlpLocation?.horizontalUncertainty &&
        c.accuracy <= c.mlpLocation.horizontalUncertainty
      ) {
        this.sip_total_horizontal_accuracy_uncertainty_iOS.add(
          eventId,
          c.mlpLocation.horizontalUncertainty,
          false
        );
      }

      this.sip_total_uncertainty_iOS.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      // The maximum uncertainty value
      this.sip_total_horizontal_uncertainty_values_iOS.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.sip_total_vertical_altitude_iOS.add(eventId, c.mlpLocation?.altitude);

      if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
        // Records where vertical accuracy <= 3m.
        this.sip_total_vertical_accuracy_within_Threshold_iOS.add(eventId, c.altitudeAccuracy);
      }

      this.sip_total_vertical_accuracy_values_iOS.add(eventId, c.altitudeAccuracy);

      // Records where vertical accuracy <= altitude uncertainty.
      if (
        c.altitudeAccuracy !== null &&
        c.mlpLocation?.verticalUncertainty != null &&
        c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
      ) {
        this.sip_total_vertical_accuracy_within_uncertainty_iOS.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
      }

      this.sip_total_vertical_uncertainty_iOS.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );

      // non-null altitude, non-null or non-0 altitude uncertainty
      if (c.mlpLocation?.altitude != null) {
        // The maximum altitude uncertainty value in Htech - excluding NULL but including 0
        this.sip_total_vertical_uncertainty_values_iOS.add(eventId, c.mlpLocation.verticalUncertainty);
      }

      if (c.accuracy !== null && c.accuracy <= horizontalAccuracyThreshold) {
        this.sip_within_horizontalAccuracyThreshold_iOS.add(eventId);
      }
    }
  };

  private populateSIPAndroidFields = (
    c: CorrelationGroup,
    eventId: number,
    status: number,
    horizontalAccuracyThreshold: number,
    verticalAccuracyThreshold: number,
  ) => {
    this.sipAndroidRecords.add(eventId);

    if (MlpStatusOptions.isSuccess(status)) {
      this.android_total_Success.add(eventId);
      this.sip_total_Success_android.add(eventId);
      this.sip_total_horizontal_accuracy_android.add(eventId, c.accuracy);
      this.sip_total_horizontal_latency_android.add(eventId, c.mlpLocation!.latency);
      this.sip_total_vertical_accuracy_android.add(eventId, c.altitudeAccuracy);

      if (c.accuracy &&
        c.mlpLocation?.horizontalUncertainty &&
        c.accuracy <= c.mlpLocation.horizontalUncertainty
      ) {
        this.sip_total_horizontal_accuracy_uncertainty_android.add(
          eventId,
          c.mlpLocation.horizontalUncertainty,
          false
        );
      }

      this.sip_total_uncertainty_android.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      // The maximum uncertainty value
      this.sip_total_horizontal_uncertainty_values_android.add(eventId, c.mlpLocation?.horizontalUncertainty, false);

      this.sip_total_vertical_altitude_android.add(eventId, c.mlpLocation?.altitude);

      if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
        // Records where vertical accuracy <= 3m.
        this.sip_total_vertical_accuracy_within_Threshold_android.add(eventId, c.altitudeAccuracy);
      }

      this.sip_total_vertical_accuracy_values_android.add(eventId, c.altitudeAccuracy);

      // Records where vertical accuracy <= altitude uncertainty.
      if (
        c.altitudeAccuracy !== null &&
        c.mlpLocation?.verticalUncertainty != null &&
        c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
      ) {
        this.sip_total_vertical_accuracy_within_uncertainty_android.add(
          eventId,
          c.mlpLocation.verticalUncertainty,
          false
        );
      }

      this.sip_total_vertical_uncertainty_android.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );

      // non-null altitude, non-null or non-0 altitude uncertainty
      if (c.mlpLocation?.altitude != null) {
        // The maximum altitude uncertainty value in Htech - excluding NULL but including 0
        this.sip_total_vertical_uncertainty_values_android.add(eventId, c.mlpLocation.verticalUncertainty);
      }

      if (c.accuracy !== null && c.accuracy <= horizontalAccuracyThreshold) {
        this.sip_within_horizontalAccuracyThreshold_android.add(eventId);
      }
    }
  };

  private populateSelectedVertical_iOS = (
    c: CorrelationGroup,
    eventId: number,
    verticalAccuracyThreshold: number,
  ) => {

    this.selectedVertical_total_vertical_accuracy_iOS.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_uncertainty_iOS.add(eventId, c.mlpLocation?.horizontalUncertainty, false);
    this.selectedVertical_total_vertical_altitude_iOS.add(eventId, c.mlpLocation?.altitude);
    this.selectedVertical_total_vertical_accuracy_values_iOS.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_vertical_uncertainty_iOS.add(
      eventId,
      c.mlpLocation.verticalUncertainty,
      false
    );

    this.selectedVertical_total_vertical_accuracy_all.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_uncertainty_all.add(eventId, c.mlpLocation?.horizontalUncertainty, false);
    this.selectedVertical_total_vertical_altitude_all.add(eventId, c.mlpLocation?.altitude);
    this.selectedVertical_total_vertical_accuracy_values_all.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_vertical_uncertainty_all.add(
      eventId,
      c.mlpLocation.verticalUncertainty,
      false
    );

    if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
      this.selectedVertical_total_vertical_accuracy_within_Threshold_iOS.add(eventId, c.altitudeAccuracy);
      this.selectedVertical_total_vertical_accuracy_within_Threshold_all.add(eventId, c.altitudeAccuracy);
    }

    // Records where vertical accuracy <= altitude uncertainty.
    if (
      c.altitudeAccuracy !== null &&
      c.mlpLocation?.verticalUncertainty != null &&
      c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
    ) {
      this.selectedVertical_total_vertical_accuracy_within_uncertainty_iOS.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );
      this.selectedVertical_total_vertical_accuracy_within_uncertainty_all.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );
    }

    // non-null altitude, non-null or non-0 altitude uncertainty
    if (c.mlpLocation?.altitude != null) {
      this.selectedVertical_total_vertical_uncertainty_values_iOS.add(eventId, c.mlpLocation.verticalUncertainty);
      this.selectedVertical_total_vertical_uncertainty_values_all.add(eventId, c.mlpLocation.verticalUncertainty);
    }
  };

  private populateSelectedVertical_android = (
    c: CorrelationGroup,
    eventId: number,
    verticalAccuracyThreshold: number,
  ) => {

    this.selectedVertical_total_vertical_accuracy_android.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_uncertainty_android.add(eventId, c.mlpLocation?.horizontalUncertainty, false);
    this.selectedVertical_total_vertical_altitude_android.add(eventId, c.mlpLocation?.altitude);
    this.selectedVertical_total_vertical_accuracy_values_android.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_vertical_uncertainty_android.add(
      eventId,
      c.mlpLocation.verticalUncertainty,
      false
    );

    this.selectedVertical_total_vertical_accuracy_all.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_uncertainty_all.add(eventId, c.mlpLocation?.horizontalUncertainty, false);
    this.selectedVertical_total_vertical_altitude_all.add(eventId, c.mlpLocation?.altitude);
    this.selectedVertical_total_vertical_accuracy_values_all.add(eventId, c.altitudeAccuracy);
    this.selectedVertical_total_vertical_uncertainty_all.add(
      eventId,
      c.mlpLocation.verticalUncertainty,
      false
    );

    if (c.mlpLocation?.altitude != null && c.altitudeAccuracy! <= verticalAccuracyThreshold) {
      this.selectedVertical_total_vertical_accuracy_within_Threshold_android.add(eventId, c.altitudeAccuracy);
      this.selectedVertical_total_vertical_accuracy_within_Threshold_all.add(eventId, c.altitudeAccuracy);
    }

    // Records where vertical accuracy <= altitude uncertainty.
    if (
      c.altitudeAccuracy !== null &&
      c.mlpLocation?.verticalUncertainty != null &&
      c.altitudeAccuracy <= c.mlpLocation.verticalUncertainty
    ) {
      this.selectedVertical_total_vertical_accuracy_within_uncertainty_android.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );
      this.selectedVertical_total_vertical_accuracy_within_uncertainty_all.add(
        eventId,
        c.mlpLocation.verticalUncertainty,
        false
      );
    }

    // non-null altitude, non-null or non-0 altitude uncertainty
    if (c.mlpLocation?.altitude != null) {
      this.selectedVertical_total_vertical_uncertainty_values_android.add(eventId, c.mlpLocation.verticalUncertainty);
      this.selectedVertical_total_vertical_uncertainty_values_all.add(eventId, c.mlpLocation.verticalUncertainty);
    }
  };

}
