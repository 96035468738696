import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { ErrorMessage, Formik, validateYupSchema, yupToFormErrors } from "formik";
import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormLabel, Modal } from "react-bootstrap";
import styled from "styled-components";
import { useUserContext } from "../../../contexts/UserContext";
import { getAllowListMsisdns } from "../../../services/apiService";
import { timeZoneOptions } from "../../../utilities/constants";
import {
  getTimeZoneSpecificTimeAsLocalTime,
  localDateToUTCDateTime,
} from "../../../utilities/datetime";
import {
  getUserTimeZoneAbbreviationFromIdentifier,
  TimeZoneValue,
} from "../../../utilities/timezone";
import Dropdown from "../shared/Dropdown";
import Form from "../shared/Form";
import { formatMsisdn } from "../shared/formatters";
import NumberInput from "../shared/NumberInput";
import SpinnerButton from "../shared/SpinnerButton";
import "./AddModal.css";
import addModalValidationSchema, { isEndDateValid } from "./addModalValidationSchema";
import StartDateTime from "./StartDateTime";
import { formatIntervalToTime } from "./mtlrHelpers";
import MsisdnPicker, { OptionType } from "../shared/MsisdnPicker";
import { AddMtlrSetRequest } from "../../../services/requests/AddMtlrSetRequest";
import { postAddMtlrSet } from "../../../services/apiService";
import { getTimeZoneIdentifierFromAbbreviation } from "../../../utilities/timezone";
import { MtlrSetWithDisplayName } from "../../../services/models/MtlrSetWithDisplayName";
import { customMultiSelectStyles } from "../shared/helpers";

type Props = {
  show: boolean;
  onModalAdd: (newSets: MtlrSetWithDisplayName[]) => void;
  onModalClose: () => void;
  isScheduled: boolean;
};

type FormData = {
  startDate: Date;
  hours: number;
  minutes: number;
  interval: number;
  msisdns: string[];
  timeZone: TimeZoneValue;
};

const MsisdnContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
`;

const FlexColLeft = styled.div`
  flex: 1;
`;

const FlexColRight = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const PaddedLabel = styled(FormLabel)`
  margin-left: 10px;
`;

const IntervalTimeDisplay = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0rem;
`;

const customSelectStyles = (error: boolean) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid #dc3545" : "",
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
  };
};

const customSelectStylesOnDemand = (error: boolean) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid #dc3545" : "",
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
    /* styles for menu dropdown list */
    menu: (provided: any) => ({
      ...provided,
      zIndex: "99",
    }),
  };
};

const AddModal = ({ show, onModalAdd, onModalClose, isScheduled }: Props) => {
  const [addingMtlrSet, setAddingMtlrSet] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [allowListMsisdns, setAllowListMsisdns] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [msisdnAddedMessage, setMsisdnAddedMessage] = useState<string>();
  const { userTimeZoneIdentifier } = useUserContext();
  const modalTitle = isScheduled ? "Add Scheduled MTLR Set(s)" : "Add On-Demand MTLR Set(s)";

  const initialValues: FormData = {
    startDate: getTimeZoneSpecificTimeAsLocalTime(userTimeZoneIdentifier).toJSDate(),
    hours: 8,
    minutes: 0,
    interval: 30,
    msisdns: [],
    timeZone: getUserTimeZoneAbbreviationFromIdentifier(userTimeZoneIdentifier),
  };

  //run onMount get allowList records
  useEffect(() => {
    const fetchAllowList = async () => {
      const response = await getAllowListMsisdns();

      if (response.hasError || !response.data) {
        setIsDisabled(true);
        setAllowListMsisdns([]);

        if (response.error) {
          setErrorMessage(response.error.join(" "));
        } else {
          setErrorMessage("Fetching msisdns failed contact an administrator");
        }
      } else {
        setErrorMessage("");
        setIsDisabled(false);

        let msisdns: OptionType[] = response.data.msisdns.map((value) => {
          return { value: value, label: formatMsisdn(value) };
        });

        setAllowListMsisdns(msisdns);
      }
    };

    if (show) {
      setMsisdnAddedMessage("");
      fetchAllowList();
    }
  }, [show]);

  const showAllowListError = allowListMsisdns.length === 0;

  const handleSubmit = async (values: FormData) => {
    setAddingMtlrSet(true);
    setMsisdnAddedMessage("");

    const requests = getAddMtlrSetRequest(values);

    if (requests !== null) {
      const mtlrSets = await makeApiRequests(requests);

      if (mtlrSets.length === requests.length) {
        onModalAdd(mtlrSets);
      }
    }

    setAddingMtlrSet(false);
  };

  const getAddMtlrSetRequest = (values: FormData) => {
    let requests: AddMtlrSetRequest[] = [];
    let startTimeUtc = null;
    let endTimeUtc = null;

    if (isScheduled) {
      const startDate = localDateToUTCDateTime(
        values.startDate!,
        getTimeZoneIdentifierFromAbbreviation(values.timeZone)
      );

      const endDate = startDate.plus({
        hours: values.hours || 0,
        minutes: values.minutes || 0,
      });
      startTimeUtc = startDate.toString();
      endTimeUtc = endDate.toString();
    }

    for (let i = 0; i < values.msisdns.length; i++) {
      requests.push(
        new AddMtlrSetRequest(
          values.msisdns[i].replaceAll("-", ""),
          values.interval,
          values.timeZone,
          isScheduled,
          false,
          startTimeUtc,
          endTimeUtc
        )
      );
    }

    return requests;
  };

  const makeApiRequests = async (requests: AddMtlrSetRequest[]) => {
    const OVERLAP = "Overlap:";
    let mtlrSets: MtlrSetWithDisplayName[] = [];
    let errorMessage = "";
    let overlappingMsisdns: string[] = [];
    let addedMsisdns: string[] = [];

    const promises = requests.map((r, i) =>
      postAddMtlrSet(r).then((response) => {
        return response;
      })
    );

    const responses = await Promise.all(promises);

    for (let response of responses) {
      if (response.hasError || !response.data) {
        if (response.response?.status === 400) {
          //check to see if it is an overlap error
          if (response.error && response.error.length > 0 && response.error[0].startsWith(OVERLAP)) {
            overlappingMsisdns.push(response.error[0].substring(OVERLAP.length));
          }
          else {
            errorMessage = "An error occurred in the MTLR Sets creation process, reload the application and try again.";
          }
        } else {
          errorMessage = "An error occurred in the MTLR Sets creation process, reload the application and try again.";
        }
      } else {
        mtlrSets.push(response.data.mtlrSetWithDisplayName);
        addedMsisdns.push(response.data.mtlrSetWithDisplayName.msisdn);
      }
    }

    if (overlappingMsisdns.length > 0) {
      //only show the overlap error if found, do not show the generic message
      const formattedMsisdns = overlappingMsisdns.map(m => formatMsisdn(m));

      if (requests[0].isScheduled) {
        setErrorMessage(`Scheduled MTLR Sets for this time range already exist for: ${formattedMsisdns.join(", ")}`);
      } else {
        setErrorMessage(`On-Demand MTLR Sets already exist for: ${formattedMsisdns.join(", ")}`);
      }
    } else {
      setErrorMessage(errorMessage);
    }

    if (addedMsisdns.length > 0) {
      const formattedMsisdns = addedMsisdns.map(m => formatMsisdn(m));
      
      if (requests[0].isScheduled) {
        setMsisdnAddedMessage(`Scheduled MTLR Sets successfully added: ${formattedMsisdns.join(", ")}`);
      } else {
        setMsisdnAddedMessage(`On-Demand MTLR Sets successfully added: ${formattedMsisdns.join(", ")}`);
      }
    }

    return mtlrSets;
  };

  const handleCancel = () => {
    setAddingMtlrSet(false);
    onModalClose();
  };

  const validateForm = async (values: FormData) => {
    try {
      // Pass the form data to Yup via the context option. This function will
      // throw an error if there are validation errors.
      await validateYupSchema(
        values,
        addModalValidationSchema(
          allowListMsisdns.map((m) => m.value),
          window.appSettings?.mtlrSetIntervalMinValueSeconds,
          window.appSettings?.mtlrSetIntervalMaxValueSeconds
        ),
        false,
        values
      );

      if (isScheduled) {
        if (!isEndDateValid(values.startDate, values.timeZone, values.hours, values.minutes)) {
          const err = {
            startDate:
              "Set occurs in the past for this time zone. Please adjust Start Time and/or Duration.",
          };

          return err;
        }
      }
    } catch (err) {
      return yupToFormErrors<FormData>(err);
    }
  };

  return (
    <Modal id="addModal" show={show} onHide={handleCancel} backdrop="static">
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm}>
        {({ handleSubmit, handleReset, errors, touched, values }) => (
          <Form>
            <Modal.Body>
              {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
              {msisdnAddedMessage && <div className="text-primary mt-3 mb-3">{msisdnAddedMessage}</div>}

              {showAllowListError ? (
                <p>
                  There are no MSISDNs you are allowed to access. Please check with your
                  administrator to update your permissions.
                </p>
              ) : (
                <>
                  {isScheduled && (
                    <>
                      <FormGroup className="mb-3">
                        <FormLabel>Start Time</FormLabel>
                        <div>
                          <StartDateTime name="startDate" className="startDate" />
                          {errors.startDate && touched.startDate && (
                            <div className="text-danger small">
                              <ErrorMessage name="startDate" />
                            </div>
                          )}
                        </div>

                        <div className="w-75 mt-1">
                          <Dropdown
                            id="timeZone"
                            name="timeZone"
                            options={timeZoneOptions}
                            styles={{
                              ...customSelectStyles(
                                errors.timeZone && touched.timeZone ? true : false
                              ),
                            }}
                          />
                          {errors.timeZone && touched.timeZone && (
                            <div className="text-danger small">
                              <ErrorMessage name="timeZone" />
                            </div>
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-3" style={{ width: "55%" }}>
                        <FormLabel>Duration</FormLabel>
                        <Container>
                          <FlexRow>
                            <FlexColLeft>
                              <NumberInput
                                id="hoursAddModal"
                                name="hours"
                                width={100}
                                regex={/^[\d]*$/}
                              />
                              <PaddedLabel>Hours</PaddedLabel>
                            </FlexColLeft>
                            <FlexColRight>
                              <NumberInput
                                id="minutesAddModal"
                                name="minutes"
                                width={100}
                                maxlength={2}
                                regex={/^[\d]*$/}
                              />
                              <PaddedLabel>Minutes</PaddedLabel>
                            </FlexColRight>
                          </FlexRow>
                          <ErrorContainer>
                            {errors.hours && touched.hours && (
                              <div className="text-danger small">
                                <ErrorMessage name="hours" />
                              </div>
                            )}
                            {errors.minutes && touched.minutes && (
                              <div className="text-danger small">
                                <ErrorMessage name="minutes" />
                              </div>
                            )}
                          </ErrorContainer>
                        </Container>
                      </FormGroup>
                    </>
                  )}

                  {!isScheduled && (
                    <FormGroup className="mb-3">
                      <FormLabel>Display Time Zone</FormLabel>

                      <div className="w-75 mt-1">
                        <Dropdown
                          id="timeZone"
                          name="timeZone"
                          options={timeZoneOptions}
                          styles={{
                            ...customSelectStyles(
                              errors.timeZone && touched.timeZone ? true : false
                            ),
                          }}
                        />
                        {errors.timeZone && touched.timeZone && (
                          <div className="text-danger small">
                            <ErrorMessage name="timeZone" />
                          </div>
                        )}
                      </div>
                    </FormGroup>
                  )}

                  <FormGroup className="mb-3" style={{ width: "55%" }}>
                    <FormLabel>Interval</FormLabel>
                    <Container>
                      <FlexRow>
                        <FlexColLeft>
                          <NumberInput
                            id="intervalAddModal"
                            name="interval"
                            width={100}
                            regex={/^[\d]*$/}
                          />
                          <PaddedLabel>Seconds</PaddedLabel>
                        </FlexColLeft>
                        <FlexColRight>
                          <IntervalTimeDisplay>
                            {formatIntervalToTime(values.interval)} (hh:mm:ss)
                          </IntervalTimeDisplay>
                        </FlexColRight>
                      </FlexRow>
                      <ErrorContainer>
                        {errors.interval && touched.interval && (
                          <div className="text-danger small">
                            <ErrorMessage name="interval" />
                          </div>
                        )}
                      </ErrorContainer>
                    </Container>
                  </FormGroup>

                  <MsisdnContent>
                    <FormGroup>
                      <FormLabel>MSISDN</FormLabel>
                      <MsisdnPicker
                        id="msisdns"
                        name="msisdns"
                        options={allowListMsisdns}
                        placeholder="Add MSISDN(s)..."
                        styles={customMultiSelectStyles(
                          errors.msisdns && touched.msisdns ? true : false
                        )}
                      />

                      {errors.msisdns && touched.msisdns && (
                        <div className="text-danger small">
                          <ErrorMessage name="msisdns" />
                        </div>
                      )}
                    </FormGroup>
                  </MsisdnContent>
                </>
              )}
            </Modal.Body>
            <Modal.Footer
              className={classnames({
                "justify-content-between": !showAllowListError,
              })}
            >
              <Button variant="outline-secondary" size="sm" onClick={handleCancel}>
                <FontAwesomeIcon
                  icon={icon({ name: "arrow-turn-up" })}
                  rotation={270}
                  className="me-1"
                />{" "}
                Cancel
              </Button>
              {!showAllowListError && (
                <SpinnerButton disabled={isDisabled} size="sm" isSpinning={addingMtlrSet} type="submit">
                  {!addingMtlrSet && <FontAwesomeIcon icon={icon({ name: "plus" })} />} Add MTLR
                  Set(s)
                </SpinnerButton>
              )}
              {/* FOR DEBUGGING */}
              {/*<pre>{JSON.stringify({ values, errors, touched }, null, 2)}</pre>*/}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddModal;
