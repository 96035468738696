import { useMsal } from "@azure/msal-react";
import { ButtonHTMLAttributes } from "react";
import Button from "../shared/Button";

const LoginButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect();
    } catch (err) {
      console.error(err);
    }
  };

  return <Button {...props} onClick={handleLogin} />;
};

export default LoginButton;
