export const validateReportDefinitionName = (name: string | null | undefined) => {
  if (!name) {
    return errorMessages.required;
  }

  if (name.length > 50) {
    return errorMessages.tooLong;
  }

  return null;
};

const errorMessages = {
  required: "Name is required.",
  tooLong: "Name may not be longer than 50 characters.",
};
