import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Formik,
  FormikErrors,
  FormikState,
  FormikTouched,
  FormikValues,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Alert, FormSelect } from "react-bootstrap";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { WorkBook } from "xlsx-js-style";
import { useUserContext } from "../../../contexts/UserContext";
import { getAllowListMsisdns, getEvents, getGpsFiles } from "../../../services/apiService";
import { ControlPlane } from "../../../services/models/ControlPlane";
import { ESmlcEvent } from "../../../services/models/ESmlcEvent";
import { Event } from "../../../services/models/Event";
import { MlpLocation } from "../../../services/models/MlpLocation";
import ReportDefinition from "../../../services/models/ReportDefinitions/ReportDefinition";
import ReportDefinitionSummary from "../../../services/models/ReportDefinitions/ReportDefinitionSummary";
import { saveReportDefinition, updateReportDefinition } from "../../../services/reportDefinitionService";
import { EventRequest } from "../../../services/requests/EventRequest";
import { GpsFileRequest } from "../../../services/requests/GpsFileRequest";
import LineReader from "../../../utilities/LineReader";
import { getLocalTimeAsUTC, utcISOToJsDateInTimeZone } from "../../../utilities/datetime";
import { getTimeZoneIdentifierFromAbbreviation } from "../../../utilities/timezone";
import { apiPermissions } from "../../auth/roles";
import CorrelationGroup from "../Report/models/ExcelReport/CorrelationGroup";
import NewReportGenerator from "../Report/models/ExcelReport/NewReportGenerator";
import { BatchSet } from "../shared/BatchSet";
import { DropdownOption } from "../shared/Dropdown";
import Form from "../shared/Form";
import { OptionType } from "../shared/MsisdnPicker";
import { PartialMtlr } from "../shared/MtlrToReport";
import SpinnerButton from "../shared/SpinnerButton";
import { formatMsisdn } from "../shared/formatters";
import { DateTimeOption, MTLR_DATA_FOR_REPORT_SESSION_STORAGE } from "../shared/helpers";
import BatchTab, { TabConfig } from "./BatchTab";
import BatchTabContent from "./BatchTabContent";
import ProgressModal from "./ProgressModal";
import "./Report.css";
import ReportFormData from "./ReportFormData";
import SaveLoadReportForm from "./SaveLoadReportForm";
import { AdvancedReportFilters } from "./models/AdvancedReportFilters";
import { CorrelatedESmlcRecord } from "./models/CorrelatedESmlcRecord";
import { CorrelatedMlpLocation } from "./models/CorrelatedMlpLocation";
import { EventGroundTruth } from "./models/EventGroundTruth";
import { GpsLocation } from "./models/GpsLocation";
import { GroundTruthData } from "./models/GroundTruthData";
import MlpStatusOptions from "./models/MlpStatusOptions";
import {
  GroundTruthType,
  LocationType,
  ModeOption,
  ReportType,
  TOLERANCE_MS,
  calculateBatchSetDate,
  calculateVerticalAccuracy,
  dateFormatter,
  getBatchSetEndDate,
  getBatchSetStartDate,
  getSphericalDistanceInMeters,
  isCorrelatedControlPlane,
  isCorrelatedThunderbird,
  isGpsLoggerHeader,
  rawReportHeader,
  readableStreamToFile,
  truncateInputMilliseconds,
} from "./reportHelpers";
import reportValidationSchema from "./reportValidationSchema";

const INITIAL_TAB_KEY = uuidv4();

type Msisdn = string;
type MlpPointersByMsisdn = Map<Msisdn, number>;
type MlpByMsisdn = Map<Msisdn, CorrelatedMlpLocation[]>;

export const customSelectStyles = (error: boolean, width: number) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
      width: `${width}px`,
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
    /* styles for menu which is shown when the dropdown is clicked */
    menu: (provided: any) => ({
      ...provided,
    }),
  };
};

const ModeOptionChosen = styled.span`
  margin-top: 4px;
  margin-left: 0.5rem;
  color: #e20074;
`;

const GroundTruthError = styled.div`
  margin-top: 4px;
  color: red;
`;

const modeOptions: DropdownOption[] = [
  {
    label: ModeOption.E911,
    value: ModeOption.E911,
  },
  {
    label: ModeOption.MTLR.toUpperCase(),
    value: ModeOption.MTLR,
  },
];

const formatStaticCorrelatedRecords = (groups: MlpByMsisdn, groundTruth: GroundTruthData) => {
  let correlatedRecords = Array.from(groups.values()).flat();

  //build static gpsLocation record
  for (const record of correlatedRecords) {
    const gps = new GpsLocation(
      "",
      "",
      null,
      record.truncatedAscertained_ms,
      record.ascertainedTime,
      Number(groundTruth.longitude),
      Number(groundTruth.latitude),
      Number(groundTruth.elevation),
      "0",
      "0",
      null,
      groundTruth.morphology,
      "0",
      "0",
      "0",
      true
    );

    record.correlatedGpsLocation = gps;
  }

  return correlatedRecords;
};

const formatStaticCorrelatedRecordsForReport = (groups: MlpByMsisdn, groundTruth: GroundTruthData) => {
  for (const mlpLocation of groups.values()) {
    for (let i = 0; i < mlpLocation.length; i++) {
      const gps = new GpsLocation(
        "",
        "",
        null,
        mlpLocation[i].truncatedAscertained_ms,
        mlpLocation[i].ascertainedTime,
        Number(groundTruth.longitude),
        Number(groundTruth.latitude),
        Number(groundTruth.elevation),
        "0",
        "0",
        null,
        groundTruth.morphology,
        "0",
        "0",
        "0",
        true
      );

      mlpLocation[i].correlatedGpsLocation = gps;
    }
  }
};

/*
  True for all correlated Control Plane Records whose Status is NOT yieldExclude.
  True for the latest correlated Thunderbird record within 30s of CallStartTime.
  True for the latest correlated SIP record within 30s of CallStartTime.
  False for all un-correlated records, yieldExclude records and un-used Thunderbird records.
 */
const setUsedInReportField = (correlatedRecords: CorrelatedMlpLocation[]) => {
  let currentEventId = -1;

  for (let i = 0; i < correlatedRecords.length; i++) {
    let location = correlatedRecords[i];

    if (location.correlatedGpsLocation && location.confidence && location.confidence > 0) {
      if (location.eventCallId === currentEventId) {
        if (!isCorrelatedControlPlane(location)) {
          setUsedInReportForThunderbirdOrSIP(correlatedRecords, i);
        }
      } else {
        //there is only 1 controlPlane record per event
        if (isCorrelatedControlPlane(location)) {
          if (!location.yieldExclude) {
            location.usedInReport = true;
          }
        } else {
          setUsedInReportForThunderbirdOrSIP(correlatedRecords, i);
        }
      }
    }

    currentEventId = location.eventCallId;
  }
};

const setUsedInReportForThunderbirdOrSIP = (correlatedRecords: CorrelatedMlpLocation[], loopPosition: number) => {
  let eventCallStartTimeWithTolerance =
    DateTime.fromISO(correlatedRecords[loopPosition].correlatedEvent.callStartTime, {
      zone: "utc",
    }).toMillis() + TOLERANCE_MS;

  if (correlatedRecords[loopPosition].truncatedAscertained_ms <= eventCallStartTimeWithTolerance) {
    //need to set all the previous TB/SIP records to false
    let counter = loopPosition;
    const isThunderbird = correlatedRecords[loopPosition].type === LocationType.Thunderbird;

    for (let j = correlatedRecords[loopPosition].mlpId; j > 2; j--) {
      //only reset the locations that have the same type as the correlated record type
      //there can be a CP/TB/SIP usedInReport set to true
      if (isThunderbird) {
        if (counter > 0 && correlatedRecords[counter - 1].type === LocationType.Thunderbird) {
          correlatedRecords[counter - 1].usedInReport = false;
        }
      } else if (counter > 0 && correlatedRecords[counter - 1].type !== LocationType.Thunderbird) {
        //sip record
        correlatedRecords[counter - 1].usedInReport = false;
      }

      counter--;
    }

    //set latest found so far to true
    correlatedRecords[loopPosition].usedInReport = true;
  }
};

const setUsedInReportAndIsStaticFieldsForReport = (correlationGroups: CorrelationGroup[]) => {
  let currentEventId = -1;

  for (let i = 0; i < correlationGroups.length; i++) {
    let location = correlationGroups[i].mlpLocation;

    if (location.correlatedGpsLocation && location.confidence && location.confidence > 0) {
      if (location.correlatedGpsLocation?.morphology?.toLowerCase() === "drivetest") {
        location.correlatedGpsLocation.isStatic = false;
      } else {
        location.correlatedGpsLocation.isStatic = true;
      }

      if (location.eventCallId === currentEventId) {
        if (!isCorrelatedControlPlane(location)) {
          setUsedInReportFieldForTBOrSIPRecordForReport(correlationGroups, i);
        }
      } else {
        //there is only 1 controlPlane record per event
        if (isCorrelatedControlPlane(location)) {
          if (!location.yieldExclude) {
            location.usedInReport = true;
          }
        } else {
          setUsedInReportFieldForTBOrSIPRecordForReport(correlationGroups, i);
        }
      }
    }

    currentEventId = location.eventCallId;
  }
};

const setUsedInReportFieldForTBOrSIPRecordForReport = (correlatedRecords: CorrelationGroup[], loopPosition: number) => {
  let eventCallStartTimeWithTolerance =
    DateTime.fromISO(correlatedRecords[loopPosition].mlpLocation.correlatedEvent.callStartTime, {
      zone: "utc",
    }).toMillis() + TOLERANCE_MS;

  if (correlatedRecords[loopPosition].mlpLocation.truncatedAscertained_ms <= eventCallStartTimeWithTolerance) {
    //control plane locations will not get to this function
    const isThunderbird = correlatedRecords[loopPosition].mlpLocation.type === LocationType.Thunderbird;

    //need to set all the previous records to false
    let counter = loopPosition;

    for (let j = correlatedRecords[loopPosition].mlpLocation.mlpId; j > 2; j--) {
      //only reset the locations that have the same type as the correlated record type
      //there can be a CP/TB/SIP usedInReport set to true
      if (isThunderbird) {
        if (counter > 0 && correlatedRecords[counter - 1].mlpLocation.type === LocationType.Thunderbird) {
          correlatedRecords[counter - 1].mlpLocation.usedInReport = false;
        }
      } else if (counter > 0 && correlatedRecords[counter - 1].mlpLocation.type !== LocationType.Thunderbird) {
        //sip record
        correlatedRecords[counter - 1].mlpLocation.usedInReport = false;
      }

      counter--;
    }

    //set latest found so far to true
    correlatedRecords[loopPosition].mlpLocation.usedInReport = true;
  }
};

const generateReportName = (reportType: ReportType) => {
  const dt = new Date();

  const year = dt.getFullYear();
  const month = (dt.getMonth() + 1).toString().padStart(2, "0");
  const day = dt.getDate().toString().padStart(2, "0");

  const hour = dt.getHours().toString().padStart(2, "0");
  const min = dt.getMinutes().toString().padStart(2, "0");
  const sec = dt.getSeconds().toString().padStart(2, "0");

  const typeAndExtension = reportType === ReportType.Raw ? "Raw.csv" : "Rpt.xlsx";

  return `LCAT_${year}${month}${day}${hour}${min}${sec}_${typeAndExtension}`;
};

const doCorrelations = async (mlpRecordsByMsisdn: MlpByMsisdn, gpsLogger: File) => {
  const lineReader = new LineReader(gpsLogger);
  // Initialize all pointers to index 0
  const mlpPointers: MlpPointersByMsisdn = new Map([...mlpRecordsByMsisdn.keys()].map((msisdn) => [msisdn, 0]));

  let gps: GpsLocation;
  let mlp: CorrelatedMlpLocation;
  let mlpBeforeGps: boolean;
  let mlpWithToleranceAfterGps: boolean;
  let mlpRecords: CorrelatedMlpLocation[] | undefined;
  let mlpPointer: number | undefined;

  for await (let line of lineReader.forEachLine()) {
    try {
      // skip header and blank lines
      if (!line || isGpsLoggerHeader(line)) {
        continue;
      }

      gps = GpsLocation.parse(line, true);

      mlpRecords = mlpRecordsByMsisdn.get(gps.msisdn);
      mlpPointer = mlpPointers.get(gps.msisdn);

      if (!mlpRecords || mlpRecords.length < 1 || mlpPointer === undefined) {
        continue;
      }

      for (let j = mlpPointer; j < mlpRecords.length; j++) {
        mlp = mlpRecords[j];

        mlpBeforeGps = mlp.truncatedAscertained_ms <= gps.truncatedAscertained_ms;
        mlpWithToleranceAfterGps = mlp.truncatedAscertained_ms + TOLERANCE_MS >= gps.truncatedAscertained_ms;

        if (!mlpBeforeGps) {
          // Skip this GPS record, it's too early to correlate with any MLPs.
          break;
        } else if (mlpBeforeGps && mlpWithToleranceAfterGps) {
          // This GPS record is within the correlations window.
          mlpPointers.set(gps.msisdn, j + 1);
          mlp.correlatedGpsLocation = gps;
        } else {
          // GPS is outside of the tolerance range of this MLP record and will
          // only get further away since GPS records are sorted chronologically,
          // go ahead and do a null correlation.
          mlpPointers.set(gps.msisdn, j + 1);
          mlp.correlatedGpsLocation = null;
        }
      }
    } catch (e) {
      //todo:not sure if we want to log here or not and what can we log...
      //console.error(e);
    }
  }

  return mlpRecordsByMsisdn;
};

/**
 * Determine whether a tab should show an error based on the current form state.
 *
 * @param errors The errors object provided by the Formik component.
 * @param touched The touched object provided by the Formik component.
 * @param tabIndex The tab index of the tab to evaluate.
 */
const tabHasError = (
  errors: FormikErrors<ReportFormData>,
  touched: FormikTouched<ReportFormData>,
  values: FormikValues,
  tabIndex: number
): boolean => {
  if (!errors?.batchSets || !errors.batchSets[tabIndex] || !touched?.batchSets || !touched.batchSets[tabIndex]) {
    return false;
  }

  const batchSetErrors = errors.batchSets[tabIndex] as FormikErrors<BatchSet>;
  const batchSetTouched = touched.batchSets[tabIndex];
  const batchSetValues = values.batchSets[tabIndex];

  if (batchSetErrors.msisdns && batchSetTouched.msisdns) {
    return true;
  }

  if (batchSetValues.dateTimeOption === DateTimeOption.CUSTOM_DATE_TIME) {
    if (batchSetErrors.customStartDate && batchSetTouched.customStartDate) {
      return true;
    }
    if (batchSetErrors.customEndDate && batchSetTouched.customEndDate) {
      return true;
    }
  }

  if (batchSetErrors.groundTruthData && batchSetTouched.groundTruthData) {
    if (batchSetValues.groundTruthData.groundTruth === GroundTruthType.Static) {
      if (batchSetErrors.groundTruthData.latitude && batchSetTouched.groundTruthData.latitude) {
        return true;
      }
      if (batchSetErrors.groundTruthData.longitude && batchSetTouched.groundTruthData.longitude) {
        return true;
      }
      if (batchSetErrors.groundTruthData.elevation && batchSetTouched.groundTruthData.elevation) {
        return true;
      }
      if (batchSetErrors.groundTruthData.morphology && batchSetTouched.groundTruthData.morphology) {
        return true;
      }
    }

    if (batchSetValues.groundTruthData.groundTruth === GroundTruthType.GpsLogger) {
      if (batchSetErrors.groundTruthData.gpsLoggerFile && batchSetTouched.groundTruthData.gpsLoggerFile) {
        return true;
      }
    }
  }

  if (batchSetErrors.advancedFilters && batchSetTouched.advancedFilters) {
    return true;
  }

  return false;
};

export type GpsFileProgress = { batchSetIndex: number; isComplete: boolean; };

const Report = () => {
  const { userTimeZoneIdentifier, currentUser, userHasApiPermission } = useUserContext();
  const [runningRawData, setRunningRawData] = useState(false);
  const [runningExcelReport, setRunningExcelReport] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [allowListMsisdns, setAllowListMsisdns] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [progressModalErrorMessage, setProgressModalErrorMessage] = useState<string>("");
  const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
  const [numberOfEvents, setNumberOfEvents] = useState<number>(0);
  const [numberOfFilteredEvents, setNumberOfFilteredEvents] = useState<number>(0);
  const [numberOfGPSLocations, setNumberOfGPSLocations] = useState<number>(0);
  const [numberOfLocations, setNumberOfLocations] = useState<number>(0);
  const [numberOfESmlcCalls, setNumberOfESmlcCalls] = useState<number | null>(null);
  const [batchSetsProgress, setBatchSetsProgress] = useState<boolean[]>([]);
  const [gpsFileProgress, setGpsFileProgress] = useState<GpsFileProgress[]>([]);
  const [retrieveESmlcRecords, setRetrieveESmlcRecords] = useState<boolean>(false);
  const [exportJobIds, setExportJobIds] = useState<string[]>([]);
  const [doneProgressNumber, setDoneProgressNumber] = useState<number>(1);
  const [doneProgressTime, setDoneProgressTime] = useState<number>(0);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(INITIAL_TAB_KEY);
  const [reportDefinition, setReportDefinition] = useState<ReportDefinition>();

  const [initialFormData, setInitialFormData] = useState<ReportFormData>(
    new ReportFormData("", "raw", false, [new BatchSet(userTimeZoneIdentifier, INITIAL_TAB_KEY)], false)
  );

  const [initialFormTouched, setInitialFormTouched] = useState<FormikTouched<ReportFormData>>({});

  const { permissions } = useUserContext();
  const handleAddModalCancel = () => setShowProgressModal(false);
  const abortController = useRef<AbortController>(new AbortController());

  const handleCancel = () => {
    abortController.current.abort();
    setIsCanceled(true);
    initializeButtonStatus();
  };

  useEffect(() => {
    // Retrieve the array from session storage
    const storageItem = sessionStorage.getItem(MTLR_DATA_FOR_REPORT_SESSION_STORAGE);

    if (storageItem) {
      // remove the session storage item
      sessionStorage.removeItem(MTLR_DATA_FOR_REPORT_SESSION_STORAGE);

      const formData = new ReportFormData("", "raw", false, [new BatchSet(userTimeZoneIdentifier, INITIAL_TAB_KEY)], false);
      const mtlrReportSets = JSON.parse(storageItem);

      formData.modeOption = ModeOption.MTLR;

      for (let i = 0; i < mtlrReportSets.partialMtlr.length; i++) {
        const partialMtlr: PartialMtlr = mtlrReportSets.partialMtlr[i];

        if (i > 0) {
          formData.batchSets[i] = new BatchSet(userTimeZoneIdentifier);
        }

        formData.batchSets[i].msisdns.push(partialMtlr.msisdn);
        formData.batchSets[i].dateTimeOption = DateTimeOption.CUSTOM_DATE_TIME;

        const timeZoneIdentifier = getTimeZoneIdentifierFromAbbreviation(partialMtlr.timeZone);

        formData.batchSets[i].customStartDate = DateTime.fromISO(partialMtlr.startTimeUtc, {
          zone: "utc",
        })
          .setZone(timeZoneIdentifier, {
            keepLocalTime: false,
          })
          .setZone(userTimeZoneIdentifier, {
            keepLocalTime: true,
          })
          .toJSDate();

        formData.batchSets[i].customEndDate = DateTime.fromISO(partialMtlr.endTimeUtc, {
          zone: "utc",
        })
          .setZone(timeZoneIdentifier, {
            keepLocalTime: false,
          })
          .setZone(userTimeZoneIdentifier, {
            keepLocalTime: true,
          })
          .toJSDate();

        formData.batchSets[i].timeZone = partialMtlr.timeZone;
      }

      setInitialFormData(formData);
    }
  }, [userTimeZoneIdentifier]);

  //run onMount get allowList records
  useEffect(() => {
    const fetchAllowList = async () => {
      const response = await getAllowListMsisdns();

      if (response.hasError || !response.data) {
        setIsDisabled(true);
        setAllowListMsisdns([]);

        if (response.error) {
          setErrorMessage(response.error.join(" "));
        } else {
          setErrorMessage("Fetching msisdns failed contact an administrator");
        }
      } else {
        setErrorMessage("");
        setIsDisabled(false);

        let msisdns: OptionType[] = response.data.msisdns.map((value) => {
          return { value: value, label: formatMsisdn(value) };
        });

        setAllowListMsisdns(msisdns);
      }
    };

    fetchAllowList();
  }, []);

  const makeApiRequests = async (requests: EventRequest[], formData: ReportFormData) => {
    const eventsWithGroundTruth: EventGroundTruth[] = [];
    const errorMessages = [];

    const eventPromises = requests.map((request, requestIdx) =>
      getEvents(request, abortController.current.signal).then((response) => {
        //each promise will set the batchSet position in the array to true when it is resolved
        setBatchSetsProgress((prevProgress) =>
          prevProgress.map((progress, progressIdx) => (progressIdx === requestIdx ? true : progress))
        );

        return response;
      })
    );

    const eventResponses = await Promise.all(eventPromises);
    const reportFormDataWithDownloadedGpsFiles = await getReportFormDataWithDownloadedGpsFiles(formData);

    // Promise.all() maintains original ordering of promises, so we can trust the i index here to be the correct batchSetId
    for (let i = 0; i < eventResponses.length; i++) {
      const response = eventResponses[i];

      if (response.hasError || !response.data) {
        if (response.error) {
          if (typeof response.error === "string") {
            if (response.error !== undefined) {
              errorMessages.push(response.error);
            }
          } else {
            if (response.error[0].toString().includes("abort")) {
              errorMessages.push("Query was cancelled");
              abortController.current = new AbortController();
            } else {
              errorMessages.push(response.error.join(" "));
            }
          }
        } else {
          errorMessages.push("Server was unable to return Event data.");
        }

        setIsCanceled(false);
        setIsDisabled(false);
      } else {
        //the response can be valid but no data is returned, throw out the response
        if (response.data.events.length > 0) {
          /*
           From the server the response.data.events and response.data.eSmlcEvents are tied together
           with the callGuid. The groundTruth is not part of the response so it will be tied
           to the events using the batchSetId.
           */
          response.data.events.forEach((value, index) => (value.batchSetId = i));

          const eventGroundTruth = new EventGroundTruth(
            i,
            response.data.events,
            response.data.eSmlcEvents,
            reportFormDataWithDownloadedGpsFiles.batchSets[i].groundTruthData
          );
          //put the ground truth from the formData with the response result
          eventsWithGroundTruth.push(eventGroundTruth);
        }
      }
    }

    if (errorMessages.length > 0) {
      setProgressModalErrorMessage(errorMessages.join(" "));
    }

    return eventsWithGroundTruth;
  };

  const getReportFormDataWithDownloadedGpsFiles = async (reportFormData: ReportFormData) => {
    const reportFormDataWithGpsFiles = structuredClone(reportFormData);

    // build gps file requests
    const gpsFileRequests: { gpsFileRequest: GpsFileRequest; batchSetIndex: number; }[] = [];
    for (let i = 0; i < reportFormDataWithGpsFiles.batchSets.length; i++) {
      const batchSet = reportFormDataWithGpsFiles.batchSets[i];

      const batchSetStartDate = getBatchSetStartDate(
        batchSet.dateTimeOption,
        batchSet.customStartDate,
        batchSet.timeZone
      );
      const batchSetEndDate = getBatchSetEndDate(batchSet.dateTimeOption, batchSet.customEndDate, batchSet.timeZone);

      if (batchSet.groundTruthData.groundTruth === GroundTruthType.SavedGPSData) {
        // make API request
        const request = new GpsFileRequest(batchSet.msisdns, batchSetStartDate, batchSetEndDate);
        gpsFileRequests.push({ gpsFileRequest: request, batchSetIndex: i });
      }
    }

    setGpsFileProgress(
      gpsFileRequests.map((r) => {
        return {
          batchSetIndex: r.batchSetIndex,
          isComplete: false,
        };
      })
    );

    const gpsFilePromises = gpsFileRequests.map(async ({ gpsFileRequest, batchSetIndex }) => {
      const gpsFileResponse = await getGpsFiles(gpsFileRequest, abortController.current.signal);

      //each promise will set the batchSet position in the array to true when it is resolved
      setGpsFileProgress((prevProgress) =>
        prevProgress.map((progress) =>
          progress.batchSetIndex === batchSetIndex ? { ...progress, isComplete: true } : progress
        )
      );

      return { gpsFileResponse: gpsFileResponse, batchSetIndex: batchSetIndex };
    });

    const gpsFileResponses = await Promise.all(gpsFilePromises);

    const errorMessages: string[] = [];
    for (let i = 0; i < gpsFileResponses.length; i++) {
      const { gpsFileResponse, batchSetIndex } = gpsFileResponses[i];

      if (gpsFileResponse.hasError || !gpsFileResponse.data) {
        if (gpsFileResponse.error) {
          if (typeof gpsFileResponse.error === "string") {
            if (gpsFileResponse.error !== undefined) {
              errorMessages.push(gpsFileResponse.error);
            }
          } else {
            if (gpsFileResponse.error[0].toString().includes("abort")) {
              errorMessages.push("Query was cancelled");
              abortController.current = new AbortController();
            } else {
              errorMessages.push(gpsFileResponse.error.join(" "));
            }
          }
        } else {
          errorMessages.push("Server was unable to return Gps File Data.");
        }

        setIsCanceled(false);
        setIsDisabled(false);
      } else {
        const readableStream = gpsFileResponse.data as ReadableStream;
        const file = await readableStreamToFile(readableStream, `savedGpsLoggerFile_batch_${batchSetIndex}.csv`);
        reportFormDataWithGpsFiles.batchSets[batchSetIndex].groundTruthData.gpsLoggerFile = file;
      }
    }

    if (errorMessages.length > 0) {
      setProgressModalErrorMessage(errorMessages.join(" "));
    }

    return reportFormDataWithGpsFiles;
  };

  /*
    The eventsWithGroundTruth[] represents the batchSets. The first sort will ensure that the events in a batchSet
    are sorted by callStarTime then msisdn. The second sort will ensure that the eventsWithGroundTruth are 
    sorted by callStarTime then msisdn.

    if the data from the server looks like:
      eventsWithGroundTruth[0].event[0].callStarTime = 1/10/24
      eventsWithGroundTruth[0].event[0].msisdn = 123
      eventsWithGroundTruth[0].event[1].callStarTime = 1/5/24
      eventsWithGroundTruth[0].event[1].msisdn = 456
    it needs to be:
      eventsWithGroundTruth[0].event[0].callStarTime = 1/5/24
      eventsWithGroundTruth[0].event[0].msisdn = 456
      eventsWithGroundTruth[0].event[1].callStarTime = 1/10/24
      eventsWithGroundTruth[0].event[1].msisdn = 123         
      
    Another server result could be:
      eventsWithGroundTruth[0].event[0].callStarTime = 1/10/24
      eventsWithGroundTruth[0].event[0].msisdn = 123
      eventsWithGroundTruth[1].event[0].callStarTime = 1/5/24
      eventsWithGroundTruth[1].event[0].msisdn = 456
    needs to be:
      eventsWithGroundTruth[0].event[0].callStarTime = 1/5/24
      eventsWithGroundTruth[0].event[0].msisdn = 456
      eventsWithGroundTruth[1].event[0].callStarTime = 1/10/24
      eventsWithGroundTruth[1].event[0].msisdn = 123
   */
  const sortEvents = (eventsWithGroundTruth: EventGroundTruth[]) => {
    const sortedEvents: EventGroundTruth[] = eventsWithGroundTruth;

    //loop over each eventsWithGroundTruth and sort the events in each by callStartTime then msisdn
    for (let i = 0; i < sortedEvents.length; i++) {
      sortedEvents[i].events.sort((a: Event, b: Event) => {
        if (a.callStartTime < b.callStartTime) {
          return -1;
        }

        if (a.callStartTime > b.callStartTime) {
          return 1;
        }

        if (a.callStartTime === b.callStartTime) {
          if (a.msisdn < b.msisdn) {
            return -1;
          }

          if (a.msisdn > b.msisdn) {
            return 1;
          }
        }

        return 0;
      });
    }

    //loop over each eventsWithGroundTruth, using events[0] and sort the eventsWithGroundTruth
    sortedEvents.sort((a, b) => {
      if (a.events[0].callStartTime < b.events[0].callStartTime) {
        return -1;
      }

      if (a.events[0].callStartTime > b.events[0].callStartTime) {
        return 1;
      }

      if (a.events[0].callStartTime === b.events[0].callStartTime) {
        if (a.events[0].msisdn < b.events[0].msisdn) {
          return -1;
        }

        if (a.events[0].msisdn > b.events[0].msisdn) {
          return 1;
        }
      }

      return 0;
    });

    return sortedEvents;
  };

  /*
   The report uses callId to be the number of events on the report. Each msisdn is an event. A batchSet
   can have many events and a report can have many batchSets. Once the data is sorted need to set a 
   callId for all events across all the batchSets. Gather the correct eSmlcEvents for an event by using
   the callGuid. Gather the correct groundTruth for an event using the batchSetId.
   */
  const addCallId = (eventsWithGroundTruth: EventGroundTruth[]) => {
    const sortedEventsWithCallId: EventGroundTruth[] = [];
    let callId = 1;

    for (let i = 0; i < eventsWithGroundTruth.length; i++) {
      let eSmlcRecordsWithEventGuid: ESmlcEvent[] = [];
      const batchSetId = eventsWithGroundTruth[i].events[0].batchSetId;

      const groundTruthWithBatchSetId = eventsWithGroundTruth.find(
        (g) => g.groundTruthData.batchSetId === batchSetId
      )?.groundTruthData;

      for (let j = 0; j < eventsWithGroundTruth[i].events.length; j++) {
        eventsWithGroundTruth[i].events[j].callId = callId;

        for (let l = 0; l < eventsWithGroundTruth[i].events[j].mlpLocations.length; l++) {
          eventsWithGroundTruth[i].events[j].mlpLocations[l].eventCallId = callId;
        }

        //get the eSmlc records that have the same callGuid as the event
        const eSmlcRecords = eventsWithGroundTruth[i].eSmlcEvents.filter(
          (e: ESmlcEvent) => e.callGuid === eventsWithGroundTruth[i].events[j].callGuid
        );
        //set the eSmlc callId
        for (let eSmlcEvent of eSmlcRecords) {
          eSmlcEvent.callId = callId;

          for (let k = 0; k < eSmlcEvent.eSmlcLocations.length; k++) {
            eSmlcEvent.eSmlcLocations[k].callId = callId;
          }
        }

        eSmlcRecordsWithEventGuid.push(...eSmlcRecords);
        callId++;
      }

      sortedEventsWithCallId.push(
        new EventGroundTruth(
          batchSetId,
          eventsWithGroundTruth[i].events,
          eSmlcRecordsWithEventGuid,
          groundTruthWithBatchSetId !== undefined ? groundTruthWithBatchSetId : new GroundTruthData()
        )
      );
    }

    return sortedEventsWithCallId;
  };

  const applyAdvancedFiltersToEvents = (eventsWithGroundTruth: EventGroundTruth[], values: ReportFormData) => {
    const filteredEventsWithGroundTruth: EventGroundTruth[] = [];

    values.batchSets.forEach((batchSet) => {
      //TODO: Can't we just add the Id to the batch set?
      //And can't we just do it when we create a dang thing? This feels overly complicated
      const batchSetId = batchSet.groundTruthData.batchSetId;
      const batchEventGroundTruth = eventsWithGroundTruth.find((x) => x.batchSetId === batchSetId);

      if (batchEventGroundTruth) {
        const filteredEvents = batchEventGroundTruth.events.filter((event) =>
          applyAdvancedFilters(event, batchSet.advancedFilters)
        );

        //filter where filteredEvents contains that guid
        const filteredCallGuids = filteredEvents.map((x) => x.callGuid);
        const filteredEsmlcEvents = batchEventGroundTruth.eSmlcEvents.filter((e: ESmlcEvent) =>
          filteredCallGuids.includes(e.callGuid)
        );

        // RO 4/1/2024 - the `values` variable (formik form data) does not have the gpsLoggerFile in its state
        // at this point, so we need to retrieve the actual File object from the object returned from makeApiRequests().
        const groundTruthData: GroundTruthData = {
          ...batchSet.groundTruthData,
          gpsLoggerFile: batchEventGroundTruth.groundTruthData.gpsLoggerFile,
        };

        filteredEventsWithGroundTruth.push(
          new EventGroundTruth(batchSetId, filteredEvents, filteredEsmlcEvents, groundTruthData)
        );
      }
    });

    return filteredEventsWithGroundTruth;
  };

  //If an event meets the filter arguments, return true
  const applyAdvancedFilters = (event: Event, filters: AdvancedReportFilters) => {
    if (filters.useRanFilters) {
      const appliedRanFilters = [];

      if (filters.ran2G) {
        appliedRanFilters.push("2G/3G");
      }

      if (filters.ran4G) {
        appliedRanFilters.push("4G");
      }

      if (filters.ran5G) {
        appliedRanFilters.push("5G");
      }

      if (!appliedRanFilters.includes(event.radioAccessNetwork.toLocaleUpperCase())) {
        return false;
      }
    }

    //Filter CGI? CGI is in the Locations, not the Events
    //The CGI is only on the ControlPlane Location, of which there can be only one, so find it and filter based on that
    //if it fails, throw the event out
    if (filters.useCgiFilter) {
      const controlPlaneLocation = event.mlpLocations.find((x) => x.type === "CP");
      if (
        !controlPlaneLocation ||
        !(controlPlaneLocation as ControlPlane).cgi ||
        (controlPlaneLocation as ControlPlane).cgi === ""
      ) {
        return false;
      }

      const cgiBits = (controlPlaneLocation as ControlPlane).cgi.split("-");
      const section1 = cgiBits[0] ?? "";
      if (filters.cgi1 && filters.cgi1 !== "" && filters.cgi1 !== section1) {
        return false;
      }

      const section2 = cgiBits[1] ?? "";
      if (filters.cgi2 && filters.cgi2 !== "" && filters.cgi2 !== section2) {
        return false;
      }

      const section3 = cgiBits[2] ?? "";
      if (filters.cgi3 && filters.cgi3 !== "" && filters.cgi3 !== section3) {
        return false;
      }

      const section4 = cgiBits[3] ?? "";
      if (filters.cgi4 && filters.cgi4 !== "" && filters.cgi4 !== section4) {
        return false;
      }
    }

    if (filters.useGeographicFilter) {
      const hasValidLocation = event.mlpLocations.some((location) => isLocationWithinParameters(location, filters));
      if (!hasValidLocation) {
        return false;
      }
    }

    return true;
  };

  const isLocationWithinParameters = (location: MlpLocation, filters: AdvancedReportFilters) => {
    if (filters.minAltitude && (!location.horizontalUncertainty || location.horizontalUncertainty < Number(filters.minAltitude))) {
      return false;
    }

    if (filters.maxAltitude && (!location.horizontalUncertainty || location.horizontalUncertainty > Number(filters.maxAltitude))) {
      return false;
    }

    const metersFromFilterPoint = getSphericalDistanceInMeters(
      location.longitude,
      location.latitude,
      Number(filters.longitude),
      Number(filters.latitude)
    );
    if (metersFromFilterPoint > Number(filters.radius)) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (values: ReportFormData) => {
    for (let i = 0; i < values.batchSets.length; i++) {
      values.batchSets[i].groundTruthData.batchSetId = i;
    }

    let startProgressTime = new Date();
    values.reportType === ReportType.Raw ? setRunningRawData(true) : setRunningExcelReport(true);

    const exportJobIds = values.batchSets.map((bs) => bs.key);
    setExportJobIds(exportJobIds);
    setProgressModalErrorMessage("");
    setIsDisabled(true);
    setDoneProgressNumber(1);

    if (!userHasApiPermission(apiPermissions.LCAT_GETXML)) { 
      values.rawCallLinks = false;
    }

    const requests = await getEventRequest(values);

    if (requests !== null) {
      //initialize the progress array to all null
      setBatchSetsProgress(Array(requests.length).fill(false));

      setShowProgressModal(true);

      if (isCanceled) {
        initializeButtonStatus();
        return;
      }

      const eventsWithGroundTruth = await makeApiRequests(requests, values);
      setDoneProgressNumber(2);

      const unfilteredNumberOfEvents = eventsWithGroundTruth.flatMap((x) => x.events);
      setNumberOfEvents(unfilteredNumberOfEvents.length);

      const filteredEventsWithGroundTruth = applyAdvancedFiltersToEvents(eventsWithGroundTruth, values);

      if (filteredEventsWithGroundTruth.length > 0) {
        setErrorMessage("");
        const flatEvents = filteredEventsWithGroundTruth.flatMap((x) => x.events);

        setNumberOfFilteredEvents(flatEvents.length);

        setRetrieveESmlcRecords(values.eSMLC);
        if (values.eSMLC) {
          setNumberOfESmlcCalls(filteredEventsWithGroundTruth.flatMap((x) => x.eSmlcEvents).length);
        }

        let totalNumberOfLocations = 0;
        flatEvents.forEach((event) => {
          totalNumberOfLocations += event.mlpLocations.length;
        });
        setNumberOfLocations(totalNumberOfLocations);

        if (flatEvents.length === 0) {
          setProgressModalErrorMessage("No data found that matches specified criteria.");
        } else {
          for (const val of flatEvents) {
            if (!val.isVersionSupported) {
              setProgressModalErrorMessage(
                "GMLC log version mismatch. Please notify the application owner of this warning."
              );
              break;
            }
          }

          const sortedEvents = sortEvents(filteredEventsWithGroundTruth);
          const sortedEventsWithCallId = addCallId(sortedEvents);

          await processResponseToBuildReport(sortedEventsWithCallId, values.reportType);
        }
      } else {
        setProgressModalErrorMessage("No data found that matches specified criteria.");
      }
    }

    initializeButtonStatus();
    let endProgressTime = new Date();
    setDoneProgressTime((endProgressTime.getTime() - startProgressTime.getTime()) / 1000);
  };

  const initializeButtonStatus = () => {
    setRunningExcelReport(false);
    setRunningRawData(false);
    setIsDisabled(false);
  };

  const getEventRequest = async (values: ReportFormData) => {
    const dateNow = getLocalTimeAsUTC();

    let requests: EventRequest[] = [];

    for (let i = 0; i < values.batchSets.length; i++) {
      const batchDates = calculateBatchSetDate(values.batchSets[i], dateNow);

      requests.push(
        new EventRequest(
          values.batchSets[i].msisdns,
          batchDates.startDate,
          batchDates.endDate,
          values.modeOption,
          values.batchSets[i].key,
          values.eSMLC,
          values.rawCallLinks
        )
      );
    }

    return requests;
  };

  const processResponseToBuildReport = async (eventsWithGroundTruth: EventGroundTruth[], reportType: string) => {
    setDoneProgressNumber(3);
    
    const groups: MlpByMsisdn[] = [];
    let correlatedRecords: CorrelatedMlpLocation[][] = [];

    for (let i = 0; i < eventsWithGroundTruth.length; i++) {
      const group: MlpByMsisdn = new Map();

      for (const event of eventsWithGroundTruth[i].events) {
        let mlpCount = 1;

        for (const mlp of event.mlpLocations) {
          group.set(event.msisdn, [
            ...(group.get(event.msisdn) || []),
            {
              ...mlp,
              truncatedAscertained_ms: truncateInputMilliseconds(
                DateTime.fromISO(mlp.ascertainedTime, { zone: "utc" })
              ).toMillis(),
              correlatedGpsLocation: null,
              correlatedEvent: event,
              mlpId: mlpCount,
              totalMlps: event.mlpLocations.length,
              usedInReport: false,
            },
          ]);

          mlpCount++;
        }
      }

      groups.push(group);
    }

    if (reportType === ReportType.Raw) {
      for (let i = 0; i < groups.length; i++) {
        switch (eventsWithGroundTruth[i].groundTruthData.groundTruth) {
          case GroundTruthType.None: {
            correlatedRecords[i] = Array.from(groups[i].values()).flat();
            break;
          }
          case GroundTruthType.Static: {
            correlatedRecords[i] = formatStaticCorrelatedRecords(groups[i], eventsWithGroundTruth[i].groundTruthData);
            break;
          }
          case GroundTruthType.SavedGPSData:
          case GroundTruthType.GpsLogger: {
            //sort the records
            for (const [msisdn, mlps] of groups[i].entries()) {
              groups[i].set(msisdn, mlps.sort(CorrelatedMlpLocation.sort));
            }

            //correlate the mlpLocation records to the gpsLogger file records
            let correlatedMlpData = await doCorrelations(
              groups[i],
              eventsWithGroundTruth[i].groundTruthData.gpsLoggerFile as File
            );

            correlatedRecords[i] = Array.from(correlatedMlpData.values()).flat();

            break;
          }
        }
      }

      let totalNumberOfGPSLocations = 0;
      //combine all the records into an Array of Maps
      const totalGroups = groups.flatMap((group) => [...group.entries()].map((e) => new Map([e])));

      for (let i = 0; i <= totalGroups.length - 1; i++) {
        const correlatedMlpLocations = Array.from(totalGroups[i].values()).flat();
        totalNumberOfGPSLocations += correlatedMlpLocations.filter((c) => c.correlatedGpsLocation !== null).length;
      }
      setNumberOfGPSLocations(totalNumberOfGPSLocations);

      //combine all the records into one set of data to send to the report
      const flatCorrelatedRecords = Array.from(correlatedRecords.values()).flat();

      setUsedInReportField(flatCorrelatedRecords);
      downloadRawDataReport(flatCorrelatedRecords);
    } else {
      let correlatedESmlcRecords: CorrelatedESmlcRecord[] = [];

      for (let i = 0; i < groups.length; i++) {
        const groundTruthData = eventsWithGroundTruth[i].groundTruthData;

        switch (groundTruthData.groundTruth) {
          case GroundTruthType.None: {
            break;
          }
          case GroundTruthType.Static: {
            formatStaticCorrelatedRecordsForReport(groups[i], groundTruthData);
            break;
          }
          case GroundTruthType.SavedGPSData:
          case GroundTruthType.GpsLogger: {
            //sort the records
            for (const [msisdn, mlps] of groups[i].entries()) {
              groups[i].set(msisdn, mlps.sort(CorrelatedMlpLocation.sort));
            }
            //correlate the mlpLocation records to the gpsLogger file records
            await doCorrelations(groups[i], groundTruthData.gpsLoggerFile as File);

            break;
          }
        }
      }

      //combine all the records into an Array of Maps
      const totalGroups = groups.flatMap((group) => [...group.entries()].map((e) => new Map([e])));

      for (let i = 0; i < eventsWithGroundTruth.length; i++) {
        if (eventsWithGroundTruth[i].eSmlcEvents && eventsWithGroundTruth[i].eSmlcEvents.length > 0) {
          for (const eSmlcEvent of eventsWithGroundTruth[i].eSmlcEvents) {

            let groupValues: CorrelatedMlpLocation[] = [];

            //loop through the group entries looking for the matching msisdn
            for (let [key, value] of totalGroups.entries()) {
              const values = value.get(eSmlcEvent.msisdn);

              if (values) {
                groupValues = values;

                break;
              }
            }

            let gpsRecord = groupValues.find((x) => x.eventCallId === eSmlcEvent.callId)?.correlatedGpsLocation;

            let correlatedESmlcRecord = new CorrelatedESmlcRecord(
              eSmlcEvent,
              eSmlcEvent.eSmlcLocations,
              gpsRecord ? gpsRecord : null
            );

            correlatedESmlcRecords.push(correlatedESmlcRecord);
          }
        }
      }

      setDoneProgressNumber(4);

      let totalNumberOfGPSLocations = 0;

      for (let i = 0; i <= totalGroups.length - 1; i++) {
        const correlatedMlpLocations = Array.from(totalGroups[i].values()).flat();
        totalNumberOfGPSLocations += correlatedMlpLocations.filter((c) => c.correlatedGpsLocation !== null).length;
      }
      
      setNumberOfGPSLocations(totalNumberOfGPSLocations);
      const workbook = buildXlsxReport(totalGroups, correlatedESmlcRecords);

      setDoneProgressNumber(5);

      NewReportGenerator.write(workbook, generateReportName(ReportType.Excel));

      setDoneProgressNumber(6);
    }
  };

  const downloadRawDataReport = (data: CorrelatedMlpLocation[]) => {
    let rows = [];

    if (isCanceled) {
      setIsCanceled(false);
      initializeButtonStatus();
      return;
    }

    for (const location of data) {
      if (isCanceled) {
        setIsCanceled(false);
        initializeButtonStatus();
        return;
      }

      let cgi = "";
      let statusValue = null;
      let statusName = "";
      let ubp = null;
      let floorNumber = null;
      let neadAddressType = "";
      let neadCounty = "";
      let neadHouseNumber = "";
      let neadFloor = "";
      let neadUnit = "";
      let neadRoad = "";
      let neadCity = "";
      let neadState = "";
      let neadPostalCode = "";
      let horizontalAccuracy = null;
      let verticalAccuracy = null;
      let locationRequestTime = null;
      let shape = null;
      let extendedHorizontalUncertainty = "False";
      let extendedVerticalUncertainty = "False";


      //if correlating with ground truth = none all gps fields should be null
      let gpsUserName = null;
      let gpsLongitude = null;
      let gpsLatitude = null;
      let gpsAscertained = null;
      let gpsIsStatic = null;
      let gpsElevation = null;
      let gpsSpeed = null;
      let gpsSatelliteCount = null;
      let gpsStatus = null;
      let gpsMorphology = null;
      let gpsHdop = null;
      let gpsPdop = null;
      let gpsVdop = null;

      if (location.correlatedGpsLocation) {
        //this will be true if the Static Ground Truth option was selected
        if (location.correlatedGpsLocation.isStatic) {
          gpsIsStatic = "True";
        } else {
          if (location.correlatedGpsLocation?.morphology?.toLowerCase() === "drivetest") {
            gpsIsStatic = "False";
          } else {
            gpsIsStatic = "True";
          }
        }

        gpsAscertained = dateFormatter(location.correlatedGpsLocation.ascertainedTime);

        if (
          (isCorrelatedControlPlane(location) && MlpStatusOptions.isSuccess(location.statusValue)) ||
          (isCorrelatedThunderbird(location) && location.latitude !== 0 && location.longitude !== 0)
        ) {
          horizontalAccuracy = getSphericalDistanceInMeters(
            location.longitude,
            location.latitude,
            location.correlatedGpsLocation.longitude,
            location.correlatedGpsLocation.latitude
          ).toFixed(4);
        }

        if (location.altitude !== null) {
          verticalAccuracy = calculateVerticalAccuracy(
            Number(location.altitude),
            location.correlatedGpsLocation.elevation
          ).toFixed(4);
        }

        gpsUserName = location.correlatedGpsLocation?.gpsUser;
        gpsLongitude = location.correlatedGpsLocation?.longitude;
        gpsLatitude = location.correlatedGpsLocation?.latitude;
        gpsElevation = location.correlatedGpsLocation?.elevation;
        gpsSpeed = location.correlatedGpsLocation?.speed;
        gpsSatelliteCount = location.correlatedGpsLocation?.satelliteCount;
        gpsStatus = location.correlatedGpsLocation?.status;
        gpsMorphology = location.correlatedGpsLocation?.morphology;
        gpsHdop = location.correlatedGpsLocation?.hdop;
        gpsPdop = location.correlatedGpsLocation?.pdop;
        gpsVdop = location.correlatedGpsLocation?.vdop;
      }

      if (isCorrelatedControlPlane(location)) {
        cgi = location.cgi;
        statusValue = location.statusValue;
        statusName = location.statusName;
        ubp = location.ubp;
        neadAddressType = location.neadAddressType;
        neadCounty = location.neadCounty;
        neadHouseNumber = location.neadHouseNumber;
        neadFloor = location.neadFloor;
        neadUnit = location.neadUnit;
        neadRoad = location.neadRoad;
        neadCity = location.neadCity;
        neadState = location.neadState;
        neadPostalCode = location.neadPostalCode;
        locationRequestTime = location.locationRequestTime;
        shape = location.shape;
        extendedHorizontalUncertainty = location.extendedHorizontalUncertainty ? "True" : "False";
        extendedVerticalUncertainty = location.extendedVerticalUncertainty ? "True" : "False";
      } else if (isCorrelatedThunderbird(location)) {
        floorNumber = location.floorNumber;
      }

      const row = [
        location.eventCallId,
        location.correlatedEvent.transactionId,
        location.correlatedEvent.msisdn,
        location.correlatedEvent.imsi,
        location.correlatedEvent.imei,
        location.correlatedEvent.radioAccessNetwork,
        location.type,
        location.correlatedEvent.isiOS,
        dateFormatter(location.correlatedEvent.callStartTime),
        dateFormatter(locationRequestTime),
        dateFormatter(location.ascertainedTime),
        location.correlatedEvent.active ? "True" : "False",
        horizontalAccuracy,
        verticalAccuracy,
        location.longitude,
        location.latitude,
        shape,
        extendedHorizontalUncertainty,
        extendedVerticalUncertainty,
        location.horizontalUncertainty,
        location.confidence,
        location.altitude,
        location.verticalUncertainty,
        location.latency,
        statusValue,
        statusName,
        location.isSuccessful,
        cgi,
        location.positionMethod,
        ubp,
        floorNumber,
        neadAddressType,
        neadCounty,
        neadHouseNumber,
        neadFloor,
        neadUnit,
        neadRoad,
        neadCity,
        neadState,
        neadPostalCode,
        gpsUserName,
        gpsLongitude,
        gpsLatitude,
        gpsAscertained,
        gpsIsStatic,
        gpsElevation,
        gpsSpeed,
        gpsSatelliteCount,
        gpsStatus,
        gpsMorphology,
        gpsHdop,
        gpsPdop,
        gpsVdop,
        location.selectedVertical,
        location.usedInReport,
        location.correlatedEvent.isVersionSupported,
        location.correlatedEvent.rawCallLink,
      ];

      rows.push(row);
    }

    setDoneProgressNumber(4);

    //Concatenate all the header and row data and write to a file.
    const headerRow: string = rawReportHeader.join(",");
    const fileData = [[headerRow, ...rows].join("\n")];

    const csvData = new Blob(fileData, { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);

    const hiddenAnchor = document.createElement("a");
    hiddenAnchor.href = csvUrl;
    hiddenAnchor.download = generateReportName(ReportType.Raw);

    if (isCanceled) {
      setIsCanceled(false);
      initializeButtonStatus();
      return;
    }

    setDoneProgressNumber(5);

    document.body.appendChild(hiddenAnchor);
    hiddenAnchor.click();
    document.body.removeChild(hiddenAnchor);

    setDoneProgressNumber(6);
  };

  const buildXlsxReport = (correlatedMlpData: MlpByMsisdn[], eSmlcEvents: CorrelatedESmlcRecord[]): WorkBook => {
    const correlationGroups: CorrelationGroup[] = [];

    for (const corrData of correlatedMlpData) {
      const corrDataGroup = [...corrData.values()].reduce<CorrelationGroup[]>(
        (groups, mlps) => [...groups, ...mlps.map((mlp) => new CorrelationGroup(mlp))],
        []
      );

      correlationGroups.push(...corrDataGroup);
    }

    setUsedInReportAndIsStaticFieldsForReport(correlationGroups);

    const jobDefinition = {
      horizontalAccuracyThreshold_Meters: window.appSettings.horizontalAccuracyThreshold_Meters,
      horizontalAccuracyPercentile1: window.appSettings.horizontalAccuracyPercentile1,
      horizontalAccuracyPercentile2: window.appSettings.horizontalAccuracyPercentile2,
      latencyPercentile1: window.appSettings.latencyPercentile1,
      latencyPercentile2: window.appSettings.latencyPercentile2,
      verticalAccuracyThreshold_Meters: window.appSettings.verticalAccuracyThreshold_Meters,
      verticalAccuracyPercentile1: window.appSettings.verticalAccuracyPercentile1,
      verticalAccuracyPercentile2: window.appSettings.verticalAccuracyPercentile2,
    };

    const workbook = NewReportGenerator.build(correlationGroups, jobDefinition, eSmlcEvents);

    return workbook;
  };

  const handleLoadReportDefinition = (
    reportDefinition: ReportDefinition,
    oldFormData?: ReportFormData,
    resetForm?: (nextState?: Partial<FormikState<ReportFormData>> | undefined) => void
  ) => {
    const formData: ReportFormData = JSON.parse(reportDefinition.formJson, function (key, value) {
      if (key === "customStartDate" || key === "customEndDate") {
        // NOTE: `this` contains the object being processed.
        // (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#the_reviver_parameter)
        const timeZone = getTimeZoneIdentifierFromAbbreviation(this.timeZone);
        return utcISOToJsDateInTimeZone(value, timeZone);
      } else if (key === "staticPointName") {
        // Clear the staticPointName from each batch set. We don't want to load this data from the server.
        return "";
      } else {
        return value;
      }
    });

    let touched: any = { batchSets: [] };

    for (let i = 0; i < formData.batchSets.length; i++) {
      const batchSet = formData.batchSets[i];
      if (
        batchSet.groundTruthData.groundTruth === GroundTruthType.GpsLogger &&
        batchSet.groundTruthData.gpsLoggerFile?.path
      ) {
        touched.batchSets[i] = {
          groundTruthData: {
            gpsLoggerFile: true,
          },
        };
      }
    }

    if (oldFormData) {
      for (let i = 0; i < oldFormData.batchSets.length; i++) {
        formData.batchSets[i].groundTruthData.gpsLoggerFile = oldFormData.batchSets[i].groundTruthData.gpsLoggerFile;
        formData.batchSets[i].groundTruthData.staticPointName =
          oldFormData.batchSets[i].groundTruthData.staticPointName;
      }
    }

    if (resetForm) {
      resetForm();
    }

    setInitialFormData(formData);
    setInitialFormTouched(touched);
    setReportDefinition(reportDefinition);
  };

  const handleSaveReportDefinition = async (reportName: string, formData: ReportFormData) => {
    const formJson = JSON.stringify(formData);
    const reportDefinition = new ReportDefinition(reportName, formJson);

    const response = await saveReportDefinition(reportDefinition);

    if (response.error) {
      console.error(response.error);
      return "Could not save report.";
    } else {
      handleLoadReportDefinition(response.data!, formData);
    }
  };

  const handleUpdateReportDefinition = async (summary: ReportDefinitionSummary, formData: ReportFormData) => {
    const formJson = JSON.stringify(formData);
    const reportDefinition = new ReportDefinition(summary.name, formJson, summary.id);

    const response = await updateReportDefinition(reportDefinition);

    if (response.error) {
      console.error(response.error);
      return "Could not save report.";
    } else {
      handleLoadReportDefinition(response.data!, formData);
    }
  };

  const validateForm = async (values: ReportFormData) => {
    try {
      // Pass the form data to Yup via the context option. This function will
      // throw an error if there are validation errors.
      await validateYupSchema(values, reportValidationSchema(allowListMsisdns.map((m) => m.value)), false, values);
    } catch (err) {
      return yupToFormErrors<ReportFormData>(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFormData}
        initialTouched={initialFormTouched}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ errors, touched, values, setFieldValue, resetForm }) => {
          const isDirty = JSON.stringify(values) !== JSON.stringify(initialFormData);

          return (
            <>
              {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}

              <SaveLoadReportForm
                isDirty={isDirty}
                initialReportName={reportDefinition?.name ?? ""}
                onLoad={(reportDef) => handleLoadReportDefinition(reportDef, undefined, resetForm)}
                onSave={(name) => handleSaveReportDefinition(name, values)}
                onUpdate={(name) => handleUpdateReportDefinition(name, values)}
              />

              <Form>
                <div id="reportContainer">
                  <div className="mb-3 d-flex">
                    <div>
                      <div id="modeLabel" className="mb-1">
                        <label htmlFor="modeOption">
                          <strong>Mode</strong>
                        </label>
                      </div>

                      <Field as={FormSelect} name="modeOption" isInvalid={errors.modeOption && touched.modeOption}>
                        <option value="">Select...</option>
                        {modeOptions.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Field>

                      {errors.modeOption && touched.modeOption && (
                        <div className="text-danger small">
                          <ErrorMessage name="modeOption" />
                        </div>
                      )}
                    </div>
                  </div>

                  {permissions?.apiPermissions?.includes(apiPermissions.LCAT_ESMLC) && (
                    <div id="eSMLCContainer" className="mb-3">
                      <div id="eSMLCLabel">
                        <label htmlFor="eSMLC" className="mb-1">
                          <strong>eSMLC</strong>
                        </label>
                      </div>
                      <Field type="checkbox" name="eSMLC" id="eSMLC" />
                      <span className="ms-2">Enable eSMLC Data</span>
                    </div>
                  )}

                  <Alert variant="warning" show={reportDefinition?.msisdnsRemoved || false}>
                    <FontAwesomeIcon icon="exclamation-circle" />{" "}
                    {reportDefinition?.owner === currentUser?.localAccountId
                      ? "MSISDNs not on your allow list have been removed."
                      : "MSISDNs not on your allow list and batches that contain no MSISDNs on your allow list have been removed."}
                  </Alert>

                  <FieldArray name="batchSets">
                    {({ push, remove }) => (
                      <BatchTab
                        tabs={values.batchSets.map((b: BatchSet, i: number) => ({
                          title: `Batch ${i + 1}`,
                          key: b.key,
                          data: b,
                          hasError: tabHasError(errors, touched, values, i),
                          isActive: b.key === activeTab,
                        }))}
                        onTabClick={(key: string) => {
                          setActiveTab(key);
                        }}
                        onAdd={(e) => {
                          const newBatchSet = new BatchSet(userTimeZoneIdentifier);
                          push(newBatchSet);
                          setActiveTab(newBatchSet.key);
                        }}
                        onDelete={(tab: TabConfig<BatchSet>, index: number) => {
                          remove(index);
                          validateForm(values);

                          if (activeTab === tab.key) {
                            //need to set the active tab one above this
                            const tabIndex = values.batchSets.findIndex((s: BatchSet) => s.key === tab.key);
                            const nextTabIndex = tabIndex === 0 ? tabIndex + 1 : tabIndex - 1;
                            setActiveTab(values.batchSets[nextTabIndex].key);
                          }
                        }}
                        render={(tab: TabConfig<BatchSet>, i: number) => {
                          return (
                            <BatchTabContent
                              allowListMsisdns={allowListMsisdns}
                              batchSetFieldName={`batchSets[${i}]`}
                              tabKey={tab.key}
                            />
                          );
                        }}
                      />
                    )}
                  </FieldArray>

                  {values.modeOption !== "" && (
                    <div className="my-4">
                      <label>
                        <strong>Mode:</strong>
                      </label>
                      <ModeOptionChosen>{`${values.modeOption === ModeOption.E911 ? values.modeOption : values.modeOption.toUpperCase()
                        }`}</ModeOptionChosen>
                    </div>
                  )}

                  {values.eSMLC &&
                    values.batchSets &&
                    !values.batchSets.find((b: BatchSet) => b.groundTruthData.groundTruth !== GroundTruthType.None) && (
                      <GroundTruthError>Ground Truth is required when eSMLC is selected</GroundTruthError>
                    )}

                  {permissions?.apiPermissions?.includes(apiPermissions.LCAT_GETXML) && (
                    <div id="rawCallLinksContainer" className="mb-3">
                      <div id="rawCallLinksLabel">
                        <label htmlFor="rawCallLinks" className="mb-1">
                          <strong>Raw Call Links</strong>
                        </label>
                      </div>
                      <Field type="checkbox" name="rawCallLinks" id="rawCallLinks" />
                      <span className="ms-2">Include in report</span>
                    </div>
                  )}

                  <div>
                    <div className="mt-4">
                      <SpinnerButton
                        type="submit"
                        size="sm"
                        disabled={isDisabled || values.eSMLC}
                        isSpinning={runningRawData}
                        onClick={() => {
                          setFieldValue("reportType", ReportType.Raw);
                          setDoneProgressNumber(0);
                        }}
                      >
                        <FontAwesomeIcon icon={icon({ name: "download" })} className="me-1" /> Raw Data (.csv)
                      </SpinnerButton>

                      <SpinnerButton
                        type="submit"
                        size="sm"
                        className="ms-3"
                        disabled={
                          isDisabled ||
                          !values.batchSets.find(
                            (b: BatchSet) => b.groundTruthData.groundTruth !== GroundTruthType.None
                          )
                        }
                        isSpinning={runningExcelReport}
                        onClick={() => {
                          setFieldValue("reportType", ReportType.Excel);
                          setDoneProgressNumber(0);
                        }}
                      >
                        <FontAwesomeIcon icon={icon({ name: "download" })} className="me-1" /> Report with Raw Data
                        (.xlsx)
                      </SpinnerButton>
                    </div>
                  </div>
                </div>
                {/*FOR DEBUGGING */}
                {/* <pre>{JSON.stringify({ values, errors, touched }, null, 2)}</pre> */}
              </Form>
            </>
          );
        }}
      </Formik>

      <ProgressModal
        show={showProgressModal}
        numberOfEvents={numberOfEvents}
        numberOfFilteredEvents={numberOfFilteredEvents}
        numberOfGPSLocations={numberOfGPSLocations}
        numberOfLocations={numberOfLocations}
        numberOfESmlcCalls={numberOfESmlcCalls}
        retrieveESmlcRecords={retrieveESmlcRecords}
        exportJobIds={exportJobIds}
        doneProgressNumber={doneProgressNumber}
        doneProgressTime={doneProgressTime}
        onModalClose={handleAddModalCancel}
        doCancel={handleCancel}
        errorMessage={progressModalErrorMessage}
        batchSetsProgress={batchSetsProgress}
        gpsFileProgress={gpsFileProgress}
      />
    </>
  );
};

export default Report;
