import ESmlcCallExport from "./ESmlcCallExport";
import { eSmlcCallsReportHeader } from "./ESmlcHelpers";
import Worksheet from "./Worksheet";

export default class ESmlcCallsWorksheet extends Worksheet {
  private _nextRow: number;

  public get hasData() {
    return this._nextRow > 2;
  }

  constructor() {
    super();
    this.writeHeaders();
    this._nextRow = 1;
  }

  public writeData = (c: ESmlcCallExport): void => {
    const values = [
      [
        c.callId,
        c.msisdn,
        c.referenceId,
        c.posRequestTime,
        c.posResponseTime,
        c.radioAccessNetwork,
        c.cid,
        c.clientType,
        c.imsi,
        c.imei,
        c.requestedHorizontalAccuracy,
        c.requestedResponseTime,
        c.agpScapability,
        c.lpPsupport,
        c.rxTXTDSupport,
        c.gpsStandalone,
        c.dbhSupport,
        c.wlanSupport,
        c.ubpsupport,
        c.responseDataType,
        c.ubp,
        c.ca_Address,
        c.ca_Method,
        c.ca_Latitude,
        c.ca_Longitude,
        c.country,
        c.a1,
        c.a2,
        c.a3,
        c.rd,
        c.hno,
        c.pc,
        c.ca_Token,
        c.dbhCaError,
        c.gnssCaError,
        c.rawCallLink
      ],
    ];

    this.setCells(this._nextRow, 0, values);

    if (c.posRequestTime) {
      this.setCellAsDateFormat(this._nextRow, 3); // col d, posRequestTime
    }

    if (c.posResponseTime) {
      this.setCellAsDateFormat(this._nextRow, 4); // col e, posResponseTime
    }
    
    if (c.rawCallLink) {
      this.setCellAsHyperLink(this._nextRow, 35); // col aj, rawCallLink
    }

    for (let i = 0; i < values[0].length; i++) {
      this.setCellDefaultValueStyles(this._nextRow, i);
    }

    this._nextRow++;
  };

  private writeHeaders = (): void => {
    this.setCells(0, 0, [eSmlcCallsReportHeader]);

    for (let i = 0; i < eSmlcCallsReportHeader.length; i++) {
      this.setCellDefaultValueStyles(0, i);
    }

    this.setAutofilter("BI");
    this.setESmlcCallsColumnWidths();
  };
}
