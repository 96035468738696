import { Formik } from "formik";
import GroundTruthGpsLogger from "../Report/GroundTruthGpsLogger";

const initialValues = {
  gpsLoggerFileFieldName: "",
};
const ManageData = () => {
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={() => {}} validate={() => {}}>
        {() => <GroundTruthGpsLogger name={"gpsLoggerFileFieldName"} />}
      </Formik>
    </>
  );
};

export default ManageData;
