export class EventRequest {
  msisdns: string[];
  startTimeUtc: string;
  endTimeUtc: string;
  mode: string;
  exportJobId: string;
  ESmlc: boolean;
  rawCallLinks: boolean;

  constructor(
    msisdns: string[],
    startTimeUtc: string,
    endTimeUtc: string,
    mode: string,
    exportJobId: string,
    eSmlc: boolean,
    rawCallLinks: boolean
  ) {
    this.msisdns = msisdns;
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
    this.mode = mode;
    this.exportJobId = exportJobId;
    this.ESmlc = eSmlc;
    this.rawCallLinks = rawCallLinks;
  }
}
