class CorrelationExport {
  public callId: number = -1;
  public transactionId: string | null = null;
  public msisdn: string | null = null;
  public imsi: string | null = null;
  public imei: string | null = null;
  public ranTech: string | null = null;
  public type: string | null = null;
  public ascertained: string | null = null;
  public active: string | null = null;
  public isiOS: string | null = null;
  public callStartTime: string | null = null;
  public accuracy: number | null = null;
  public vertical_accuracy: number | null = null;
  public longitude: number | null = null;
  public latitude: number | null = null;
  public shape: number | null = null;
  public extendedHorizontalUncertainty: string = "False";
  public extendedVerticalUncertainty: string = "False";
  public horizontalUncertainty?: number = undefined;
  public confidence?: number = undefined;
  public altitude?: number = undefined;
  public verticalUncertainty?: number = undefined;
  public latency: number | null = null;
  public status_val: number | null = null;
  public status_str: string | null = null;
  public isSuccessful: string | null = null;
  public cgi: string | null = null;
  public positionMethod: string | null = null;
  public ubp?: number = undefined;
  public floorNumber: string | null = null;
  public neadAddressType: string | null = null;
  public neadCounty: string | null = null;
  public neadHouseNumber: string | null = null;
  public neadFloor: string | null = null;
  public neadUnit: string | null = null;
  public neadRoad: string | null = null;
  public neadCity: string | null = null;
  public neadState: string | null = null;
  public neadPostalCode: string | null = null;
  public gpsUserName: string | null = null;
  public gpsLongitude: number = 0;
  public gpsLatitude: number = 0;
  public gpsAscertained: string | null = null;
  public gpsStatic: string | null = null;
  public gpsElevation: number = 0;
  public gpsSpeed: number = 0;
  public gpsSatelliteCount: number = 0;
  public gpsStatus: string | null = null;
  public gpsMorphology: string | null = null;
  public gpsHdop: number = 0;
  public gpsPdop: number = 0;
  public gpsVdop: number = 0;
  public selectedVertical: string | null = null;
  public usedInReport: string | null = null;
  public locationRequestTime: string | null = null;
  public isVersionSupported: string | null = null;
  public rawCallLink: string | null = null;
}

export default CorrelationExport;
