import { Field } from "formik";
import React, { Fragment } from "react";
import styled from "styled-components";

export type RadioButtonAndLabelFields = {
   rbId: string, rbValue: string, labelText: string 
};

type Props = {
  aria: string;
  buttonGroupId: string;
  buttonGroupName: string;
  options: RadioButtonAndLabelFields[];
};

const ButtonWrapper = styled.div`
  &.btn-group > input:checked + label {
    color: black;
    background-color: lightgray;
  }

 &.btn-group > label.btn {
    border-color: #dfdfdf;
  }
`;

const RadioButtonGroup = ({ aria, buttonGroupId, buttonGroupName, options }: Props) => {
  return (
    <>
      <ButtonWrapper id={buttonGroupId} className="btn-group" role="group" aria-label={`${aria} radio toggle button group`}>
        {options.map((option) => {
          return (
            <Fragment key= { option.rbId }>
              <Field type="radio" className="btn-check" name={buttonGroupName} id={`${option.rbId}_${buttonGroupId}`} autoComplete="off" value={option.rbValue} />
              <label className="btn btn-outline-secondary" htmlFor={`${option.rbId}_${buttonGroupId}`}>{option.labelText}</label>
            </Fragment>
          );
          } )
        }
      </ButtonWrapper>
    </>
  );
};

export default RadioButtonGroup;