import { TimeZoneValue } from "../../../utilities/timezone";

export class PartialMtlr {
  msisdn: string;
  startTimeUtc: string;
  endTimeUtc: string;
  timeZone: TimeZoneValue;

  constructor(partialMtlr: PartialMtlr) {
    this.msisdn = partialMtlr.msisdn;
    this.startTimeUtc = partialMtlr.startTimeUtc;
    this.endTimeUtc = partialMtlr.endTimeUtc;
    this.timeZone = partialMtlr.timeZone;
  }
}

class MtlrToReport {
  partialMtlr: PartialMtlr[] = [];
};

export default MtlrToReport;