import * as yup from "yup";
import { ValidationError } from "yup";
import { getPacificTimeAsLocalTime } from "../../../utilities/datetime";
import LineReader from "../../../utilities/LineReader";
import { calculateBatchSetDate, GroundTruthType, isGpsLoggerHeader, TOLERANCE_MS } from "./reportHelpers";
import { DateTimeOption } from "../shared/helpers";
import { BatchSet } from "../shared/BatchSet";
import { GpsLocation } from "./models/GpsLocation";
import { GpsLoggerFile } from "./models/GroundTruthData";

export const errorMessages = {
  required: (type: string) => `${type} is required.`,
  requiredWithValues: (type: string, minValue: number, maxValue: number) =>
    `${type} is required and must be a decimal number from ${minValue} to ${maxValue}.`,

  msisdnNotFound: (type: string) => `Msisdn ${type} not found. Please re-type.`,

  decimalNumber: (type: string, minValue: number, maxValue: number) =>
    `${type} must be a decimal number from ${minValue} to ${maxValue}.`,
  positiveNumber: (type: string) =>
    `${type} must be a positive number.`,
  date: {
    invalid: (type: string) => `Custom ${type} Time is not a valid date.`,
    beforeStartTime: "Custom End Time must be after Custom Start Time.",
  },
  selectRanFilter: "Select at least one RAN Technology or uncheck the filter.",
  selectCgiFilter: "Enter at least one term of the CGI or uncheck the filter.",
  lessThan: (thisValue: string, compareValue: string) => `${thisValue} must be less than ${compareValue} if both are specified.`,
};

const validateMsisdnDateOverlaps = (batchSets: BatchSet[]) => {
  const batchesInError = new Map();
  const dateNow = getPacificTimeAsLocalTime().toUTC();

  //for every batchSet need to compare the msisdns/dates
  for (let i = 0; i < batchSets.length; i++) {
    const batchUnderTest: BatchSet = batchSets[i];
    const batchUnderTestDates = calculateBatchSetDate(batchUnderTest, dateNow);

    const batches = batchSets.map((batch, i) => {
      if (batchUnderTest.key !== batch.key) {
        const intersection = batchUnderTest.msisdns.filter((m) => batch.msisdns.includes(m));

        if (intersection.length > 0) {
          const datesToCompare = calculateBatchSetDate(batch, dateNow);
          let hasError = false;

          if (
            batchUnderTestDates.startDate <= datesToCompare.startDate &&
            batchUnderTestDates.endDate >= datesToCompare.endDate
          ) {
            //validation date before compare start date but validation end date is after compare end date
            hasError = true;
          } else if (
            batchUnderTestDates.startDate >= datesToCompare.startDate &&
            batchUnderTestDates.startDate < datesToCompare.endDate
          ) {
            //validation date after compare start date but before compare end date
            hasError = true;
          } else if (
            batchUnderTestDates.startDate >= datesToCompare.startDate &&
            batchUnderTestDates.endDate <= datesToCompare.endDate
          ) {
            //validation date after/equal to compare start date and vd.endDate <= cd.endDate
            hasError = true;
          }

          if (hasError) {
            return `Batch ${i + 1} contains overlapping time ranges for MSISDNs: ${intersection.join(", ")}`;
          }

          return null;
        }
      }

      return null;
    });

    //remove the batches that did not have errors
    const errorBatches = batches.filter((x) => x !== null);

    if (errorBatches !== null && errorBatches.length > 0) {
      //  put all the error messages for a batch together. output will be:
      //    [0, ["Batch n contains overlapping...", "Batch n contains overlapping..."]]
      if (batchesInError.has(i)) {
        //update the batch
        const previousError = batchesInError.get(i);
        batchesInError.set(i, [...previousError, ...errorBatches]);
      } else {
        //insert the batch
        batchesInError.set(i, [...errorBatches]);
      }
    }
  }

  return batchesInError;
};

const isFileValid = async (msisdns: string[], startDate: number, endDate: number, gpsLoggerFile: GpsLoggerFile) => {
  const lineReader = new LineReader(gpsLoggerFile as File);
  let gps: GpsLocation;

  try {
    for await (let line of lineReader.forEachLine()) {
      try {
        // skip header and blank lines
        if (!line || isGpsLoggerHeader(line)) {
          continue;
        }

        gps = GpsLocation.parse(line, true);

        let startDateBeforeGps = startDate <= gps.truncatedAscertained_ms;
        let endDateWithToleranceAfterGps = endDate + TOLERANCE_MS >= gps.truncatedAscertained_ms;

        if (startDateBeforeGps && endDateWithToleranceAfterGps) {
          for (const msisdn of msisdns) {
            if (msisdn === gps.msisdn) {
              return "";
            }
          }
        }
      } catch {
        //ignore failures
      }
    }
  } catch {
    if (gpsLoggerFile?.path !== undefined && !gpsLoggerFile?.size) {
      return `This report was created using a GPS Logger File named "${gpsLoggerFile.path}". Select a GPS Logger File to be used for this report."`;
    } else {
      //one way to cause this error is drop/select a file. Run a report. Edit that file. Run a report.
      //if the file is not dropped/selected again there is a network error thrown.
      return "Error reading GPSLogger file, please drop or upload the GPSLogger file again.";
    }
  }

  return "No records in this GPSLogger file matches the selected MSISDNs and date/time range.";
};

const reportValidationSchema = (availableMsisdns: string[]) => {
  const buildValidationSchema = yup.object().shape({
    batchSets: yup
      .array()
      .test("no-msisdn-date-overlap", "msisdns-overlap-error", (batchSets, context) => {
        if (batchSets === undefined || batchSets.length === 1) {
          return true;
        }

        const batchesInError = validateMsisdnDateOverlaps(batchSets);

        if (batchesInError.size > 0) {
          let messages: (ValidationError | null)[] = [];

          for (let [key, value] of batchesInError) {
            //for every batch tab that has an error, join all the error msgs into a ValidationError
            const errorMessage = new ValidationError(
              value.join("\n\r"),
              `batchSets[${key}].msisdns`,
              `batchSets[${key}].msisdns`
            );

            messages = [...messages, errorMessage];
          }

          if (messages !== undefined && messages.length > 0) {
            return context.createError({
              message: () => messages,
            });
          }
        }

        return true;
      })
      .test("gpsLogger-File-Validate", "logger-file-error", async (batchSets, context) => {
        if (batchSets === undefined) {
          return true;
        }

        let messages: (ValidationError | null)[] = [];
        const dateNow = getPacificTimeAsLocalTime().toUTC();

        for (let i = 0; i < batchSets.length; i++) {
          if (batchSets[i].groundTruthData.groundTruth === GroundTruthType.GpsLogger) {
            const batchTestDates = calculateBatchSetDate(batchSets[i], dateNow);

            const fileValidationMessage = await isFileValid(
              batchSets[i].msisdns,
              batchTestDates.startDateForGpsFileValidation.toMillis(),
              batchTestDates.endDateForGpsFileValidation.toMillis(),
              batchSets[i].groundTruthData.gpsLoggerFile as File
            );

            if (fileValidationMessage.length > 0) {
              const errorMessage = new ValidationError(
                fileValidationMessage,
                `batchSets[${i}].groundTruthData.gpsLoggerFile`,
                `batchSets[${i}].groundTruthData.gpsLoggerFile`
              );

              messages = [...messages, errorMessage];
            }
          }
        }

        if (messages !== undefined && messages.length > 0) {
          return context.createError({
            message: () => messages,
          });
        }

        return true;
      })
      .of(
        yup.object().shape({
          msisdns: yup
            .array()
            .min(1, errorMessages.required("Msisdn"))
            .of(
              yup
                .string()
                .required(errorMessages.required("Msisdn"))
                .test(
                  "is-msisdn-in-list",
                  (val) => errorMessages.msisdnNotFound(val.value),
                  (val) => val !== undefined && availableMsisdns.includes(val)
                )
            ),

          //the custom dates only need to be validated if the dateTimeOption=customDateTime
          customStartDate: yup
            .date()
            .nullable()
            .when("dateTimeOption", {
              is: DateTimeOption.CUSTOM_DATE_TIME,
              then: (schema) =>
                schema
                  .typeError(errorMessages.required("Custom Start Time"))
                  .required(errorMessages.required("Custom Start Time")),
            }),

          customEndDate: yup
            .date()
            .nullable()
            .when("dateTimeOption", {
              is: DateTimeOption.CUSTOM_DATE_TIME,
              then: (schema) =>
                schema
                  .typeError(errorMessages.required("Custom End Time"))
                  .required(errorMessages.required("Custom End Time"))
                  .min(yup.ref("customStartDate"), errorMessages.date.beforeStartTime),
            }),

          groundTruthData: yup.object().shape({
            //if GroundTruthType.Static then need to validate latitude, longitude, elevation and morphology
            latitude: yup.mixed().test("latitude-Validate", "latitude-error", (value, context) => {
              const groundTruthValues = context.parent;

              if (groundTruthValues.groundTruth === GroundTruthType.Static) {
                if (value === undefined || value === "") {
                  return context.createError({
                    message: errorMessages.requiredWithValues("Latitude", 0, 90),
                  });
                } else {
                  const latitude = Number(value);

                  if (isNaN(latitude)) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Latitude", 0, 90),
                    });
                  }

                  if (latitude < 0 || latitude > 90) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Latitude", 0, 90),
                    });
                  }
                }
              }

              return true;
            }),

            longitude: yup.mixed().test("longitude-Validate", "longitude-error", (value, context) => {
              const groundTruthValues = context.parent;

              if (groundTruthValues.groundTruth === GroundTruthType.Static) {
                if (value === undefined || value === "") {
                  return context.createError({
                    message: errorMessages.requiredWithValues("Longitude", -180, 0),
                  });
                } else {
                  const longitude = Number(value);

                  if (isNaN(longitude)) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Longitude", -180, 0),
                    });
                  }

                  if (longitude < -180 || longitude > 0) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Longitude", -180, 0),
                    });
                  }
                }
              }

              return true;
            }),

            elevation: yup.mixed().test("elevation-Validate", "elevation-error", (value, context) => {
              const groundTruthValues = context.parent;

              if (groundTruthValues.groundTruth === GroundTruthType.Static) {
                if (value === undefined || value === "") {
                  return context.createError({
                    message: errorMessages.requiredWithValues("Elevation", -500, 10000),
                  });
                } else {
                  const elevation = Number(value);

                  if (isNaN(elevation)) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Elevation", -500, 10000),
                    });
                  }

                  if (elevation < -500 || elevation > 10000) {
                    return context.createError({
                      message: errorMessages.decimalNumber("Elevation", -500, 10000),
                    });
                  }
                }
              }

              return true;
            }),

            morphology: yup.string().when("groundTruth", {
              is: (val: string) => val === GroundTruthType.Static,
              then: (schema) => schema.required(errorMessages.required("Morphology")),
            }),

            //if GroundTruthType.GpsLogger then need to validate gpsLoggerFile
            gpsLoggerFile: yup
              .mixed()
              .nullable()
              .when("groundTruth", {
                is: (val: string) => val === GroundTruthType.GpsLogger,
                then: (schema) => schema.required(errorMessages.required("GpsLoggerFile")),
              }),
          }),

          advancedFilters: yup.object().shape({

            useRanFilters: yup
              .boolean()
              .test("ranFilter-validation", "ranFilter-error", (value, context) => {
                const ranFilterContext = context.parent;

                if (value && (
                  !ranFilterContext.ran2G &&
                  !ranFilterContext.ran4G &&
                  !ranFilterContext.ran5G)
                ) {
                  return context.createError({
                    message: errorMessages.selectRanFilter,
                  });
                }

                return true;
              }),

            useCgiFilter: yup
              .boolean()
              .test("cgiFilter-validation", "cgiFilter-error", (value, context) => {
                const cgiFilterContext = context.parent;

                if (value && (
                  cgiFilterContext.cgi1.length === 0 &&
                  cgiFilterContext.cgi2.length === 0 &&
                  cgiFilterContext.cgi3.length === 0 &&
                  cgiFilterContext.cgi4.length === 0)
                ) {
                  return context.createError({
                    message: errorMessages.selectCgiFilter,
                  });
                }

                return true;
              }),

            longitude: yup
              .mixed()
              .test("geographicLongitude-Validate", "longitude-error", (value, context) => {
                const geographicFilterContext = context.parent;

                if (geographicFilterContext.useGeographicFilter) {
                  if (value === undefined || value === "") {
                    return context.createError({
                      message: errorMessages.requiredWithValues("Longitude", -180, 0),
                    });
                  } else {
                    const longitude = Number(value);

                    if (isNaN(longitude) || (longitude < -180 || longitude > 0)) {
                      return context.createError({
                        message: errorMessages.decimalNumber("Longitude", -180, 0),
                      });
                    }
                  }
                }

                return true;
              }),

            latitude: yup
              .mixed()
              .test("geographicLatitude-Validate", "geographicLatitude-error", (value, context) => {
                const geographicFilterContext = context.parent;

                if (geographicFilterContext.useGeographicFilter) {
                  if (value === undefined || value === "") {
                    return context.createError({
                      message: errorMessages.requiredWithValues("Latitude", 0, 90),
                    });
                  } else {
                    const latitude = Number(value);

                    if (isNaN(latitude) || (latitude < 0 || latitude > 90)) {
                      return context.createError({
                        message: errorMessages.decimalNumber("Latitude", 0, 90),
                      });
                    }
                  }
                }

                return true;
              }),

            radius: yup
              .mixed()
              .test("geographicRadius-Validate", "geographicRadius-error", (value, context) => {
                const geographicFilterContext = context.parent;

                if (geographicFilterContext.useGeographicFilter) {
                  if (value === undefined || value === "") {
                    return context.createError({
                      message: errorMessages.required("Radius"),
                    });
                  } else {
                    const radius = Number(value);

                    if (isNaN(radius) || radius <= 0) {
                      return context.createError({
                        message: errorMessages.positiveNumber("Radius"),
                      });
                    }
                  }
                }

                return true;
              }),

            minAltitude: yup
              .mixed()
              .test("geographicMinAltitude-Validate", "geographicMinAltitude-error", (value, context) => {
                const geographicFilterContext = context.parent;

                if (geographicFilterContext.useGeographicFilter) {
                  if (value && geographicFilterContext.maxAltitude) {
                    const minAltitude = Number(value);
                    const maxAltitude = Number(geographicFilterContext.maxAltitude);

                    if (isNaN(minAltitude) || isNaN(maxAltitude) || minAltitude >= maxAltitude) {
                      return context.createError({
                        message: errorMessages.lessThan("Min Altitude", "Max Altitude"),
                      });
                    }
                  }
                }

                return true;
              }),

            //THIS Validation returns an empty string because it is the same error when MinAltitude is also present and invalid
            //We don't need 2 errors showing, but we do want both inputs to highlight in red. This handles that scenario
            maxAltitude: yup
              .mixed()
              .test("geographicMaxAltitude-Validate", "geographicMaxAltitude-error", (value, context) => {
                const geographicFilterContext = context.parent;

                if (geographicFilterContext.useGeographicFilter) {
                  if (value && geographicFilterContext.minAltitude) {
                    const maxAltitude = Number(value);
                    const minAltitude = Number(geographicFilterContext.minAltitude);

                    if (isNaN(minAltitude) || isNaN(maxAltitude) || minAltitude >= maxAltitude) {
                      return context.createError({
                        message: "",
                      });
                    }
                  }
                }

                return true;
              }),
          })
        })
      ),

    modeOption: yup.string().required(errorMessages.required("Mode")),
  });

  return buildValidationSchema;
};

export default reportValidationSchema;
