import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICellRendererParams } from "ag-grid-community";
import classNames from "classnames";
import { ErrorMessage, Field, useField } from "formik";
import { MouseEvent, useMemo, useState } from "react";
import { Button, Col, FormLabel, Nav, Row, Tab } from "react-bootstrap";
import styled from "styled-components";
import { StaticPoint } from "../../../services/models/StaticPoint";
import { timeZoneOptions } from "../../../utilities/constants";
import { TimeZoneValue } from "../../../utilities/timezone";
import Dropdown from "../shared/Dropdown";
import MsisdnPicker, { OptionType } from "../shared/MsisdnPicker";
import NumberInput from "../shared/NumberInput";
import RadioButtonGroup, { RadioButtonAndLabelFields } from "../shared/RadioButtonGroup";
import TooltipIcon from "../shared/TooltipIcon";
import { DateTimeOption, customMultiSelectStyles } from "../shared/helpers";
import "./BatchTabContent.scss";
import CustomDateTime from "./CustomDateTime";
import GroundTruthGpsLogger from "./GroundTruthGpsLogger";
import GroundTruthStatic from "./GroundTruthStatic";
import { SavedGpsDataGrid } from "./SavedGpsDataGrid/SavedGpsDataGrid";
import { StaticPointsModal } from "./StaticPoints/StaticPointsModal";
import { GroundTruthType, getBatchSetEndDate, getBatchSetStartDate } from "./reportHelpers";

export const customSelectStyles = (error: boolean, width: number) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
      width: `${width}px`,
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
    /* styles for menu which is shown when the dropdown is clicked */
    menu: (provided: any) => ({
      ...provided,
    }),
  };
};

type Props = {
  allowListMsisdns: OptionType[];
  tabKey: string;
  batchSetFieldName: string;
};

const NavItem = styled(Nav.Item)`
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: #dfdfdf;
  cursor: pointer;
`;

const ErrorMessageSpan = styled.span`
  white-space: pre-line;
`;

const StyledFormLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
`;

const LabelSpan = styled.span`
  padding-right: 0.25vw;
  font-weight: bold;
`;

const AdvancedFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: calc(1vw + 13px);
  align-items: center;
  grid-column-gap: 9px;
`;

const CheckboxInputGroup = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1vw;
`;

const InputLabel = styled.label`
  padding-left: 0.75vw;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
`;

const DateContainer = styled.div`
  display: flex;
`;

const dateTimeButtonOptions: RadioButtonAndLabelFields[] = [
  {
    rbId: DateTimeOption.LAST_FOUR_HOURS,
    rbValue: DateTimeOption.LAST_FOUR_HOURS,
    labelText: "Last 4 Hours",
  },
  {
    rbId: DateTimeOption.LAST_EIGHT_HOURS,
    rbValue: DateTimeOption.LAST_EIGHT_HOURS,
    labelText: "Last 8 Hours",
  },
  {
    rbId: DateTimeOption.LAST_TWENTY_FOUR_HOURS,
    rbValue: DateTimeOption.LAST_TWENTY_FOUR_HOURS,
    labelText: "Last 24 Hours",
  },
  {
    rbId: DateTimeOption.LAST_SEVEN_DAYS,
    rbValue: DateTimeOption.LAST_SEVEN_DAYS,
    labelText: "Last 7 days",
  },
  {
    rbId: DateTimeOption.LAST_THIRTY_DAYS,
    rbValue: DateTimeOption.LAST_THIRTY_DAYS,
    labelText: "Last 30 days",
  },
  {
    rbId: DateTimeOption.CUSTOM_DATE_TIME,
    rbValue: DateTimeOption.CUSTOM_DATE_TIME,
    labelText: "Custom",
  },
];

const angleUpIcon = icon({ name: "angle-up" });
const angleDownIcon = icon({ name: "angle-down" });

const BatchTabContent = ({ allowListMsisdns, tabKey, batchSetFieldName }: Props) => {
  const msisdnsFieldName = `${batchSetFieldName}.msisdns`;
  const dateTimeOptionFieldName = `${batchSetFieldName}.dateTimeOption`;
  const customStartDateFieldName = `${batchSetFieldName}.customStartDate`;
  const customEndDateFieldName = `${batchSetFieldName}.customEndDate`;
  const groundTruthFieldName = `${batchSetFieldName}.groundTruthData.groundTruth`;
  const latitudeFieldName = `${batchSetFieldName}.groundTruthData.latitude`;
  const longitudeFieldName = `${batchSetFieldName}.groundTruthData.longitude`;
  const elevationFieldName = `${batchSetFieldName}.groundTruthData.elevation`;
  const morphologyFieldName = `${batchSetFieldName}.groundTruthData.morphology`;
  const gpsLoggerFileFieldName = `${batchSetFieldName}.groundTruthData.gpsLoggerFile`;
  const staticPointFieldName = `${batchSetFieldName}.groundTruthData.staticPointName`;
  const timeZoneFieldName = `${batchSetFieldName}.timeZone`;
  const useRanFieldName = `${batchSetFieldName}.advancedFilters.useRanFilters`;
  const ran2GFieldName = `${batchSetFieldName}.advancedFilters.ran2G`;
  const ran4GFieldName = `${batchSetFieldName}.advancedFilters.ran4G`;
  const ran5GFieldName = `${batchSetFieldName}.advancedFilters.ran5G`;
  const useCgiFieldName = `${batchSetFieldName}.advancedFilters.useCgiFilter`;
  const cgi1FieldName = `${batchSetFieldName}.advancedFilters.cgi1`;
  const cgi2FieldName = `${batchSetFieldName}.advancedFilters.cgi2`;
  const cgi3FieldName = `${batchSetFieldName}.advancedFilters.cgi3`;
  const cgi4FieldName = `${batchSetFieldName}.advancedFilters.cgi4`;
  const useGeographicFieldName = `${batchSetFieldName}.advancedFilters.useGeographicFilter`;
  const geographicLatitudeFieldName = `${batchSetFieldName}.advancedFilters.latitude`;
  const geographicLongitudeFieldName = `${batchSetFieldName}.advancedFilters.longitude`;
  const geographicRadiusFieldName = `${batchSetFieldName}.advancedFilters.radius`;
  const geographicMinAltitudeFieldName = `${batchSetFieldName}.advancedFilters.minAltitude`;
  const geographicMaxAltitudeFieldName = `${batchSetFieldName}.advancedFilters.maxAltitude`;

  const [dateTimeOptionField] = useField(dateTimeOptionFieldName);
  const [isAdvancedFiltersExpanded, setIsAdvancedFiltersExpanded] = useState(false);
  const [showStaticPointsModal, setShowStaticPointsModal] = useState<boolean>(false);
  const [timeZoneField, timeZoneMeta] = useField<TimeZoneValue>(timeZoneFieldName);
  const [msisdnsField, msisdnsMeta] = useField<string[]>(msisdnsFieldName);
  const [customStartDateField, customStartDateMeta] = useField<Date>(customStartDateFieldName);
  const [customEndDateField, customEndDateMeta] = useField<Date>(customEndDateFieldName);
  const [groundTruthField, , groundTruthHelpers] = useField<GroundTruthType>(groundTruthFieldName);
  const [useRanField, useRanFieldMeta, useRanFieldHelpers] = useField(useRanFieldName);
  const [ran2GField, , useRan2GFieldHelpers] = useField(ran2GFieldName);
  const [ran4GField, , useRan4GFieldHelpers] = useField(ran4GFieldName);
  const [ran5GField, , useRan5GFieldHelpers] = useField(ran5GFieldName);
  const [useCgiField, useCgiFieldMeta, useCgiFieldHelpers] = useField(useCgiFieldName);
  const [cgi3Field, ,] = useField(cgi3FieldName);
  const [cgi4Field, ,] = useField(cgi4FieldName);
  const [useGeographicField, useGeographicFieldMeta, useGeographicFieldHelpers] = useField(useGeographicFieldName);
  const [, geographicLongitudeFieldMeta, geographicLongitudeHelpers] = useField(geographicLongitudeFieldName);
  const [, geographicLatitudeFieldMeta, geographicLatitudeHelpers] = useField(geographicLatitudeFieldName);
  const [, geographicRadiusFieldMeta] = useField(geographicRadiusFieldName);
  const [, geographicMinAltitudeFieldMeta] = useField(geographicMinAltitudeFieldName);
  //We don't need to show MaxAltitude Errors because Min/Max errors will always be the same problem

  const msisdnTouchedHasError = msisdnsMeta.error && msisdnsMeta.touched ? true : false;
  const advancedFilterHasErrors: boolean =
    useRanFieldMeta.error || useCgiFieldMeta.error || useGeographicFieldMeta.error ? true : false;

  const dateTimeOption = dateTimeOptionField.value;
  const customStartDate = customStartDateField.value;
  const customEndDate = customEndDateField.value;
  const timeZone = timeZoneField.value;

  const startDateUtcString = useMemo<string>(
    () => getBatchSetStartDate(dateTimeOption, customStartDate, timeZone),
    [dateTimeOption, customStartDate, timeZone]
  );

  const endDateUtcString = useMemo<string>(
    () => getBatchSetEndDate(dateTimeOption, customEndDate, timeZone),
    [dateTimeOption, customEndDate, timeZone]
  );

  const errorMessages = [
    useRanFieldMeta.error,
    useCgiFieldMeta.error,
    useGeographicFieldMeta.error,
    geographicLatitudeFieldMeta.error,
    geographicLongitudeFieldMeta.error,
    geographicRadiusFieldMeta.error,
    geographicMinAltitudeFieldMeta.error,
  ];

  const handleLoadStaticPoint = async (e: MouseEvent<HTMLButtonElement>, params: ICellRendererParams<StaticPoint>) => {
    if (!params.data) {
      return;
    }

    //// populate static point fields - lat, long
    geographicLongitudeHelpers.setValue(params.data.longitude);
    geographicLatitudeHelpers.setValue(params.data.latitude);

    //// validate
    geographicLongitudeHelpers.setTouched(true);
    geographicLatitudeHelpers.setTouched(true);

    //// close modal
    setShowStaticPointsModal(false);
  };

  return (
    <div>
      <div id={`msisdnContainer_${tabKey}`} className="mb-3">
        <label htmlFor={`msisdns_${tabKey}`} className="mt-2 mb-1">
          <strong>Select MSISDNs</strong>
        </label>
        <MsisdnPicker
          id={`msisdns_${tabKey}`}
          name={msisdnsFieldName}
          options={allowListMsisdns}
          placeholder="Add MSISDN(s)..."
          styles={customMultiSelectStyles(msisdnTouchedHasError)}
        />
        {msisdnTouchedHasError && (
          <div className="text-danger small">
            <ErrorMessageSpan>{msisdnsMeta.error}</ErrorMessageSpan>
          </div>
        )}
      </div>

      <div className="mb-3">
        <div id={`dateTimeLabel_${tabKey}`} className="mb-1">
          <label htmlFor={tabKey}>
            <strong>Date and Time</strong>
          </label>
        </div>
        <RadioButtonGroup
          aria="dateTime"
          buttonGroupId={tabKey}
          buttonGroupName={dateTimeOptionFieldName}
          options={dateTimeButtonOptions}
        />
      </div>

      {dateTimeOptionField.value === DateTimeOption.CUSTOM_DATE_TIME && (
        <DateContainer className="mb-3">
          <div className="me-5">
            <div className="mb-1">
              <label htmlFor={`customStartDate_${tabKey}`}>Custom Start Time</label>
              <TooltipIcon
                content={
                  <span>
                    Calls <strong>at or after</strong> the Custom Start Time will be included in the report
                  </span>
                }
              />
            </div>
            <CustomDateTime id={`customStartDate_${tabKey}`} name={customStartDateFieldName} />
            {customStartDateMeta.error && customStartDateMeta.touched && (
              <div className="text-danger small">
                <ErrorMessage name={customStartDateFieldName} />
              </div>
            )}
          </div>
          <div>
            <div className="mb-1">
              <label htmlFor={`customEndDate_${tabKey}`}>Custom End Time</label>
              <TooltipIcon
                content={
                  <span>
                    Calls <strong>before</strong> the Custom End Time will be included in the report
                  </span>
                }
              />
            </div>
            <CustomDateTime id={`customEndDate_${tabKey}`} name={customEndDateFieldName} className="customEndDate" />
            {customEndDateMeta.error && customEndDateMeta.touched && (
              <div className="text-danger small">
                <ErrorMessage name={customEndDateFieldName} />
              </div>
            )}
          </div>

          <div className="mx-5">
            <div className="mb-1">
              <label>Time Zone</label>
            </div>
            <Dropdown
              id={`timeZone_${tabKey}`}
              name={timeZoneFieldName}
              options={timeZoneOptions}
              styles={customSelectStyles(timeZoneMeta.error && timeZoneMeta.touched ? true : false, 275)}
            />
            {timeZoneMeta.error && timeZoneMeta.touched && (
              <div className="text-danger small">
                <ErrorMessage name="timeZone" />
              </div>
            )}
          </div>
        </DateContainer>
      )}

      <div className="mb-3">
        <label className="mb-1" htmlFor={`ground-truth-tabs_${tabKey}`}>
          <strong>Ground Truth</strong>
        </label>
        <Tab.Container
          id={`ground-truth-tabs_${tabKey}`}
          activeKey={groundTruthField.value}
          onSelect={(eventKey: any) => {
            groundTruthHelpers.setValue(eventKey);
          }}
        >
          <Row className="ground-truth-row" id={`ground-truth-row_${tabKey}`} sm={11}>
            <Col className="ground-truth-col-nav" id={`ground-truth-col-nav_${tabKey}`} sm={2}>
              <Nav className="ground-truth-nav flex-column" id={`ground-truth-nav_${tabKey}`}>
                <NavItem>
                  <Nav.Link eventKey={GroundTruthType.SavedGPSData}>Saved GPS Data</Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey={GroundTruthType.GpsLogger}>GPS Logger File</Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey={GroundTruthType.Static}>Static Point</Nav.Link>
                </NavItem>
                <NavItem>
                  <Nav.Link eventKey={GroundTruthType.None}>No Ground Truth</Nav.Link>
                </NavItem>
              </Nav>
            </Col>

            <Col className="ground-truth-col-tab" id={`ground-truth-col-tab_${tabKey}`} sm={10}>
              <Tab.Content id={`ground-truth-tab-content_${tabKey}`}>
                <Tab.Pane eventKey={GroundTruthType.SavedGPSData}>
                  <div className="d-flex flex-row">
                    <SavedGpsDataGrid
                      timeZoneAbbreviation={timeZoneField.value}
                      msisdns={msisdnsField.value}
                      startTimeUtc={startDateUtcString}
                      endTimeUtc={endDateUtcString}
                    />
                    <TextContainer>
                      <p className="px-2">
                        Only stored data that match at least one MSISDN above <strong>and </strong>
                        overlap with the defined time range are shown.
                      </p>
                      <p className="px-2">Data may take 5-10 minutes after upload to be available for use.</p>
                      <p className="px-2">
                        If you expect to see data that are not in this table, double check the MSISDNs and time range or
                        refresh this table.
                      </p>
                    </TextContainer>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey={GroundTruthType.GpsLogger}>
                  <GroundTruthGpsLogger name={gpsLoggerFileFieldName} />
                </Tab.Pane>

                <Tab.Pane eventKey={GroundTruthType.Static}>
                  <GroundTruthStatic
                    latitudeFieldName={latitudeFieldName}
                    longitudeFieldName={longitudeFieldName}
                    elevationFieldName={elevationFieldName}
                    morphologyFieldName={morphologyFieldName}
                    staticPointFieldName={staticPointFieldName}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey={GroundTruthType.None}>
                  <p>
                    Select a Ground Truth to generate report calculations for this Batch. Batches with no Ground Truth
                    only appear in Raw Data.{" "}
                    <strong>At least one Batch must include a Ground Truth to download a Report.</strong>
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      <div>
        <StyledFormLabel
          className={classNames("AdvancedFiltersSection", "mb-1", {
            hasError: advancedFilterHasErrors,
          })}
          htmlFor={``}
          onClick={() => setIsAdvancedFiltersExpanded(!isAdvancedFiltersExpanded)}
        >
          <LabelSpan>Advanced Filters</LabelSpan>
          <FontAwesomeIcon icon={isAdvancedFiltersExpanded ? angleDownIcon : angleUpIcon} />
        </StyledFormLabel>

        {isAdvancedFiltersExpanded && (
          <div>
            <AdvancedFilterSection>
              <SectionLabel>
                <Field
                  type="checkbox"
                  name={useRanFieldName}
                  id="useRanFilters"
                  onClick={() => useRanFieldHelpers.setValue(!useRanField.value)}
                />
                <InputLabel htmlFor="useRanFilters">RAN Tech</InputLabel>
              </SectionLabel>

              <SectionInput>
                <CheckboxInputGroup>
                  <Field
                    type="checkbox"
                    name={ran2GFieldName}
                    id="ran2G"
                    disabled={!useRanField.value}
                    onClick={() => useRan2GFieldHelpers.setValue(!ran2GField.value)}
                  />
                  <InputLabel htmlFor="ran2G">2G/3G</InputLabel>
                </CheckboxInputGroup>
                <CheckboxInputGroup>
                  <Field
                    type="checkbox"
                    name={ran4GFieldName}
                    id="ran4G"
                    disabled={!useRanField.value}
                    onClick={() => useRan4GFieldHelpers.setValue(!ran4GField.value)}
                  />
                  <InputLabel htmlFor="ran4G">4G</InputLabel>
                </CheckboxInputGroup>
                <CheckboxInputGroup>
                  <Field
                    type="checkbox"
                    name={ran5GFieldName}
                    id="ran5G"
                    disabled={!useRanField.value}
                    onClick={() => useRan5GFieldHelpers.setValue(!ran5GField.value)}
                  />
                  <InputLabel htmlFor="ran5G">5G</InputLabel>
                </CheckboxInputGroup>
              </SectionInput>
            </AdvancedFilterSection>

            <AdvancedFilterSection>
              <SectionLabel>
                <Field
                  type="checkbox"
                  name={useCgiFieldName}
                  id="useCgiFilter"
                  onClick={() => useCgiFieldHelpers.setValue(!useCgiField.value)}
                />
                <InputLabel htmlFor="useCgiFilter">CGI</InputLabel>
              </SectionLabel>

              <SectionInput>
                <NumberInput
                  id={cgi1FieldName}
                  name={cgi1FieldName}
                  regex={/^[-.\d]*$/}
                  maxlength={3}
                  width={65}
                  isDisabled={!useCgiField.value}
                />
                -
                <NumberInput
                  id={cgi2FieldName}
                  name={cgi2FieldName}
                  regex={/^[-.\d]*$/}
                  maxlength={3}
                  width={65}
                  isDisabled={!useCgiField.value}
                />
                -
                <NumberInput
                  id={cgi3FieldName}
                  name={cgi3FieldName}
                  regex={/^[-.\d]*$/}
                  maxlength={10 - cgi4Field.value.length}
                  width={125}
                  isDisabled={!useCgiField.value}
                />
                -
                <NumberInput
                  id={cgi4FieldName}
                  name={cgi4FieldName}
                  regex={/^[-.\d]*$/}
                  maxlength={10 - cgi3Field.value.length}
                  width={125}
                  isDisabled={!useCgiField.value}
                />
              </SectionInput>
            </AdvancedFilterSection>

            <AdvancedFilterSection>
              <SectionLabel>
                <Field
                  type="checkbox"
                  name={useGeographicFieldName}
                  id="useGeographicFilter"
                  onClick={() => useGeographicFieldHelpers.setValue(!useGeographicField.value)}
                />
                <InputLabel htmlFor="useGeographicFilter">Geographic Cylinder</InputLabel>
              </SectionLabel>

              <SectionInput>
                {/* the below is throwing a compile error: "Expression produces a union type that is too complex to represent" */}
                {/* @ts-ignore */}
                <StyledButton
                  size="sm"
                  onClick={() => setShowStaticPointsModal(true)}
                  disabled={!useGeographicField.value}
                >
                  <FontAwesomeIcon icon={icon({ name: "file-export" })} className="me-2" />
                  <span>Load from Static Point</span>
                </StyledButton>
              </SectionInput>

              <SectionInput>
                <div>
                  <FormLabel htmlFor={geographicLatitudeFieldName}>Latitude</FormLabel>
                  <NumberInput
                    id={geographicLatitudeFieldName}
                    name={geographicLatitudeFieldName}
                    regex={/^[-.\d]*$/}
                    isDisabled={!useGeographicField.value}
                  />
                </div>

                <div>
                  <FormLabel htmlFor={geographicLongitudeFieldName}>Longitude</FormLabel>
                  <NumberInput
                    id={geographicLongitudeFieldName}
                    name={geographicLongitudeFieldName}
                    regex={/^[-.\d]*$/}
                    isDisabled={!useGeographicField.value}
                  />
                </div>

                <div>
                  <FormLabel htmlFor={geographicRadiusFieldName}>Radius (m)</FormLabel>
                  <NumberInput
                    id={geographicRadiusFieldName}
                    name={geographicRadiusFieldName}
                    regex={/^[.\d]*$/}
                    isDisabled={!useGeographicField.value}
                  />
                </div>

                <div>
                  <FormLabel htmlFor={geographicMinAltitudeFieldName}>Min Altitude (m)</FormLabel>
                  <NumberInput
                    id={geographicMinAltitudeFieldName}
                    name={geographicMinAltitudeFieldName}
                    regex={/^[-.\d]*$/}
                    isDisabled={!useGeographicField.value}
                  />
                </div>

                <div>
                  <FormLabel htmlFor={geographicMaxAltitudeFieldName}>Max Altitude (m)</FormLabel>
                  <NumberInput
                    id={geographicMaxAltitudeFieldName}
                    name={geographicMaxAltitudeFieldName}
                    regex={/^[-.\d]*$/}
                    isDisabled={!useGeographicField.value}
                  />
                </div>
              </SectionInput>
            </AdvancedFilterSection>
          </div>
        )}

        {errorMessages &&
          errorMessages.map((error, idx) => {
            if (error) {
              return (
                <div className="text-danger small" key={`batchTabErr-${idx}`}>
                  <ErrorMessageSpan>{error}</ErrorMessageSpan>
                </div>
              );
            }
            return "";
          })}

        <StaticPointsModal
          showModal={showStaticPointsModal}
          hideModal={() => setShowStaticPointsModal(false)}
          handleLoadStaticPoint={handleLoadStaticPoint}
          editableRows={false}
          enableDeleteRows={false}
        />
      </div>
    </div>
  );
};

export default BatchTabContent;
