import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { AgGridReact, CustomHeaderProps } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getGpsFileSummary, getGpsFiles } from "../../../../services/apiService";
import { GpsFileRequest } from "../../../../services/requests/GpsFileRequest";
import {
  TimeZoneValue,
  getTimeZoneGridDisplayFromAbbreviation,
  getTimeZoneIdentifierFromAbbreviation,
} from "../../../../utilities/timezone";
import { msisdnFormatter } from "../../Mtlr/mtlrHelpers";
import { numberFormatter } from "../../shared/formatters";
import { getCleanSummaryDataForGrid, getMapOfGpsFileSummaryByMsisdn } from "./savedGpsDataGridHelpers";

const StyledButton = styled.button`
  background: none;
  border: 0;
  padding: 0;

  &:hover:not([disabled]) {
    filter: brightness(85%);
  }

  color: #0d6efd;
`;

export type SavedGpsData = {
  msisdn: string;
  timeRange: string;
  gpsRecordCount: number;
  timeOfLoad: string;
};

type RefreshHeaderProps = CustomHeaderProps & {
  getGpsLoggerFileData: (
    msisdns: string[],
    startTimeUtc: string,
    endTimeUtc: string,
    timeZoneAbbreviation: TimeZoneValue
  ) => Promise<void>;
  timeZoneAbbreviation: TimeZoneValue;
  msisdns: string[];
  startTimeUtc: string;
  endTimeUtc: string;
};

const RefreshGridDataHeader = (props: RefreshHeaderProps) => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setIsRefreshing(true);

      await props.getGpsLoggerFileData(props.msisdns, props.startTimeUtc, props.endTimeUtc, props.timeZoneAbbreviation);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <StyledButton onClick={handleClick} type="button">
      <FontAwesomeIcon icon={icon({ name: "sync" })} spin={isRefreshing} />
    </StyledButton>
  );
};

const gridMessageOption = {
  noData: "No Saved Data",
  apiError: '<span style="color: #dc3545">Failed to Fetch Summary Data from Server</span>',
} as const;

type GridMessageOption = (typeof gridMessageOption)[keyof typeof gridMessageOption];

const GridWrapper = styled.div.attrs({ className: "ag-theme-balham" })`
  flex: 3;
  height: 220px;
  font-size: 14px;

  & .ag-overlay {
    z-index: 0;
  }
`;

type SavedGpsDataGridProps = {
  timeZoneAbbreviation: TimeZoneValue;
  msisdns: string[];
  startTimeUtc: string;
  endTimeUtc: string;
};

export const SavedGpsDataGrid = (props: SavedGpsDataGridProps) => {
  const gridApiRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const stringifiedMsisdns = JSON.stringify(props.msisdns);
  const timeZoneGridDisplay = getTimeZoneGridDisplayFromAbbreviation(props.timeZoneAbbreviation);
  const [gridMessage, setGridMessage] = useState<GridMessageOption>(gridMessageOption.noData);

  const getGpsFileSummaryAndSetRowData = async (
    msisdns: string[],
    startTimeUtc: string,
    endTimeUtc: string,
    timeZoneAbbreviation: TimeZoneValue
  ) => {
    try {
      const request = new GpsFileRequest(msisdns, startTimeUtc, endTimeUtc);

      const response = await getGpsFileSummary(request);

      if (response.hasError || !response.data) {
        if (response.error) {
          console.error(response.error.join(" "));
        }

        setGridMessage(gridMessageOption.apiError);
      } else {
        const mapOfGpsFileSummaryByMsisdn = getMapOfGpsFileSummaryByMsisdn(response.data.summaries);

        const cleanSummaryDataForGrid = getCleanSummaryDataForGrid(
          mapOfGpsFileSummaryByMsisdn,
          getTimeZoneIdentifierFromAbbreviation(timeZoneAbbreviation)
        );

        if (cleanSummaryDataForGrid.length === 0) {
          setGridMessage(gridMessageOption.noData);
        }

        setRowData(cleanSummaryDataForGrid);
      }
    } catch (error) {
      console.error(error);

      setGridMessage(gridMessageOption.apiError);
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !stringifiedMsisdns ||
        stringifiedMsisdns === "[]" ||
        !props.startTimeUtc ||
        !props.endTimeUtc
      ) {
        return;
      }

      await getGpsFileSummaryAndSetRowData(
        JSON.parse(stringifiedMsisdns),
        props.startTimeUtc,
        props.endTimeUtc,
        props.timeZoneAbbreviation
      );
    })();

    // don't need to add msisdns to dependency array as we've stringified it instead
  }, [stringifiedMsisdns, props.startTimeUtc, props.endTimeUtc, props.timeZoneAbbreviation]);

  const colDefs: ColDef<SavedGpsData>[] = useMemo(
    (): ColDef<SavedGpsData>[] => [
      {
        headerComponent: RefreshGridDataHeader,
        headerComponentParams: {
          getGpsLoggerFileData: getGpsFileSummaryAndSetRowData,
          msisdns: props.msisdns,
          startTimeUtc: props.startTimeUtc,
          endTimeUtc: props.endTimeUtc,
          timeZoneAbbreviation: props.timeZoneAbbreviation,
        },
        width: 40,
        sortable: false,
        editable: false,
        pinned: "left",
      },
      {
        field: "msisdn",
        headerName: "MSISDN",
        width: 115,
        valueFormatter: (params: ValueFormatterParams) => msisdnFormatter(params.value),
      },
      {
        field: "timeRange",
        headerName: `Time Range (${timeZoneGridDisplay} Time)`,
        width: 265,
      },
      {
        field: "gpsRecordCount",
        headerName: "GPS Record Count",
        width: 140,
        valueFormatter: (params: ValueFormatterParams) => numberFormatter(params.value),
      },
      {
        field: "timeOfLoad",
        headerName: `Time of Load (${timeZoneGridDisplay} Time)`,
        width: 220,
      },
    ],
    [timeZoneGridDisplay, props.msisdns, props.startTimeUtc, props.endTimeUtc, props.timeZoneAbbreviation]
  );

  return (
    <GridWrapper>
      <AgGridReact
        ref={gridApiRef}
        overlayNoRowsTemplate={gridMessage}
        enableCellTextSelection={true}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={{
          resizable: false,
          sortable: true,
        }}
      />
    </GridWrapper>
  );
};
