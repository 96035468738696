import { BatchSet } from "../shared/BatchSet";

class ReportFormData {
  modeOption: string;
  reportType: string;
  eSMLC: boolean;
  batchSets: BatchSet[];
  rawCallLinks: boolean;

  constructor(
    modeOption: string,
    reportType: string,
    eSMLC: boolean,
    batchSets: BatchSet[],
    rawCallLinks: boolean,
  ) {
    this.modeOption = modeOption;
    this.reportType = reportType;
    this.eSMLC = eSMLC;
    this.batchSets = batchSets;
    this.rawCallLinks = rawCallLinks;
  }
};

export default ReportFormData;