import { GroundTruthType } from "../reportHelpers";

// NOTE: For some unknown reason Formik stores File objects using a `path` property which doesn't exist on the JS File
// object. This type appends an optional `path` property to the File type so the Formik value can be accessed.
export type GpsLoggerFile = (File & { path?: string }) | null;

export class GroundTruthData {
  groundTruth: GroundTruthType = GroundTruthType.SavedGPSData;
  latitude: string = "";
  longitude: string = "";
  elevation: string = "";
  morphology: string = "";
  gpsLoggerFile: GpsLoggerFile = null;
  staticPointName: string = "";

  //this is set by the client
  batchSetId: number = -1;
}
