import { Nav, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AuthCheck } from "../auth/AuthCheck";
import { appPermissions } from "../auth/roles";
import ManageData from "../pages/ManageData/ManageData";
import MtlrSet from "../pages/Mtlr/MtlrSet";
import Report from "../pages/Report/Report";
import "./Content.css";

const Container = styled.div`
  grid-area: container;
  padding: 30px 15px 0px 15px;
  background-color: white;
  margin-top: 50px;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-left: 15px;
`;

//force the tabs to appear "above" the content area and to be left justified to the content edge
const NavContainer = styled(Nav)`
  margin-top: -66px;
  margin-left: -15px;
`;
const TabContent = styled(Tab.Content)`
  margin: 5px;
  height: 100%;
`;

const Content = () => {
  const location = useLocation();

  return (
    <Container id="container">
      <Tab.Container id="tabContainer" activeKey={location.pathname}>
        <NavContainer id="navContainer" variant="tabs">
          <AuthCheck
            requiredPermissions={[appPermissions.reportPage.view]}
            allow={() => (
              <Nav.Item id="reportNavItem">
                <Nav.Link eventKey="/report" to="/report" as={Link}>
                {location.pathname === "/report" ? <strong>Reports</strong> : "Reports"}
                </Nav.Link>
              </Nav.Item>
            )}
          />
          <AuthCheck
            requiredPermissions={[appPermissions.mtlrSetPage.view]}
            allow={() => (
              <Nav.Item id="mtlrNavItem">
                <Nav.Link eventKey="/mtlr-set" to="/mtlr-set" as={Link}>
                  {location.pathname === "/mtlr-set" ? <strong>MTLR Sets</strong> : "MTLR Sets"}
                </Nav.Link>
              </Nav.Item>
            )}
          />
          <AuthCheck
            requiredPermissions={[appPermissions.manageDataPage.view]}
            allow={() => (
              <Nav.Item id="manageDataNavItem">
                <Nav.Link eventKey="/manage-data" to="/manage-data" as={Link}>
                {location.pathname === "/manage-data" ? <strong>Manage Data</strong> : "Manage Data"}
                </Nav.Link>
              </Nav.Item>
            )}
          />
        </NavContainer>
        <TabContent id="tabContent">
          {location.pathname === "/mtlr-set" && <MtlrSet />}
          {location.pathname === "/report" && <Report />}
          {location.pathname === "/manage-data" && <ManageData />}
        </TabContent>
      </Tab.Container>
    </Container>
  );
};

export default Content;
