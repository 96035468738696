import * as yup from "yup";
import { getTimeZoneIdentifierFromAbbreviation } from "../../../utilities/timezone";
import { localDateToUTCDateTime } from "../../../utilities/datetime";
import { TimeZoneValue } from "../../../utilities/timezone";
import { DateTime } from "luxon";

export const errorMessages = {
  required: (type: string) => `${type} is required.`,

  MustBeGreaterThanOrEqualToZero: (type: string) =>
    `${type} must be greater than or equal to zero.`,

  duration: {
    HoursMustBeGreaterThanZero:
      "Hours must be greater than zero if minutes are zero",
    MinutesMustBeGreaterThanZero:
      "Minutes must be greater than zero if hours are zero",
    MinutesMustBeLessThan60: "Minutes must be between 0 and 59",
  },

  startDate: {
    DateInvalid: "Start Time is not a valid date.",
    inThePast: "Start Time must be in the future.",
  },

  msisdnNotFound: (type: string) => `Msisdn ${type} not found. Please re-type.`,

  intervalOutsideOfRange: (minValue: number, maxValue: number) =>
    `Interval must be a whole number between ${minValue} and ${maxValue}.`,
};

export const isEndDateValid = (
  inputStartDate: Date,
  timeZone: TimeZoneValue,
  hours: number,
  minutes: number
) => {
  const startDate = localDateToUTCDateTime(
    inputStartDate!,
    getTimeZoneIdentifierFromAbbreviation(timeZone)
  );

  const endDate = startDate.plus({
    hours: hours || 0,
    minutes: minutes || 0,
  });

  //the endDate must be in the future
  if (endDate <= DateTime.utc())
  {
    return false;
  }

  return true;
};

const addModalValidationSchema = (
  allowedMsisdns: string[],
  intervalMinValue: number,
  intervalMaxValue: number
) => {
  const buildValidationSchema = yup.object().shape(
    {
      startDate: yup
        .date()
        .typeError(errorMessages.startDate.DateInvalid)
        .required(errorMessages.required("Start Date")),

      hours: yup
        .number()
        .default(0)
        .integer(errorMessages.MustBeGreaterThanOrEqualToZero("Hours"))
        .min(0, errorMessages.MustBeGreaterThanOrEqualToZero("Hours"))
        .when("minutes", {
          is: 0,
          then: (schema) =>
            schema
              .min(1, errorMessages.duration.HoursMustBeGreaterThanZero),
        }),
      minutes: yup
        .number()
        .default(0)
        .integer(errorMessages.MustBeGreaterThanOrEqualToZero("Minutes"))
        .min(0, errorMessages.MustBeGreaterThanOrEqualToZero("Minutes"))
        .max(59, errorMessages.duration.MinutesMustBeLessThan60)
        .when("hours", {
          is: 0,
          then: (schema) =>
            schema
              .min(1, errorMessages.duration.MinutesMustBeGreaterThanZero),
        }),
      msisdns: yup
        .array()
        .min(1, errorMessages.required("Msisdn"))
        .of(
          yup
            .string()
            .required(errorMessages.required("Msisdn"))
            .test(
              "is-msisdn-in-list",
              (val) => errorMessages.msisdnNotFound(val.value),
              (val) => val !== undefined && allowedMsisdns.includes(val)
            )
        ),

      interval: yup
        .number()
        .required(errorMessages.required("Interval"))
        .integer(errorMessages.intervalOutsideOfRange(intervalMinValue, intervalMaxValue))
        .min(intervalMinValue, errorMessages.intervalOutsideOfRange(intervalMinValue, intervalMaxValue))
        .max(intervalMaxValue, errorMessages.intervalOutsideOfRange(intervalMinValue, intervalMaxValue))
    },
    [["minutes", "hours"]] //this tells yup that these fields depend on each other, w/out getting cyclic dependency error
  );

  return buildValidationSchema;
};

export default addModalValidationSchema;
