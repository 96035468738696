import DropdownIconButton from "../DropdownIconButton";

type Props = {
  handleDelete: () => Promise<void>;
  isVisible: boolean;
};

export const DeleteDropdownButton = ({ handleDelete, isVisible }: Props) => {
  return (
    <DropdownIconButton
      dropdownId="row--menu"
      onActionClick={handleDelete}
      isHidden={!isVisible}
      hoverColor="#dc3545"
      icon="trash"
      actionText="Delete Static Point"
    />
  );
};
