export default abstract class MlpStatusOptions {
  public static STATUS_SUCCESS = 0;
  public static DIAMETER_SUCCESS = 2001;
  public static SUCCESS_5G = 200;
  public static STATUS_UNKNOWN = -1;

  public static isSuccess = (status: number) =>
    status === MlpStatusOptions.STATUS_SUCCESS ||
    status === MlpStatusOptions.DIAMETER_SUCCESS ||
    status === MlpStatusOptions.SUCCESS_5G;
}
