import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { formatDateTimeUtc, formatMsisdn } from "../shared/formatters";

import SyntaxHighlighter from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/hljs";
import React from "react";
import { theme } from "../../../utilities/constants";

const HeaderContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0px 12px;
  background-color: #f5f7f7;
  outline: 1px solid ${theme.colors.lightGray};
`;

const HeaderText = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0000008a;
  white-space: nowrap;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 12px;
  padding-top: 1px;

  pre {
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

const Container = styled.div`
  border: 1px solid ${theme.colors.lightGray};
  border-width: thin;
  width: 30vw;
  margin-left: 1em;
  height: fit-content;
`;

type Props = {
  msisdn: string;
  startTime: string;
  isLoading: boolean;
  lastRequest: string | null;
  lastResponse: string | null;
};

const GmlcData = ({ msisdn, startTime, isLoading, lastRequest, lastResponse }: Props) => {
  return (
    <Container className={"d-flex flex-column"}>
      <HeaderContainer>
        <HeaderText>Most Recent Request/Response for MTLR Set</HeaderText>
      </HeaderContainer>

      <BodyContainer>
        <span>MSISDN {formatMsisdn(msisdn)}</span>
        <span>Start Time: {formatDateTimeUtc(startTime)}</span>
      </BodyContainer>

      <HeaderContainer>
        <HeaderText>Request</HeaderText>
      </HeaderContainer>
      {isLoading ? (
        <FontAwesomeIcon icon={icon({ name: "spinner" })} spin />
      ) : (
        lastRequest && (
          <BodyContainer>
            <SyntaxHighlighter language="xml" style={vs}>
              {lastRequest}
            </SyntaxHighlighter>
          </BodyContainer>
        )
      )}

      <HeaderContainer>
        <HeaderText>Response</HeaderText>
      </HeaderContainer>

      {isLoading ? (
        <FontAwesomeIcon icon={icon({ name: "spinner" })} spin />
      ) : (
        lastResponse && (
          <BodyContainer>
            <SyntaxHighlighter language="xml" style={vs}>
              {lastResponse}
            </SyntaxHighlighter>
          </BodyContainer>
        )
      )}
    </Container>
  );
};

export default GmlcData;
