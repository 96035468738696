import React from "react";
import styled from "styled-components";
import LoginButton from "./LoginButton";

const PageStyles = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: lightgray;
`;

const LoginCard = styled.div`
  width: 340px;
  height: 240px;
  padding: 1rem;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  background: white;
`;

const CardHeader = styled.div`
  & > .title {
    margin-bottom: 0;
  }

  & > .subtitle {
    margin-top: 0;
    opacity: 0.75;
  }
`;

const LoginPage = () => {
  return (
    <PageStyles>
      <LoginCard>
        <CardHeader>
          <h1 className="title">Welcome to LCAT</h1>
          <p className="subtitle">Please sign in to use the application.</p>
        </CardHeader>
        <LoginButton>Sign in</LoginButton>
      </LoginCard>
    </PageStyles>
  );
};

export default LoginPage;
