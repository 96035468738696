import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { formatMsisdn } from "../shared/formatters";
import { OptionType } from "./MsisdnPicker";

const pxToRem = (pixels: number, baseFontSize = 16) => {
  return `${pixels / baseFontSize}rem`;
};

const AddBulkMsisdnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  bottom: ${pxToRem(258)};
  height: ${pxToRem(240)};
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  position: relative;
  flex-wrap: wrap;

  @media screen and (min-width: 847px) {
    height: 0rem;
    margin-top: 0rem;
  }
`;

const SpacerDiv = styled.div`
  width: ${pxToRem(250)};
  min-width: ${pxToRem(250)};
  height: ${pxToRem(240)};
  margin-right: 1rem;
`;

const InputContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledTextAreaInput = styled(Form.Control)`
  padding: 0.25rem;
  box-shadow: none;
  height: ${pxToRem(173)};
  border: 1px solid #dfdfdf;
  outline-color: #dfdfdf;
  border-radius: 4px;
  resize: none;
  &:focus {
    border: 1px solid #dfdfdf;
    outline: 1px solid #2684ff;
    transition:
      border 100ms ease,
      outline 100ms ease;
  }
`;

const InputErrorContainer = styled.div`
  width: ${pxToRem(244)};
  margin: 0.25rem;
  color: red;
`;

const StyledButton = styled(Button)`
  margin-top: 0.5rem;
  white-space: nowrap;
`;

type Props = {
  allowListMsisdnOptions: OptionType[];
  selectedMsisdns: OptionType[];
  setSelectedMsisdns: (options: OptionType[]) => void;
};

export const AddBulkMsisdns = ({
  allowListMsisdnOptions,
  selectedMsisdns,
  setSelectedMsisdns,
}: Props) => {
  const [inputText, setInputText] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");

  const handleBulkAddMsisdns = () => {
    if (!inputText) {
      return;
    }
    setInputError("");

    const validMsisdnOptions = [...selectedMsisdns];
    const existingMsisdns = [...selectedMsisdns.map((m) => m.value)];
    const invalidMsisdns = [];

    const rawInputs = inputText.split("\n");
    for (const rawInput of rawInputs) {
      const digits = [];
      for (const char of rawInput) {
        if (/\d/.test(char)) {
          digits.push(char);
        }
      }
      const msisdn = digits.join("");

      // check msisdn length
      if (msisdn.length !== 10) {
        invalidMsisdns.push(rawInput);
        continue;
      }

      // check allow list
      const allowListMsisdns = allowListMsisdnOptions.map((o) => o.value);
      if (!allowListMsisdns.includes(msisdn)) {
        invalidMsisdns.push(msisdn);
        continue;
      }

      // check for duplicates
      if (existingMsisdns.includes(msisdn)) {
        continue;
      }

      // add to valid msisdn options
      const msisdnOption: OptionType = {
        value: msisdn,
        label: formatMsisdn(msisdn),
      };
      existingMsisdns.push(msisdn);
      validMsisdnOptions.push(msisdnOption);
    }

    if (invalidMsisdns.length > 0) {
      setInputError(
        "The above MSISDN values are invalid. MSISDN values must be one per line, 10 digits and on your Allow List"
      );
    }

    setInputText(invalidMsisdns.join("\n"));
    setSelectedMsisdns(validMsisdnOptions);
  };

  return (
    <AddBulkMsisdnsContainer>
      <SpacerDiv />

      <InputContainer>
        <StyledTextAreaInput
          as="textarea"
          value={inputText}
          onChange={(e: any) => {
            setInputError("");
            setInputText(e.target.value);
          }}
          placeholder="Enter a list of MSISDNs separated by line breaks..."
        />

        <div className="d-flex flex-row align-items-start">
          <InputErrorContainer>{inputError}</InputErrorContainer>

          {/* @ts-ignore */}
          <StyledButton size="sm" onClick={handleBulkAddMsisdns}>
            <FontAwesomeIcon icon={faPlus} size="sm" /> Bulk Add MSISDNs
          </StyledButton>
        </div>
      </InputContainer>
    </AddBulkMsisdnsContainer>
  );
};
