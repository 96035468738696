export default class LineReader {
  private file: File;

  constructor(file: File) {
    this.file = file;
  }

  async *forEachLine() {
    // Convince Typescript that this is not a NodeJS.ReadableStream. (A deeply-nested dependency is importing @types/node and is confusing typescript.)
    const stream = this.file.stream() as unknown as ReadableStream;
    const reader = stream.getReader();
    const decoder = new TextDecoder("utf-8");

    let currentChunk = "";

    while (true) {
      const result = await reader.read();

      if (result.done) {
        break;
      }

      currentChunk += decoder.decode(result.value, { stream: true });

      let { newLineIndex, isCrlf } = this.findNewLineIndex(currentChunk);

      while (newLineIndex !== -1) {
        const line = currentChunk.slice(0, newLineIndex);

        // Reset the current chunk to the remainder of the last chunk.
        const charactersToSkip = isCrlf ? 2 : 1;
        currentChunk = currentChunk.slice(newLineIndex + charactersToSkip);

        // Yield the line to the iterator.
        yield line;

        // Search for the next newline to determine if we loop again or not.
        const result = this.findNewLineIndex(currentChunk);
        newLineIndex = result.newLineIndex;
        isCrlf = result.isCrlf;
      }
    }
  }

  private findNewLineIndex = (value: string) => {
    const crlf = "\r\n";
    const lf = "\n";
    const crlfIndex = value.indexOf(crlf);

    let newLineIndex = -1;
    let isCrlf = false;

    if (crlfIndex === -1) {
      const lfIndex = value.indexOf(lf);
      newLineIndex = lfIndex;
    } else {
      newLineIndex = crlfIndex;
      isCrlf = true;
    }

    return { newLineIndex, isCrlf };
  };
}
