import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CellClassParams,
  CellStyle,
  CellStyleFunc,
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  RowValueChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { useReportContext } from "../../../../contexts/ReportContext";
import { useUserContext } from "../../../../contexts/UserContext";
import {
  deleteStaticPoint,
  getStaticPoints,
  patchStaticPoint,
  putStaticPoint,
} from "../../../../services/apiService";
import { StaticPoint } from "../../../../services/models/StaticPoint";
import { StaticPointAddEditRequest } from "../../../../services/requests/StaticPointAddEditRequest";
import { StaticPointPatchRequest } from "../../../../services/requests/StaticPointPatchRequest";
import IconButton from "../../shared/IconButton";
import { formatDateTimeUtc } from "../../shared/formatters";
import { morphologyOptions } from "../GroundTruthStatic";
import { DeleteDropdownButton } from "./DeleteDropdownButton";
import {
  StaticPointKey,
  StaticPointRowState,
  buildRowState,
  getValidatedRowState,
} from "./StaticPointsValidationSchema";
import { theme } from "../../../../utilities/constants";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 30px;
  overflow: visible;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  display: flex;
  flex: 1;
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 250px);
`;

const GridStyle = styled.div`
  grid-area: grid;
  width: 100%;
  height: 97%;
  margin-bottom: 5px;
  max-width: 1135px;
`;

const GridWrapper = styled.div.attrs({ className: "ag-theme-balham" })`
  height: 100%;
  font-size: 14px;

  & .ag-row {
    overflow: visible !important;
  }

  & .ag-cell-value {
    overflow: visible !important;
  }

  & .ag-row.ag-row-focus {
    z-index: 1;
  }
`;

const editableCallback = (params: EditableCallbackParams) => {
  const rowData: StaticPoint = params.data;
  const gridContext: GridContext = params.context;

  return gridContext.editableRows && gridContext.isEditingStaticPoints && gridContext.idOfStaticPointBeingEdited === rowData.id;
};

const cellStyleCallback: CellStyleFunc = (params: CellClassParams): CellStyle => {
  const rowState = params.context.rowState;
  const colId = params.column.getColId();

  const field = rowState[colId];
  const hasError =
    params.context.isEditingStaticPoints &&
    params.context.idOfStaticPointBeingEdited === params.data?.id &&
    field?.errorMessage;

  const isEditable = params.column.isCellEditable(params.node);

  return {
    border: hasError ? "1px solid #dc3545" : isEditable ? "1px solid #0d6efd" : "",
  };
};

type GridContext = {
  isEditingStaticPoints: boolean;
  idOfStaticPointBeingEdited: number;
  editableRows: boolean;
  enableDeleteRows: boolean;
};

type StaticPointsModalProps = {
  showModal: boolean;
  hideModal: () => void;
  handleLoadStaticPoint: (e: MouseEvent<HTMLButtonElement>, params: any) => Promise<void>;
  editableRows?: boolean;
  enableDeleteRows?: boolean;
};

export const StaticPointsModal = ({
  showModal,
  hideModal,
  handleLoadStaticPoint,
  editableRows = true,
  enableDeleteRows = true
}: StaticPointsModalProps) => {
  const { loadedStaticPointId, setGroundTruthStaticMessage, setIsSaveAsButtonDisabled } =
    useReportContext();
  const { userTimeZoneIdentifier } = useUserContext();
  const gridApiRef = useRef<AgGridReact>(null);

  const [isEditingStaticPoints, setIsEditingStaticPoints] = useState<boolean>(false);
  const [idOfStaticPointBeingEdited, setIdOfStaticPointBeingEdited] = useState<number | null>(null);
  const [rowIndex, setRowIndex] = useState<number | null>(null);
  const [nodeId, setNodeId] = useState<string | undefined>();

  const [staticPoints, setStaticPoints] = useState<StaticPoint[]>([]);
  const [apiError, setApiError] = useState<string>("");
  const [rowState, setRowState] = useState<StaticPointRowState>(buildRowState());
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const hasGridErrors = Object.values(rowState).some((rs) => rs.errorMessage);

  useEffect(() => {
    if (!gridApiRef.current?.api || rowIndex === null) {
      return;
    }

    if (isEditingStaticPoints) {
      gridApiRef.current?.api.startEditingCell({
        rowIndex: rowIndex,
        colKey: "name",
      });
    }
  }, [rowIndex, isEditingStaticPoints]);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    fetchStaticPoints();
  }, [showModal]);

  useEffect(() => {
    if (gridApiRef.current?.api) {
      gridApiRef.current.api.refreshCells({ force: true });
    }
  }, [rowState]);

  const fetchStaticPoints = async () => {
    try {
      const response = await getStaticPoints();

      if (response.hasError || !response.data) {
        if (response.error) {
          setApiError(response.error.join(" "));
        } else {
          setApiError("Failed to get static points");
        }
      } else {
        setApiError("");
        setStaticPoints(response.data);
      }
    } catch (err) {
      setApiError("Failed to get static points");
    }
  };

  const handleDeleteStaticPoint = async (params: ICellRendererParams<StaticPoint>) => {
    if (!params.data) {
      return;
    }

    // remove static point from grid
    try {
      const response = await deleteStaticPoint(params.data.id);

      if (response.hasError) {
        if (response.error) {
          console.error(response.error.join(" "));
        }

        setApiError(
          "Failed to delete Static Point. Try again or contact the application administrator."
        );
      } else {
        if (params.context.loadedStaticPointId === params.data.id) {
          setGroundTruthStaticMessage("");
          setIsSaveAsButtonDisabled(false);
        }

        setApiError("");
        gridApiRef.current?.api.applyTransaction({ remove: [params.data] });
      }
    } catch (err) {
      setApiError(
        "Failed to delete Static Point. Try again or contact the application administrator."
      );
    }
  };

  const handleStartEditStaticPoint = async (
    e: MouseEvent<HTMLButtonElement>,
    params: ICellRendererParams<StaticPoint>
  ) => {
    if (!params.data) {
      return;
    }
    const data = params.data as StaticPoint;

    setRowState(buildRowState(data));
    setIsEditingStaticPoints(true);
    setIdOfStaticPointBeingEdited(params.data.id);
    setRowIndex(params.node.rowIndex);
    setNodeId(params.node.id);
  };

  const handleDiscardChanges = () => {
    if (!gridApiRef.current?.api || !nodeId) {
      return;
    }

    const rowNode = gridApiRef.current.api.getRowNode(nodeId);

    const oldRowData: StaticPoint = {
      id: rowState.id.initialValue,
      user: rowState.user.initialValue,
      name: rowState.name.initialValue,
      latitude: rowState.latitude.initialValue,
      longitude: rowState.longitude.initialValue,
      elevation_Meters: rowState.elevation_Meters.initialValue,
      morphology: rowState.morphology.initialValue,
      lastUsedUtc: rowState.lastUsedUtc.initialValue,
    };

    rowNode?.setData(oldRowData);
    setRowState(buildRowState());

    gridApiRef.current.api.stopEditing(false);
    setIdOfStaticPointBeingEdited(null);
    setIsEditingStaticPoints(false);
    setRowIndex(null);
  };

  const handleSaveStaticPointChanges = async () => {
    if (hasGridErrors || !gridApiRef?.current?.api || !nodeId) {
      return;
    }

    setApiError("");
    setIsSaving(true);

    try {
      let updatedStaticPoint: StaticPoint;
      const rowNode = gridApiRef.current.api.getRowNode(nodeId);
      const staticPoint = rowNode?.data as StaticPoint;

      const requestBody: StaticPointAddEditRequest = new StaticPointAddEditRequest(
        rowState.name.newValue,
        rowState.latitude.newValue,
        rowState.longitude.newValue,
        rowState.elevation_Meters.newValue,
        rowState.morphology.newValue
      );

      // update static point record
      const putResponse = await putStaticPoint(staticPoint.id, requestBody);
      if (putResponse.hasError || !putResponse.data) {
        if (putResponse.error) {
          console.error(putResponse.error.join(" "));
        }

        setApiError(
          "Failed to save Static Point. Try again or contact the application administrator."
        );
      } else {
        updatedStaticPoint = putResponse.data;

        // update static point's lastUsedUtc
        const staticPointPatchRequest = new StaticPointPatchRequest();
        const patchResponse = await patchStaticPoint(staticPoint.id, staticPointPatchRequest);
        if (patchResponse.hasError || !patchResponse.data) {
          if (patchResponse.error) {
            console.error(patchResponse.error.join(" "));
          }
        } else {
          updatedStaticPoint = patchResponse.data;
        }

        rowNode?.setData(updatedStaticPoint);
        setApiError("");
        setRowState(buildRowState());

        gridApiRef.current.api.stopEditing(true);
        setIdOfStaticPointBeingEdited(null);
        setIsEditingStaticPoints(false);
        setRowIndex(null);
      }
    } catch (err) {
      console.error(err);
      setApiError(
        "Failed to save Static Point. Try again or contact the application administrator."
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnRowValueChanged = async (params: RowValueChangedEvent<StaticPoint>) => {
    const staticPoint = params.data;

    if (!staticPoint) {
      return;
    }

    const updatedRowState = { ...rowState };
    const keys = Object.keys(staticPoint) as StaticPointKey[];

    for (const key of keys) {
      const updatedField = { ...updatedRowState[key] };
      updatedField.newValue = staticPoint[key];
      updatedField.errorMessage = "";
      updatedRowState[key] = updatedField;
    }

    const validatedRowState = await getValidatedRowState(updatedRowState);

    setRowState(validatedRowState);
  };

  const colDefs: ColDef<StaticPoint>[] = useMemo(
    (): ColDef<StaticPoint>[] => [
      {
        width: 100,
        sortable: false,
        editable: false,
        pinned: "left",
        cellRenderer: (params: ICellRendererParams<StaticPoint>) => {
          return (
            <IconContainer>
              <IconButton
                title="Load"
                iconProp={icon({ name: "file-export" })}
                hoverColor={theme.colors.green}
                onClick={async (e) => await handleLoadStaticPoint(e, params)}
                isVisible={!params.context.isEditingStaticPoints}
              />
              <IconButton
                title="Edit"
                iconProp={icon({ name: "pen-to-square" })}
                hoverColor="#0d6efd"
                onClick={async (e) => await handleStartEditStaticPoint(e, params)}
                isVisible={
                  (!params.context.isEditingStaticPoints ||
                  params.context.idOfStaticPointBeingEdited === params.data?.id) &&
                  params.context.editableRows
                }
              />
              <DeleteDropdownButton
                handleDelete={async () => await handleDeleteStaticPoint(params)}
                isVisible={!params.context.isEditingStaticPoints && params.context.enableDeleteRows}
              />
            </IconContainer>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        width: 300,
        singleClickEdit: true,
        editable: editableCallback,
        cellEditor: "agTextCellEditor",
        cellEditorParams: {
          maxLength: 50,
        },
        cellDataType: "text",
        cellStyle: cellStyleCallback,
      },
      {
        field: "lastUsedUtc",
        headerName: "Last Used",
        width: 180,
        valueFormatter: (params) => formatDateTimeUtc(params.value, userTimeZoneIdentifier),
        editable: false,
        sortable: true,
        sort: "desc",
        cellStyle: {
          borderRight: "1px solid #ccc",
        },
      },
      {
        field: "latitude",
        headerName: "Latitude",
        width: 100,
        editable: editableCallback,
        singleClickEdit: true,
        cellDataType: "number",
        cellEditorParams: {
          preventStepping: true,
        },
        cellStyle: cellStyleCallback,
      },
      {
        field: "longitude",
        headerName: "Longitude",
        width: 100,
        editable: editableCallback,
        singleClickEdit: true,
        cellDataType: "number",
        cellEditorParams: {
          preventStepping: true,
        },
        cellStyle: cellStyleCallback,
      },
      {
        field: "elevation_Meters",
        headerName: "Elevation (meters)",
        width: 140,
        editable: editableCallback,
        singleClickEdit: true,
        cellDataType: "number",
        cellEditorParams: {
          preventStepping: true,
        },
        cellStyle: cellStyleCallback,
      },
      {
        field: "morphology",
        headerName: "Morphology",
        cellEditor: "agSelectCellEditor",
        width: 120,
        editable: editableCallback,
        singleClickEdit: true,
        cellEditorParams: {
          values: morphologyOptions.map((o) => o.value),
        },
        cellStyle: cellStyleCallback,
      },
    ],
    [isEditingStaticPoints, idOfStaticPointBeingEdited, userTimeZoneIdentifier, editableRows, enableDeleteRows]
  );

  return (
    <Modal
      id="staticPointsModal"
      show={showModal}
      onHide={hideModal}
      size="xl"
      backdrop="static"
      keyboard={!isEditingStaticPoints}
    >
      <Modal.Header>
        <Modal.Title>Saved Static Points</Modal.Title>
      </Modal.Header>

      <ModalBody>
        <ModalContent>
          <GridStyle>
            <GridWrapper>
              <AgGridReact
                ref={gridApiRef}
                context={{
                  isEditingStaticPoints,
                  idOfStaticPointBeingEdited,
                  rowState,
                  loadedStaticPointId,
                  editableRows,
                  enableDeleteRows
                }}
                editType="fullRow"
                enableCellTextSelection={true}
                stopEditingWhenCellsLoseFocus={true}
                suppressDragLeaveHidesColumns={true}
                rowData={staticPoints}
                columnDefs={colDefs}
                defaultColDef={{
                  resizable: false,
                  sortable: true,
                }}
                onRowValueChanged={handleOnRowValueChanged}
              ></AgGridReact>
            </GridWrapper>
          </GridStyle>
        </ModalContent>

        <div className="d-flex justify-content-between mt-4">
          {isEditingStaticPoints ? (
            <Button size="sm" onClick={handleDiscardChanges} variant="outline-secondary">
              <FontAwesomeIcon
                icon={icon({ name: "arrow-turn-up" })}
                rotation={270}
                className="me-2"
              />
              Discard
            </Button>
          ) : (
            <Button onClick={hideModal} size="sm" variant="outline-secondary">
              <FontAwesomeIcon
                icon={icon({ name: "arrow-turn-up" })}
                rotation={270}
                className="me-2"
              />
              Close
            </Button>
          )}

          <ErrorContainer>
            {apiError && (
              <div className="text-danger small">
                <span>{apiError}</span>
              </div>
            )}

            {rowState.latitude.errorMessage && (
              <div className="text-danger small">
                <span>{rowState.latitude.errorMessage}</span>
              </div>
            )}
            {rowState.longitude.errorMessage && (
              <div className="text-danger small">
                <span>{rowState.longitude.errorMessage}</span>
              </div>
            )}
            {rowState.elevation_Meters.errorMessage && (
              <div className="text-danger small">
                <span>{rowState.elevation_Meters.errorMessage}</span>
              </div>
            )}
            {rowState.morphology.errorMessage && (
              <div className="text-danger small">
                <span>{rowState.morphology.errorMessage}</span>
              </div>
            )}
          </ErrorContainer>

          {isEditingStaticPoints && (
            <Button size="sm" onClick={handleSaveStaticPointChanges} disabled={hasGridErrors}>
              {isSaving ? (
                <FontAwesomeIcon
                  icon={icon({ name: "spinner" })}
                  spin={isSaving}
                  className="me-2"
                />
              ) : (
                <FontAwesomeIcon icon={icon({ name: "floppy-disk" })} className="me-2" />
              )}
              Save
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
