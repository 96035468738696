import { DateTime } from "luxon";
import { localDateToUTCDateTime } from "../../../utilities/datetime";
import { TimeZoneValue, getTimeZoneIdentifierFromAbbreviation } from "../../../utilities/timezone";
import { BatchSet } from "../shared/BatchSet";
import { DateTimeOption } from "../shared/helpers";
import { CorrelatedControlPlane } from "./models/CorrelatedControlPlane";
import { CorrelatedMlpLocation } from "./models/CorrelatedMlpLocation";
import { CorrelatedThunderbird } from "./models/CorrelatedThunderbird";

export const TOLERANCE_MS = 30_000;

export const ModeOption = {
  E911: "e911",
  MTLR: "mtlr",
};

export const enum ReportType {
  Raw = "raw",
  Excel = "excel",
}

export const rawReportHeader = [
  "CallId",
  "TransactionId",
  "MSISDN",
  "IMSI",
  "IMEI",
  "RANTech",
  "Type",
  "iOS",
  "CallStartTime",
  "LocationRequestTime",
  "AscertainedTime",
  "Active",
  "HorizontalAccuracy",
  "VerticalAccuracy",
  "Longitude",
  "Latitude",
  "Shape",
  "ExtendedHorizontalUncertainty",
  "ExtendedVerticalUncertainty",
  "HorizontalUncertainty",
  "Confidence",
  "Altitude",
  "VerticalUncertainty",
  "Latency",
  "StatusValue",
  "StatusText",
  "IsSuccessful",
  "CGI",
  "PositionMethod",
  "UBP",
  "FloorNumber",
  "NEADAddressType",
  "NEADCounty",
  "NEADHouseNumber",
  "NEADFloor",
  "NEADUnit",
  "NEADRoad",
  "NEADCity",
  "NEADState",
  "NEADPostalCode",
  "GpsUserName",
  "GpsLongitude",
  "GpsLatitude",
  "GpsAscertainedTime",
  "GpsStatic",
  "GpsElevation",
  "GpsSpeed",
  "GpsSatelliteCount",
  "GpsStatus",
  "GpsMorphology",
  "GpsHdop",
  "GpsPdop",
  "GpsVdop",
  "SelectedVertical",
  "UsedInReport",
  "VersionSupported",
  "Call Link",
];

export const gpsLoggerHeader = [
  "UserId",
  "Msisdn",
  "MSISDN_Name",
  "Date",
  "Longitude",
  "Latitude",
  "Elevation",
  "Speed",
  "NumberSatellites",
  "Status",
  "Morphology",
  "HDOP",
  "PDOP",
  "VDOP",
];

export const isGpsLoggerHeader = (data: string) => {
  return data.replaceAll(", ", ",").toLowerCase() === gpsLoggerHeader.toString().toLowerCase();
};

export const enum GroundTruthType {
  None = "none",
  Static = "static",
  GpsLogger = "gpsLogger",
  SavedGPSData = "savedGPSData",
}

export const MorphologyOption = {
  Outdoor: "Outdoor",
  Indoor: "Indoor",
  Mild_Indoor: "Mild Indoor",
  Deep_Indoor: "Deep Indoor",
} as const;
export type ValuesOfType<T> = T[keyof T];
export type TMorphologyOption = ValuesOfType<typeof MorphologyOption>;

const K: number = Math.PI / 180;
const R: number = 6378135; // meters

export const getSphericalDistanceInMeters = (
  mlpLocationLongitude: number,
  mlpLocationLatitude: number,
  gpsLocationLongitude: number,
  gpsLocationLatitude: number
) => {
  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin((K * (mlpLocationLatitude - gpsLocationLatitude)) / 2), 2) +
          Math.cos(K * mlpLocationLatitude) *
            Math.cos(K * gpsLocationLatitude) *
            Math.pow(Math.sin((K * (mlpLocationLongitude - gpsLocationLongitude)) / 2), 2)
      )
    )
  );
};

export const calculateVerticalAccuracy = (mlpLocationAltitude: number, gpsLocationElevation: number) => {
  return Math.abs(mlpLocationAltitude - gpsLocationElevation);
};

export const toNearestSecond = (ticks: number) => {
  const ms = ticks % 1000;

  if (ms > 499) {
    ticks = ticks + 1000 - ms;
  } else {
    ticks = ticks - ms;
  }

  return ticks;
};

export const roundInputMilliseconds = (unRoundedDate: DateTime) => {
  const roundedDate: DateTime =
    unRoundedDate.millisecond > 499
      ? unRoundedDate.plus({
          milliseconds: 1000 - unRoundedDate.millisecond,
        })
      : unRoundedDate.plus({
          milliseconds: -unRoundedDate.millisecond,
        });

  return roundedDate;
};

export const truncateInputMilliseconds = (unRoundedDate: DateTime) => {
  const truncatedDate: DateTime = unRoundedDate.endOf("millisecond");
  return truncatedDate;
};

export const enum LocationType {
  ControlPlane = "CP",
  Thunderbird = "TB",
  //this is a location.type that is returned but can't trigger off of it like we do for CP/TB
  SIP = "SIP",
}

export const isCorrelatedControlPlane = (location: CorrelatedMlpLocation): location is CorrelatedControlPlane => {
  return location.type === LocationType.ControlPlane;
};

export const isCorrelatedThunderbird = (location: CorrelatedMlpLocation): location is CorrelatedThunderbird => {
  return location.type === LocationType.Thunderbird;
};

export const dateFormatter = (value: string | null) => {
  if (!value) {
    return "";
  }

  const dateUtc = DateTime.fromISO(value, { zone: "utc" });

  const month = dateUtc.month;
  const day = dateUtc.day;
  const year = dateUtc.year;

  const milliseconds = dateUtc.millisecond;
  const hour = dateUtc.hour;
  const min = dateUtc.minute;
  const sec = dateUtc.second;
  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year} ${hour
    .toString()
    .padStart(2, "0")}:${min.toString().padStart(2, "0")}:${sec
    .toString()
    .padStart(2, "0")}.${milliseconds.toString().padStart(3, "0")}`;
};

export const calculateBatchSetDate = (batchSet: BatchSet, dateNow: DateTime) => {
  const timeZoneIdentifier = getTimeZoneIdentifierFromAbbreviation(batchSet.timeZone);

  let endDate = dateNow.toString();
  let startDate = "";
  let endDateForGpsFileValidation = dateNow;
  let startDateForGpsFileValidation = dateNow;

  switch (batchSet.dateTimeOption) {
    case DateTimeOption.LAST_FOUR_HOURS: {
      startDate = dateNow.plus({ hours: -4 }).toString();
      startDateForGpsFileValidation = dateNow.plus({ hours: -4 });
      break;
    }
    case DateTimeOption.LAST_EIGHT_HOURS: {
      startDate = dateNow.plus({ hours: -8 }).toString();
      startDateForGpsFileValidation = dateNow.plus({ hours: -8 });
      break;
    }
    case DateTimeOption.LAST_TWENTY_FOUR_HOURS: {
      startDate = dateNow.plus({ hours: -24 }).toString();
      startDateForGpsFileValidation = dateNow.plus({ hours: -24 });
      break;
    }
    case DateTimeOption.LAST_SEVEN_DAYS: {
      startDate = dateNow.plus({ days: -7 }).toString();
      startDateForGpsFileValidation = dateNow.plus({ days: -7 });
      break;
    }
    case DateTimeOption.LAST_THIRTY_DAYS: {
      startDate = dateNow.plus({ days: -30 }).toString();
      startDateForGpsFileValidation = dateNow.plus({ days: -30 });
      break;
    }
    case DateTimeOption.CUSTOM_DATE_TIME: {
      startDate = localDateToUTCDateTime(batchSet.customStartDate, timeZoneIdentifier).toString();
      endDate = localDateToUTCDateTime(batchSet.customEndDate, timeZoneIdentifier).toString();

      startDateForGpsFileValidation = localDateToUTCDateTime(batchSet.customStartDate, timeZoneIdentifier);
      endDateForGpsFileValidation = localDateToUTCDateTime(batchSet.customEndDate, timeZoneIdentifier);
      break;
    }
    default:
      throw new Error("Invalid date and time object type.");
  }

  return {
    startDate: startDate,
    endDate: endDate,
    startDateForGpsFileValidation: startDateForGpsFileValidation,
    endDateForGpsFileValidation: endDateForGpsFileValidation,
  };
};

export const customSelectStyles = (error: boolean, width: number) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
      width: `${width}px`,
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
    /* styles for menu which is shown when the dropdown is clicked */
    menu: (provided: any) => ({
      ...provided,
    }),
  };
};

export const customMultiSelectStyles = (error: boolean) => {
  return {
    /* styles for input box for msisdn entry */
    control: (provided: any) => ({
      ...provided,
      border: error ? "1px solid red" : "",
    }),
    /* styles for menu dropdown list */
    menu: (provided: any) => ({
      ...provided,
      position: "relative",
      boxShadow: "none",
      border: "1px solid #dfdfdf",
      width: "250px",
    }),
    /* styles for menuList  */
    menuList: (provided: any) => ({
      ...provided,
      height: "240px",
    }),
    /* change the spacing of the items in the menulist */
    option: (provided: any) => ({
      ...provided,
      paddingTop: "1px",
      paddingBottom: "1px",
    }),
  };
};

/**
 * Converts a number of bytes into a human-readable string representing the size in bytes, kilobytes (KB), megabytes (MB), gigabytes (GB), or terabytes (TB).
 * @param {number} bytes - The number of bytes to be converted.
 * @returns {string} A string representing the size in human-readable format, such as "5 KB", "3.2 MB", etc.
 */
export const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1_024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  let i = 0;
  while (bytes >= k) {
    bytes /= k;
    i++;
  }

  return parseFloat(bytes.toFixed(1)) + " " + sizes[i];
};

export const readableStreamToFile = async (readableStream: ReadableStream, filename: string): Promise<File> => {
  const reader = readableStream.getReader();
  const chunks: Uint8Array[] = [];
  let totalLength = 0;

  // Read data from the stream
  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
    totalLength += value.length;
  }

  // Concatenate all the chunks into a single Uint8Array
  const fullArray = new Uint8Array(totalLength);
  let offset = 0;
  for (const chunk of chunks) {
    fullArray.set(chunk, offset);
    offset += chunk.length;
  }

  const blob = new Blob([fullArray]);
  const file = new File([blob], filename);

  return file;
};

export const getBatchSetStartDate = (
  dateTimeOption: string,
  customStartDate: Date,
  timeZone: TimeZoneValue
): string => {
  switch (dateTimeOption) {
    case DateTimeOption.CUSTOM_DATE_TIME:
      return localDateToUTCDateTime(customStartDate, getTimeZoneIdentifierFromAbbreviation(timeZone)).toISO() ?? "";
    case DateTimeOption.LAST_FOUR_HOURS:
      return DateTime.utc().minus({ hours: 4 }).toISO();
    case DateTimeOption.LAST_EIGHT_HOURS:
      return DateTime.utc().minus({ hours: 8 }).toISO();
    case DateTimeOption.LAST_TWENTY_FOUR_HOURS:
      return DateTime.utc().minus({ hours: 24 }).toISO();
    case DateTimeOption.LAST_SEVEN_DAYS:
      return DateTime.utc().minus({ days: 7 }).toISO();
    case DateTimeOption.LAST_THIRTY_DAYS:
      return DateTime.utc().minus({ days: 30 }).toISO();
    default:
      return DateTime.utc().toISO();
  }
};

export const getBatchSetEndDate = (dateTimeOption: string, customEndDate: Date, timeZone: TimeZoneValue) => {
  switch (dateTimeOption) {
    case DateTimeOption.CUSTOM_DATE_TIME:
      return localDateToUTCDateTime(customEndDate, getTimeZoneIdentifierFromAbbreviation(timeZone)).toISO() ?? "";
    default:
      return DateTime.utc().toISO();
  }
};
