// identifiers taken from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const validTimeZones = {
  eastern: {
    value: "ET",
    label: "Eastern Time (UTC -4 / -5)",
    identifier: "America/New_York",
    gridDisplay: "Eastern",
  },
  central: {
    value: "CT",
    label: "Central Time (UTC -5 / -6)",
    identifier: "America/Chicago",
    gridDisplay: "Central",
  },
  mountain: {
    value: "MT",
    label: "Mountain Time (UTC -6 / -7)",
    identifier: "America/Denver",
    gridDisplay: "Mountain",
  },
  pacific: {
    value: "PT",
    label: "Pacific Time (UTC -7 / -8)",
    identifier: "America/Los_Angeles",
    gridDisplay: "Pacific",
  },
  arizona: {
    value: "AZ",
    label: "Arizona Time (UTC -7)",
    identifier: "America/Phoenix",
    gridDisplay: "Arizona",
  },
  alaska: {
    value: "AT",
    label: "Alaska Time (UTC -8 / -9)",
    identifier: "America/Anchorage",
    gridDisplay: "Alaska",
  },
  hawaii: {
    value: "HT",
    label: "Hawaii Time (UTC -10)",
    identifier: "Pacific/Honolulu",
    gridDisplay: "Hawaii",
  },
  utc: {
    value: "UTC",
    label: "UTC Time (UTC -0)",
    identifier: "UTC",
    gridDisplay: "UTC",
  },
} as const;

export const timeZoneOptions = [
  { value: validTimeZones.eastern.value, label: validTimeZones.eastern.label },
  { value: validTimeZones.central.value, label: validTimeZones.central.label },
  { value: validTimeZones.mountain.value, label: validTimeZones.mountain.label },
  { value: validTimeZones.pacific.value, label: validTimeZones.pacific.label },
  { value: validTimeZones.arizona.value, label: validTimeZones.arizona.label },
  { value: validTimeZones.alaska.value, label: validTimeZones.alaska.label },
  { value: validTimeZones.hawaii.value, label: validTimeZones.hawaii.label },
  { value: validTimeZones.utc.value, label: validTimeZones.utc.label },
];


export const theme = {
  colors: {
    green: "#28a745",
    red: "#dc3545",
    blue: "#0d6efd",
    yellow: "#ffc107",
    lightGray: "#bdc3c7",
  }
}