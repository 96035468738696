import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { Navigate, Route, Routes, createRoutesFromElements } from "react-router";
import styled from "styled-components";
import Content from "../src/components/layout/Content";
import Footer from "../src/components/layout/Footer";
import msalInstance from "./azure/msalInstance";
import AuthorizedRoute from "./components/auth/AuthorizedRoute";
import { appPermissions } from "./components/auth/roles";
import Header from "./components/layout/Header";
import SiteLayout from "./components/layout/SiteLayout";
import { FiveHundredPage } from "./components/pages/Error/FiveHundredPage";
import { FourOhFourPage } from "./components/pages/Error/FourOhFourPage";
import { FourOhOnePage } from "./components/pages/Error/FourOhOnePage";
import LoginPage from "./components/pages/Login/LoginPage";
import { UserContextProvider } from "./contexts/UserContext";
import { ReportContextProvider } from "./contexts/ReportContext";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const Version = styled.span`
  padding-left: 5px;
`;

const unauthenticatedRouter = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<LoginPage />} />));

const authenticatedRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/report" replace />} />
      <Route
        path="/report"
        element={
          <ReportContextProvider>
            <AuthorizedRoute requiredPermissions={[appPermissions.reportPage.view]} allow={() => <Content />} />
          </ReportContextProvider>
        }
      />
      <Route
        path="/manage-data"
        element={
          <AuthorizedRoute requiredPermissions={[appPermissions.manageDataPage.view]} allow={() => <Content />} />
        }
      />
      <Route
        path="/mtlr-set"
        element={<AuthorizedRoute requiredPermissions={[appPermissions.mtlrSetPage.view]} allow={() => <Content />} />}
      />
      <Route path="/401" element={<FourOhOnePage />} />
      <Route path="/500" element={<FiveHundredPage />} />
      <Route path="/404" element={<FourOhFourPage />} />
    </>
  )
);

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <RouterProvider router={unauthenticatedRouter} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <UserContextProvider>
          <SiteLayout>
            <Header />

            <RouterProvider router={authenticatedRouter} />

            <Footer>
              <span>Built by SGSI</span>
              <Version>{window.appSettings?.versionNumber}</Version>
            </Footer>
          </SiteLayout>
        </UserContextProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
