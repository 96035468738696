import { useField } from "formik";
import React from "react";
import DateTimePicker from "../shared/DateTimePicker";

type Props = {
  name: string;
  id: string;
  className?: string;
};

const CustomDateTime = ({ name, id, className = "" }: Props) => {
  const [field, meta, helpers] = useField(name);

  return (
    <DateTimePicker
      {...field}
      id={id}
      onChange={(value: Date) => {
        helpers.setValue(value);
      }}
      isInvalid={meta.touched && meta.error !== undefined}
      className={className}
    />
  );
};

export default CustomDateTime;
