import React from "react";
import { ErrorPage } from "./ErrorPage";

export const FourOhOnePage = () => {
  return (
    <ErrorPage
      code={401}
      message="🛑 Unauthorized. You don't seem to have access to this page. Please contact your network admin if you believe this is an error."
    />
  );
};
