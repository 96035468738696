import React, { ReactNode } from "react";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import "./TooltipIcon.scss";
import classnames from "classnames";

type Props = {
  content: ReactNode;
  iconStyles?: React.CSSProperties;
  muted?: boolean;
  className?: string;
};

const TooltipIcon = ({ content, iconStyles, muted = true, className }: Props) => {
  const textMuted = muted ? "text-muted" : "";

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${uuidv4()}`} className={classnames("TooltipOverlay", className)}>{content}</Tooltip>}
    >
      <span>
        <FontAwesomeIcon icon={icon({ name: "circle-info" })} className={`TooltipIcon ${textMuted}`} style={iconStyles}></FontAwesomeIcon>
      </span>
    </OverlayTrigger>
  );
};

export default TooltipIcon;