import React, { useState, ReactNode, useContext } from "react";
import { GmlcDataContext } from "./GmlcDataContext";

type Props = {
  children?: ReactNode;
};

export const GmlcDataProvider = ({ children }: Props) => {
  const [rowId, setRowId] = useState<number | null>(null);

  return (
    <GmlcDataContext.Provider
      value={{
        rowId,
        setRowId,
      }}
    >
      {children}
    </GmlcDataContext.Provider>
  );
};

export const useGmlcData = () => useContext(GmlcDataContext);